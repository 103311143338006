/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import * as RegistrationConstants from './RegistrationConstants';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import SuccessMessage from '../../../SharedModule/MessageHandlers/SuccessMessage';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import { AppConfigDropdownActions } from '../../../SharedModule/Store/Actions/AppActions';
import { ValidateRegistrationActions } from '../Store/Actions/RegistrationActions';
import Radio from "@material-ui/core/Radio";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import InputLabel from "@material-ui/core/InputLabel";
import TableComponent from '../../../SharedModule/Table/Table';
const headCells = [
    {
        id: 'fileName', numeric: false, disablePadding: true, label: 'File Name', enableHyperLink: false, fontSize: 12, isDownload: true, width: '33%'
    },
    {
        id: 'description', numeric: false, disablePadding: false, label: 'Description', enableHyperLink: false, fontSize: 12, width: '60%'
    }
];
function rand() {
    return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 600,
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        //padding: theme.spacing(2, 4, 3),
        borderRadius: '10px'
    },
}));
export default function AppRegistration(props) {    
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const registrationRef = useRef(null); 
    const [selectDeleteArray, setSelectDeleteArray] = React.useState([]);
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [successMessages, setSuccessMessages] = React.useState([]);
    const [stateList, setStateList] = React.useState([]);
    const [appTypeList, setAppTypeList] = React.useState([]);
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const [attachmentFile, setAttachmentFile] = React.useState([]);
    const [description, setDescription] = React.useState("");
    const inputRef = React.useRef();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [appTypeDesc, setAppTypeDesc] = React.useState(false);
    const [showWelcome, setShowWelcome] = React.useState(true);
    const [dialogType, setDialogType] = React.useState('');
    const [bnrMsg, setBnrMsg] = React.useState("");
    const [showAttachmentErr, setShowAttachmentErr] = React.useState({
        showFileNameErr: false,
        showDescriptionErr: false
    });
    const [attachmentErrMsg, setAttachmentErrMsg] = React.useState({
        fileNameErrMsg: '',
        descriptionErrMsg: ''
    });
    const [selectedFile, setSelectedFile] = React.useState(null)
    const [addAttachmentDetails, setAddAttachmentDetails] = React.useState({
        fileName: '',
        description: '',
        isAddedRow: true,
        workUnitLevel: '-1',
        documentType: '-1'
    });
    const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
    // const [attachmentsVo, setAttachmentsVo] = React.useState([]);
    //const [selectedAttachmentRow, setSelectedAttachmentRow] = React.useState(attachmentsTableData[0]);
    const [{
        appNameError,
        appTypeError,
        devFirstNameError,
        devLastNameError,
        orgNameError,
        homePageUrlError,
        privacyPolicyUrlError,
        termsOfServiceUrlError,
        redirectUrlError,
        addressLine1Error,
        cityError,
        stateError,
        zipError,
        extnInvalid,
        zipInvalid,
        phoneNumberError,
        phoneInvalid,
        emailIdError,
        emailIdInvalidError,
        privacyPolicyIndError,
        privacyPolicyDetailIndError,
        bestPracticesIndError,
        appTypeDesError
    }, setShowError] = React.useState(false);
    const defaultVale = {
        "appName": "",
        "appType": "-1",
        "appTypeODesc": "",
        "devFirstName": "",
        "devLastName": "",
        "orgName": "",
        "homePageUrl": "",
        "privacyPolicyUrl": "",
        "termsOfServiceUrl": "",
        "redirectUrl": "",
        "addressLine1": "",
        "addressLine2": "",
        "city": "",
        "state": "-1",
        "zip": "",
        "zip1": "",
        "phoneNumber": "",
        "phoneNumber1": "",
        "phoneNumber2": "",
        "emailId": "",
        "privacyPolicyInd": '',
        "privacyPolicyDetailInd": "",
        "bestPracticesInd": ""
    }
    const [values, setValues] = React.useState(defaultVale);
    const dropDownDispatch = (configKeyList) => dispatch(AppConfigDropdownActions(configKeyList));
    const validateRegistrationDispatch = (registrationCriteria, attachmentFile) => dispatch(ValidateRegistrationActions(registrationCriteria, attachmentFile));

    const dropdownData = useSelector(state => state.sharedState.appConfigDropdown);
    const validateRegistrationData = useSelector(state => state.AppRegistrationState.validateRegistration);
    const handleOpen = () => {
        setDescription('');
        setSelectedFile(null);
        setOpen(true);
        setShowAttachmentErr({
            showFileNameErr: false,
            showDescriptionErr: false
        });
    };
    const handleClose = () => {
        setDescription('');
        inputRef.current.value = '';
        setSelectedFile(null);
        setOpen(false);
    };
    // Method to clear error/success messages
    const clearMessages = () => {
        setErrorMessages([]);
        setSuccessMessages([]);
        setShowError(false);
    }    
    const cancelAction = () =>{
        setDialogOpen(false)
        clearMessages();
        setDescription(''); 
        setDescription('');
        setShowAttachmentErr({
            showFileNameErr: false,
            showDescriptionErr: false
        });
        setAttachmentErrMsg({
            fileNameErrMsg: '',
            descriptionErrMsg: ''
        });     
        setSelectedFile(null);
        setAttachmentsTableData([]);
        setAttachmentFile([]);
      //  setAttachmentsVo([]);
        setSelectDeleteArray([]);
        setValues(defaultVale)
    }
    const redirectToHomePage = () =>{
        props.history.push({
            pathname: "/",
          });
    }

    // Method to make API calls on Load
    useEffect(() => {
        const configKeyList = ["STATE_LIST","APP_TYPE", "APP_REGISTRATION_SUPPORT_CONTACT"];
        dropDownDispatch(configKeyList);
    }, []);

    useEffect(() => {
        if (dropdownData && dropdownData.STATE_LIST && dropdownData.STATE_LIST.configDetail) {
            setStateList(dropdownData.STATE_LIST.configDetail);
            setAppTypeList(dropdownData.APP_TYPE.configDetail);
        }
        if(dropdownData && dropdownData.APP_REGISTRATION_SUPPORT_CONTACT && 
            dropdownData.APP_REGISTRATION_SUPPORT_CONTACT.configKey === 
            AppConstants.APP_REGISTRATION_SUPPORT_CONTACT) {
              setBnrMsg(dropdownData.APP_REGISTRATION_SUPPORT_CONTACT.configDetail[0].value);
        }
            
    }, [dropdownData]);

    useEffect(() => {
        if (validateRegistrationData) {
            setSpinnerLoader(false);
            let errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(validateRegistrationData, errorMessagesArray, RegistrationConstants.REGISTRATION_TECHNICAL_ERROR);
            if (errorMessagesArray && errorMessagesArray.length > 0 ) {
                setErrorMessages(errorMessagesArray);
                /* document.getElementById("error-scroll").scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                }); */
                registrationRef.current.scrollIntoView({
                    behavior: "smooth"
                });
                document.getElementById("root").scrollTop=0;
            }else{
                registrationRef.current.scrollIntoView({
                    behavior: "smooth"
                });
                document.getElementById("root").scrollTop=0;
                cancelAction();
                setSuccessMessages(['Thank you for submitting your app for review. You will receive an email with our decision after your app has been reviewed.']); 
            }
        }

    }, [validateRegistrationData]);

    const handleWelcome = () => {
        setShowWelcome(false);
    }
    // Method to set field values on change
    const handleChangeNumber = name => event =>{
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setValues({ ...values, [name]: event.target.value });
            let valueOfCurrent=event.target.value;
            if(name==='phoneNumber' && valueOfCurrent.length===3){
                document.getElementById('phone-num2').focus(); 
            }
            if(name==='phoneNumber1' && valueOfCurrent.length===3){
                document.getElementById('phone-num3').focus(); 
            }
            if(name==='zip' && valueOfCurrent.length===5){
                document.getElementById('zip-ext2').focus(); 
            }
         }
    }
    const handleChanges = name => event => {       
        setValues({ ...values, [name]: event.target.value });
        if (name === 'appType' && event.target.value === 'Others') {
            let newObj={[name]: event.target.value,'appTypeODesc': ''}
            setValues({ ...values, ...newObj });            
            setAppTypeDesc(true);
        } else {
            if (name === 'appType' && event.target.value !== 'Others') {
                setAppTypeDesc(false);
            }
            if (name === 'privacyPolicyUrl' || name === 'homePageUrl' || name === 'termsOfServiceUrl' || name === 'redirectUrl' || name == 'emailId') {
                event.target.value = event.target.value.trim(); 
            }
            setValues({ ...values, [name]: event.target.value });
        }
        
        // Conditional check if for address 2
        // if ((name === 'city' && event.target.value !== '') || (name === 'state' && event.target.value !== '-1') || (name === 'zip' && event.target.value !== '') || (name === 'zip1' && event.target.value !== '')) {
        //     setAddrRequiredAdd2(true);
        // }
        // else {
        //     if ((name === 'city' && event.target.value === '' && values.state === '-1' && !values.zip && !values.zip1) || (name === 'state' && event.target.value === '-1' && !values.city && !values.zip && !values.zip1) || (name === 'zip' && event.target.value === '' && values.state === '-1' && !values.zip1 && !values.city) || (name === 'zip1' && event.target.value === '' && values.state === '-1' && !values.zip && !values.city)) {
        //         setAddrRequiredAdd2(false);
        //     }
        // }

        // // Conditional check if for city
        // if ((name === 'addressLine1' && event.target.value !== '') || (name === 'state' && event.target.value !== '-1') || (name === 'zip' && event.target.value !== '') || (name === 'zip1' && event.target.value !== '')) {
        //     setAddrRequiredCity(true);
        // }
        // else {
        //     if ((name === 'addressLine1' && event.target.value === '' && values.state === '-1' && !values.zip && !values.zip1) || (name === 'state' && event.target.value === '-1' && !values.addressLine1 && !values.zip && !values.zip1) || (name === 'zip' && event.target.value === '' && values.state === '-1' && !values.zip1 && !values.addressLine1) || (name === 'zip1' && event.target.value === '' && values.state === '-1' && !values.zip && !values.addressLine1)) {
        //         setAddrRequiredCity(false);
        //     }
        // }

        // // Conditional check if for state
        // if ((name === 'addressLine1' && event.target.value !== '') || (name === 'city' && event.target.value !== '-1') || (name === 'zip' && event.target.value !== '') || (name === 'zip1' && event.target.value !== '')) {
        //     setAddrRequiredState(true);
        // }
        // else {
        //     if ((name === 'addressLine1' && event.target.value === '' && !values.city && !values.zip && !values.zip1) || (name === 'city' && event.target.value === '-1' && !values.addressLine1 && !values.zip && !values.zip1) || (name === 'zip' && event.target.value === '' && !values.city && !values.zip1 && !values.addressLine1) || (name === 'zip1' && event.target.value === '' && !values.city && !values.zip && !values.addressLine1)) {
        //         setAddrRequiredState(false);
        //     }
        // }

        // Conditional check if for Zip
        // if ((name === 'addressLine1' && event.target.value !== '') || (name === 'city' && event.target.value !== '-1') || (name === 'state' && event.target.value !== '-1')) {
        //     setAddrRequiredZip(true);
        // }
        // else {
        //     if ((name === 'addressLine1' && event.target.value === '' && !values.city && values.state === '-1') || (name === 'city' && event.target.value === '-1' && !values.addressLine1 && !values.state === '-1' && !values.city) || (name === 'state' && event.target.value === '-1' && !values.city && !values.addressLine1)) {
        //         setAddrRequiredZip(false);
        //     }
        // }

    };


    // Method to validate Registration Form
    const validateRegisterForm = () => {
        let errorMessagesArray = [];
        setShowError({
            appNameError: values.appName ? false : (() => { errorMessagesArray.push(RegistrationConstants.APP_NMAE_REQUIRED); return true; })(),
            appTypeError: values.appType !== '-1' ? false : (() => { errorMessagesArray.push(RegistrationConstants.APP_TYPE_REQUIRED); return true; })(),
            appTypeDesError: values.appType === 'Others' ? (() => { 
                if(!values.appTypeODesc){
                    errorMessagesArray.push(RegistrationConstants.APP_TYPE_DES_REQUIRED); return true;
                }else{ return false; }
                 })() : false,
            devFirstNameError: values.devFirstName ? false : (() => { errorMessagesArray.push(RegistrationConstants.DEV_FIRTS_NAME_REQUIRED); return true; })(),
            devLastNameError: values.devLastName ? false : (() => { errorMessagesArray.push(RegistrationConstants.DEV_LAST_NAME_REQUIRED); return true; })(),
            orgNameError: values.orgName ? false : (() => { errorMessagesArray.push(RegistrationConstants.ORGN_NAME_REQUIRED); return true; })(),
            homePageUrlError: {
                invalidUrlError : (() => {
                    if(values.homePageUrl){
                        if(!values.homePageUrl.startsWith('https://')) {
                            errorMessagesArray.push(RegistrationConstants.INVALID_HOME_PAGE_URL_ERROR);
                            return true;
                        }
                        return false;
                    } else {
                        return false;
                    }
                })()
            },
            privacyPolicyUrlError: {
                invalidUrlError : (() => {
                    if(values.privacyPolicyUrl){
                        if(!values.privacyPolicyUrl.startsWith('https://')) {
                            errorMessagesArray.push(RegistrationConstants.INVALID_PRIVACY_POLICY_URL_ERROR);
                            return true;
                        }
                        return false;
                    } else {
                        return false;
                    }
                })(),
                mandatoryFieldError : (() => {
                    if(!values.privacyPolicyUrl) {
                        errorMessagesArray.push(RegistrationConstants.PRIVACY_URL_REQUIRED);
                        return true;
                    }
                })()
            },
            termsOfServiceUrlError: {
                invalidUrlError : (() => {
                    if(values.termsOfServiceUrl){
                        if(!values.termsOfServiceUrl.startsWith('https://')) {
                            errorMessagesArray.push(RegistrationConstants.INVALID_TERMS_OF_SERVICE_URL_ERROR);
                            return true;
                        }
                        return false;
                    } else {
                        return false;
                    }
                })(),
                mandatoryFieldError : (() => {
                    if(!values.termsOfServiceUrl) {
                        errorMessagesArray.push(RegistrationConstants.TERM_URL_REQUIRED);
                        return true;
                    }
                })()
            },
            redirectUrlError: {
                invalidUrlError : (() => {
                    if(values.redirectUrl){
                        if(!values.redirectUrl.startsWith('https://')) {
                            errorMessagesArray.push(RegistrationConstants.INVALID_REDIRECT_URL_ERROR);
                            return true;
                        }
                        return false;
                    } else {
                        return false;
                    }
                })(),
                mandatoryFieldError : (() => {
                    if(!values.redirectUrl) {
                        errorMessagesArray.push(RegistrationConstants.REDIRECT_REQUIRED);
                        return true;
                    }
                })()
            },
            addressLine1Error: values.addressLine1 ? false : (() => { errorMessagesArray.push(RegistrationConstants.ADDRESS1_REQUIRED); return true; })(),
            // addressLine2Error: !values.addressLine2 && addrRequiredAdd2 ? (() => { errorMessagesArray.push(RegistrationConstants.ADDRESS_LINE_REQUIRED); return true; })() : false,
            cityError: !values.city ? (() => { errorMessagesArray.push(RegistrationConstants.CITY_REQUIRED); return true; })() : false,
            stateError: values.state === '-1' ? (() => { errorMessagesArray.push(RegistrationConstants.STATE_REQUIRED); return true; })() : false,
            zipError: (!values.zip) ? (() => { errorMessagesArray.push(RegistrationConstants.ZIP_REQUIRED); return true; })() : false,
            zipInvalid:(()=>{
                if (values.zip && (!/^[0-9]+$/.test(values.zip))) {
                    errorMessagesArray.push(RegistrationConstants.ZIP_INVALID);
                    return true;
                }
                if (values.zip && (/^[0-9]+$/.test(values.zip))) {
                    let zipStriing=values.zip;
                    if(zipStriing.length!==5){
                        errorMessagesArray.push(RegistrationConstants.ZIP_INVALID);
                        return true;
                    }            
                }
            })(),
            extnInvalid:(()=>{
                if (values.zip1 && (!/^[0-9]+$/.test(values.zip1))) {
                    errorMessagesArray.push(RegistrationConstants.EXTN_INVALID);
                    return true;
                }
                if (values.zip1 && (/^[0-9]+$/.test(values.zip1))) {
                    let zipStriing=values.zip1;
                    if(zipStriing.length!==4){
                        errorMessagesArray.push(RegistrationConstants.EXTN_INVALID);
                        return true;
                    }            
                }
            })(),
            phoneNumberError: values.phoneNumber || values.phoneNumber1 || values.phoneNumber2 ? false : (() => { errorMessagesArray.push(RegistrationConstants.PHONE_REQUIRED); return true; })(),
            phoneInvalid:(()=>{
                let phoneStringAll=values.phoneNumber+values.phoneNumber1+values.phoneNumber2;
                if(phoneStringAll.length > 0 && phoneStringAll.length < 10){
                    errorMessagesArray.push(RegistrationConstants.PHONE_INVALID);
                    return true
                }
                if (values.phoneNumber && values.phoneNumber1 && values.phoneNumber2 && (!/^[0-9]+$/.test(values.phoneNumber) || !/^[0-9]+$/.test(values.phoneNumber1) || !/^[0-9]+$/.test(values.phoneNumber2))) {
                    errorMessagesArray.push(RegistrationConstants.PHONE_INVALID);
                    return true
                }
                if (values.phoneNumber && values.phoneNumber1 && values.phoneNumber2 && (/^[0-9]+$/.test(values.phoneNumber) || /^[0-9]+$/.test(values.phoneNumber1) || /^[0-9]+$/.test(values.phoneNumber2))) {
                    let phoneString=values.phoneNumber+values.phoneNumber1+values.phoneNumber2;
                    if(phoneString.length!==10){
                        errorMessagesArray.push(RegistrationConstants.PHONE_INVALID);
                        return true
                    }            
                }
            })(),
            emailIdError: values.emailId ? false : (() => { errorMessagesArray.push(RegistrationConstants.Email_REQUIRED); return true; })(),
            emailIdInvalidError: !values.emailId ? false : (() => {
                if (!AppConstants.EMAIL_ADDRESS_REGEX.test(values.emailId)) {
                    errorMessagesArray.push(RegistrationConstants.EMAIL_INVALID);
                    return true;
                }

            }
            )(),
            privacyPolicyIndError: values.privacyPolicyInd ? false : (() => { errorMessagesArray.push(RegistrationConstants.PRIVACY_REQUIRED); return true; })(),
            privacyPolicyDetailIndError: values.privacyPolicyDetailInd ? false : (() => { errorMessagesArray.push(RegistrationConstants.PRIVACY_DETAILS_REQUIRED); return true; })(),
            bestPracticesIndError: values.bestPracticesInd ? false : (() => { errorMessagesArray.push(RegistrationConstants.PBEST_PACTICE_REQUIRED); return true; })(),
        });   
     

        setErrorMessages(errorMessagesArray);

        if (errorMessagesArray && errorMessagesArray.length === 0) {
            setDialogOpen(true);
            setDialogType('submit')
            return true;
        } else {
            /* document.getElementById("error-scroll").scrollIntoView({
                behavior: "smooth",
            }); */
            registrationRef.current.scrollIntoView({
                behavior: "smooth"
            });
            document.getElementById("root").scrollTop=0;
            return false;
        }
    };

    const cancelDialog = () =>{
        setDialogOpen(true);
        setDialogType('cancel')
    }
    // Method to submit Registration Form
    const submitRegistrationForm = () => {
        clearMessages();
        setDialogOpen(false);       
        const myArrayFiltered = attachmentFile.filter((el) => {
            return attachmentsTableData.some((f) => {
              return f.fileName === el.name;
            });
          });         
        //  if (validateRegisterForm()) {
        let phone = values.phoneNumber + values.phoneNumber1 + values.phoneNumber2;
        let zip = values.zip + values.zip1;
        const registrationRequest = {
            "addressLine1": values.addressLine1,
            "addressLine2": values.addressLine2,
            "appName": values.appName,
            "appRegistrationId": "",
            "appType": values.appType !== '-1' ? values.appType : "",
            "appTypeODesc": values.appTypeODesc,
            "auditUserID": "",
            "attachments": attachmentsTableData.length > 0 ? attachmentsTableData : null,
            "bestPracticesInd": values.bestPracticesInd === 'true' ? true : false,
            "city": values.city,
            "clientId": "",
            "clientSecret": "",
            "devFirstName": values.devFirstName,
            "devLastName": values.devLastName,
            "emailId": values.emailId,
            "homePageUrl": values.homePageUrl,
            "orgName": values.orgName,
            "phoneNumber": phone,
            "privacyPolicyDetailInd": values.privacyPolicyDetailInd === 'true' ? true : false,
            "privacyPolicyInd": values.privacyPolicyInd === 'true' ? true : false,
            "privacyPolicyUrl": values.privacyPolicyUrl,
            "redirectUrl": values.redirectUrl,
            "registrationAccessToken": "",
            "state": values.state,
            "status": "",
            "statusReason": "",
            "termsOfServiceUrl": values.termsOfServiceUrl,
            "zip": zip
        }

        setSpinnerLoader(true);
        validateRegistrationDispatch(registrationRequest, myArrayFiltered);
        // }
    };
    const getTablegetTableData = (data) => {
        if (data && data.length) {
            let tData = JSON.stringify(data);
            tData = JSON.parse(tData);
            tData.map((each, index) => {
                each.index = index;
                //each.edmsWrkUnitLevel = each.edmsWrkUnitLevel;
                // each.edmsDocType= each.edmsDocType;
            });
            return tData;
        } else {
            return [];
        }
    }
    // upload codes

    const resetAttachment = () => {
        setSelectedFile(null);
        inputRef.current.value = '';
        
        setDescription('');
        setShowAttachmentErr({
            showFileNameErr: false,
            showDescriptionErr: false
        });
        setAttachmentErrMsg({
            fileNameErrMsg: '',
            descriptionErrMsg: ''
        });
    };

    const saveAttachment = () => {

        if (selectedFile === null) {
            setShowAttachmentErr({
                showFileNameErr: true,
                showDescriptionErr: false
            });
            setAttachmentErrMsg({
                fileNameErrMsg: RegistrationConstants.FILES_REQUIRED,
            });
            return false;
        }
        let tempAttachments = [];
      //  let tempAttachments2 = [];
        //  if(validateAttachmentDetails()){
        if (attachmentsTableData && attachmentsTableData.length > 0) {
            tempAttachments = [...attachmentsTableData];
        } else {
            tempAttachments = [];
        }
        // if (attachmentsVo && attachmentsVo.length > 0) {
        //     tempAttachments2 = [...attachmentsVo];
        // } else {
        //     tempAttachments2 = [];
        // }

        const data =
        {
            "attachmentPageID": "",
            "finalPath": "",
            "file1": null,
            "description": description ? description : "",
            "fileName": addAttachmentDetails.fileName ? addAttachmentDetails.fileName : ""
        };
        tempAttachments.push(data)
        setAttachmentsTableData(tempAttachments);
        const files = inputRef.current.files[0];
        if(files){
            attachmentFile.push(files);
            setAttachmentFile([...attachmentFile]);
        }
        setDescription('');
        inputRef.current.value = '';
        setSelectedFile(null);
        handleClose();
        
    };
    const attachmentsTableRowDeletEvent = () => {
        let tempAttachments = attachmentsTableData;
        if (selectDeleteArray && selectDeleteArray.length > 0) {
            selectDeleteArray.map((selectedRowId, index) => {
                let tempAttachment = tempAttachments.findIndex(i => i.fileName === selectedRowId.fileName)
                tempAttachments.splice(tempAttachment, 1);
            });
            setSelectedFile(null);
           // setAttachmentFile([]);
            setDialogOpen(false);
            setSelectDeleteArray([]);
            if(tempAttachments.length < 1){
                setAttachmentFile([]);
            }


        }
        setAttachmentsTableData(tempAttachments);
    };

    const checkMimeType = event => {
        let fileErr = false;
        let fileErrMSg = '';
        // getting file object
        const files = event.target.files;
        // define message container
        const err = [];
        // list allow mime type
       // const types = ["application/pdf", "application/msword"]
        // loop access array
        for (var x = 0; x < files.length; x++) {
            // compare file type find doesn't matach             
            var ext = files[x].name.split('.').pop();
            if (ext === "pdf" || ext === "docx" || ext === "doc") {
                fileErr = false;
                fileErrMSg = '';
                setShowAttachmentErr({
                    showFileNameErr: fileErr
                });
                setAttachmentErrMsg({
                    fileNameErrMsg: fileErrMSg,
                });

            }
            else {
                err[x] = RegistrationConstants.FILES_TYPE;                
                fileErr = true;
                fileErrMSg = RegistrationConstants.FILES_TYPE;
                setShowAttachmentErr({
                    showFileNameErr: fileErr
                });
                setAttachmentErrMsg({
                    fileNameErrMsg: fileErrMSg,
                });
            }
        }
        const temErr = [];
        for (var z = 0; z < err.length; z++) {
            // if message not same old that mean has error
            // discard selected file
            // toast.error(err[z]);
            event.target.value = null;
            temErr.push(err[z]);
        }
        if (temErr.length > 0) {
          //  setErrorMessages(temErr);
            // props.seterrorMessages(temErr);
            return false;
        }
        return true;
    };
    const checkFileSize = event => {
      //  let fileErr = false;
     //   let fileErrMSg = '';
        const files = event.target.files;
        const size = 10000000;
        const err = [];
        for (var x = 0; x < files.length; x++) {
            if (files[x].size === 0) {
                err[x] = RegistrationConstants.FILES_SIZE_ZERO
            }
            if (files[x].size > size) {
                err[x] = RegistrationConstants.FILES_SIZE_ERROR;
            }
        }
        const tempErr = [];
        for (var z = 0; z < err.length; z++) {
            // if message not same old that mean has error
            // discard selected file
            // toast.error(err[z]);
            tempErr.push(err[z]);
            event.target.value = null;
        }
        if (tempErr.length > 0) {
           // setErrorMessages(tempErr);
            // props.seterrorMessages(tempErr);
            return false;
        }
        return true;
    };
const checkDuplicateFile = event =>{
    const files = event.target.files;
    let filterFile=[];
    let fileErr = false;
    let fileErrMSg = '';
    if(attachmentsTableData.length > 0){       
        for (var x = 0; x < files.length; x++) {
            filterFile=attachmentsTableData.filter(e => e.fileName===files[x].name)
        }
        if(filterFile.length > 0){
            fileErr = true;
            fileErrMSg = RegistrationConstants.FILES_DUPLICATE_ERROR;
            setShowAttachmentErr({
                showFileNameErr: fileErr
            });
            setAttachmentErrMsg({
                fileNameErrMsg: fileErrMSg,
            });
            return false;
        }else{
            return true;
        }
       
    }
    else{
        return  true;
    }

}
    const onChangeChooseFile = event => {
        
        if (checkDuplicateFile(event) && checkMimeType(event) && checkFileSize(event)) {
            const files = event.target.files[0];
            if (files) {
                setSelectedFile(files);
                //attachmentFile.push(files);
               // setAttachmentFile([...attachmentFile]);
                //setAttachmentFile(files);
                setAddAttachmentDetails({
                    ...addAttachmentDetails,
                    fileName: files.name
                });
                setErrorMessages([]);
            }
        } else {
            inputRef.current.value = '';
        }
    };

    // download\

    const editAttachment = row => event => {
       
      //  setSelectedAttachmentRow({ ...row });
        
        setShowAttachmentErr({
            showFileNameErr: false,
            showDescriptionErr: false
        });
        setAttachmentErrMsg({
            fileNameErrMsg: '',
            descriptionErrMsg: ''
        });
        
    };

    const editRow = row => event => {
        event.stopPropagation();
        // downloaFileClick();
    }

    return (
        <div>
            {spinnerLoader ? <Spinner /> : null}

            <Dialog
                open={dialogOpen}
                // aria-labelledby="alert-dialog-title"
                // aria-describedby="alert-dialog-description"
                className="custom-alert-box"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogType === 'multiDelete' ? 'Are you sure that you want to delete?' : dialogType === 'cancel' ? 'Are you sure you want to Cancel?' : ' Are you sure you want to Submit?'}

                        
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={() => { dialogType == 'multiDelete' ? attachmentsTableRowDeletEvent() : dialogType == 'minorDelete' ? minorDelete() :null }} color="primary" className="btn btn-success">
                Ok
            </Button> */}
                    {dialogType === 'multiDelete' && (
                        <Button title="Ok" onClick={() => { attachmentsTableRowDeletEvent() }} color="primary" className="btn btn-success">
                            Ok
                        </Button>
                    )
                    }

                    {dialogType === 'submit' && (
                        <Button title="Ok" onClick={() => { submitRegistrationForm() }} color="primary" className="btn btn-success">
                            Ok
                        </Button>
                    )
                    }

                    {dialogType === 'cancel' && (
                        <Button title="Ok" onClick={() => { redirectToHomePage() }} color="primary" className="btn btn-success">
                            Ok
                        </Button>
                    )
                    }

                    <Button title="Cancel" onClick={() => { setDialogOpen(false); }} color="primary" autoFocus>
                        Cancel
            </Button>
                </DialogActions>
            </Dialog>

            {/* <!-- main content start --> */}
            <div className="content-wrapper">


                <div className="frm-app-registration mt-1">
                    <div className="tabs-container">
                        <div className="page-header mob-main-head" ref={registrationRef}>
                            <h1 className="page-heading float-left">Third Party App Registration</h1>
                            <div className="float-right"><a href="/Help2" onClick={()=>{localStorage.setItem("ioxHelpDoc","app")}} target="_blank" className="dib fw-600 py-2 mt-1"> Help </a></div>
                            <div className="clearfix" />
                        </div>
                        {showWelcome && (
                            <div className="welcome-msg p-3">
                                Welcome to the MO HealthNet Interoperability Mobile App/Website Registration.<br />
                            Please fill in the below details accurately to register your Mobile App / Website with us. For any questions, reach out to toll free help line {bnrMsg}.
                                <div className="close-msg-box" onClick={handleWelcome}>x</div>
                            </div>
                        )}

                        <div className="form-wrapper col-sm-12 px-0 mt-3">
                            {errorMessages && errorMessages.length > 0 ?
                                <ErrorMessages errorMessages={errorMessages} /> : null}
                            {errorMessages && successMessages && errorMessages.length === 0 && successMessages.length > 0 ?
                                <SuccessMessage successMessages={successMessages} /> : null}
                        </div>

                        <div className="tab-body mt-2 pb-3">
                            {/* <!-- section start --> */}
                            <div className="tabs-inner-container m-3">
                                <div className="tab-header">
                                    <h2 className="tab-heading float-left frm-label">App Details</h2>
                                    <div className="clearfix" />
                                </div>

                                <div className="tab-body-bordered pb-3 pt-3 mt-0">
                                    <div className="form-wrapper col-sm-12 px-2 my-1">
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 pb-3">
                                            <TextField
                                                required
                                                id="app-name"
                                                label="App Name"
                                                value={values.appName}
                                                onChange={handleChanges('appName')}
                                                inputProps={{ maxLength: 35 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                error={appNameError}
                                                helperText={appNameError ? RegistrationConstants.APP_NMAE_REQUIRED : null}

                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField
                                                select
                                                required={true}
                                                id="app-type"
                                                label="App Type"
                                                value={values.appType}
                                                onChange={handleChanges('appType')}
                                                inputProps={{ id: 'appType', maxLength: 2 }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    for: 'appType'
                                                }}
                                                error={appTypeError}
                                                helperText={appTypeError ? RegistrationConstants.APP_TYPE_REQUIRED : null}
                                            >
                                                <MenuItem selected key="-1" value="-1">
                                                    Please Select One
                                        </MenuItem>
                                                {appTypeList ? appTypeList.map(option => (
                                                    <MenuItem selected key={option.key} value={option.key}>
                                                        {option.value}
                                                    </MenuItem>
                                                )) : null}
                                            </TextField>
                                        </div>
                                        {appTypeDesc && (
                                            <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                                <TextField
                                                    required
                                                    id="app-description"
                                                    label="App Type Description"
                                                    value={values.appTypeODesc}
                                                    onChange={handleChanges('appTypeODesc')}
                                                    inputProps={{ maxLength: 15 }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                error={appTypeDesError}
                                                helperText={appTypeDesError ? RegistrationConstants.APP_TYPE_DES_REQUIRED : null}

                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-wrapper col-sm-12 px-2 my-1">
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 pb-3">
                                            <TextField
                                                required
                                                id="developer-fname"
                                                label="Developer First Name"
                                                value={values.devFirstName}
                                                onChange={handleChanges('devFirstName')}
                                                inputProps={{ maxLength: 35 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                error={devFirstNameError}
                                                helperText={devFirstNameError ? RegistrationConstants.DEV_FIRTS_NAME_REQUIRED : null}
                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField
                                                required
                                                id="developer-lname"
                                                label="Developer Last Name"
                                                value={values.devLastName}
                                                onChange={handleChanges('devLastName')}
                                                inputProps={{ maxLength: 35 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                error={devLastNameError}
                                                helperText={devLastNameError ? RegistrationConstants.DEV_LAST_NAME_REQUIRED : null}
                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField
                                                required
                                                id="organization-name"
                                                label="Organization Name"
                                                value={values.orgName}
                                                onChange={handleChanges('orgName')}
                                                inputProps={{ maxLength: 35 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                error={orgNameError}
                                                helperText={orgNameError ? RegistrationConstants.ORGN_NAME_REQUIRED : null}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-wrapper col-sm-12 px-2 my-1">
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField
                                               // required
                                                id="home-page-url"
                                                placeholder="Valid URL must start with https://"
                                                label="Home Page URL"
                                                value={values.homePageUrl}
                                                onChange={handleChanges('homePageUrl')}
                                                inputProps={{ maxLength: 2000 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                error={homePageUrlError?.invalidUrlError}
                                                helperText={homePageUrlError?.invalidUrlError ? RegistrationConstants.INVALID_HOME_PAGE_URL_ERROR : null}
                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField
                                                required
                                                id="privacy-policy-url"
                                                placeholder="Valid URL must start with https://"
                                                label="Privacy Policy URL"
                                                value={values.privacyPolicyUrl}
                                                onChange={handleChanges('privacyPolicyUrl')}
                                                inputProps={{ maxLength: 2000 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                error={privacyPolicyUrlError?.invalidUrlError || privacyPolicyUrlError?.mandatoryFieldError}
                                                helperText={privacyPolicyUrlError?.mandatoryFieldError ? RegistrationConstants.PRIVACY_URL_REQUIRED : (privacyPolicyUrlError?.invalidUrlError ? RegistrationConstants.INVALID_PRIVACY_POLICY_URL_ERROR : null)}
                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField
                                                required
                                                id="terms-of-service-url"
                                                placeholder="Valid URL must start with https://"
                                                label="Terms of Services URL"
                                                value={values.termsOfServiceUrl}
                                                onChange={handleChanges('termsOfServiceUrl')}
                                                inputProps={{ maxLength: 2000 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                error={termsOfServiceUrlError?.invalidUrlError || termsOfServiceUrlError?.mandatoryFieldError}
                                               helperText={termsOfServiceUrlError?.mandatoryFieldError ? RegistrationConstants.TERM_URL_REQUIRED : (termsOfServiceUrlError?.invalidUrlError ? RegistrationConstants.INVALID_TERMS_OF_SERVICE_URL_ERROR : null)}
                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField
                                                required
                                                id="redirect-url"
                                                placeholder="Valid URL must start with https://"
                                                label="Redirect URL"
                                                value={values.redirectUrl}
                                                onChange={handleChanges('redirectUrl')}
                                                inputProps={{ maxLength: 2000 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                error={redirectUrlError?.invalidUrlError || redirectUrlError?.mandatoryFieldError}
                                                helperText={redirectUrlError?.mandatoryFieldError ? RegistrationConstants.REDIRECT_REQUIRED : (redirectUrlError?.invalidUrlError ? RegistrationConstants.INVALID_REDIRECT_URL_ERROR : null)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- section end --> */}

                            {/* <!-- section start --> */}
                            <div className="tabs-inner-container m-3">
                                <div className="tab-header">
                                    <h2 className="tab-heading float-left frm-label">Address</h2>
                                    <div className="clearfix" />
                                </div>

                                <div className="tab-body-bordered pb-3 pt-3 mt-0">
                                    <div className="form-wrapper col-sm-12 px-2 my-1">
                                        <div className="mui-custom-form col-sm-6 col-md-6 m-0 pb-3">
                                            <TextField
                                                required
                                                id="address1"
                                                placeholder="Line 1"
                                                label="Address"
                                                value={values.addressLine1}
                                                onChange={handleChanges('addressLine1')}
                                                inputProps={{ maxLength: 50 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                error={addressLine1Error}
                                                helperText={addressLine1Error ? RegistrationConstants.ADDRESS1_REQUIRED : null}

                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-6 m-0 mb-3">
                                        <label className="visuallyhidden" for="address2">Address Extension</label>
                                            <TextField
                                              //  required={addrRequiredAdd2}
                                                id="address2"
                                                placeholder="Line 2"
                                                className="pt-4 add2-block"
                                                value={values.addressLine2}
                                                onChange={handleChanges('addressLine2')}
                                                inputProps={{ maxLength: 50 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                // error={addressLine2Error}
                                                // helperText={addressLine2Error ? RegistrationConstants.ADDRESS_LINE_REQUIRED : null}
                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0">
                                            <TextField
                                                required={true}
                                                id="city"
                                                label="City"
                                                value={values.city}
                                                onChange={handleChanges('city')}
                                                inputProps={{ maxLength: 30 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                error={cityError}
                                                helperText={cityError ? RegistrationConstants.CITY_REQUIRED : null}


                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mt-block">
                                            <TextField
                                                select
                                                required={true}
                                                id="state"
                                                label="State"
                                                value={values.state}
                                                onChange={handleChanges('state')}
                                                inputProps={{ id: 'state', maxLength: 2 }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    for: 'state'
                                                }}
                                                error={stateError}
                                                helperText={stateError ? RegistrationConstants.STATE_REQUIRED : null}
                                            >
                                                <MenuItem selected key="" value="-1">
                                                    Please Select One
                                                </MenuItem>
                                                {stateList ? stateList.map(option => (
                                                    <MenuItem selected key={option.key} value={option.key}>
                                                        {option.value}
                                                    </MenuItem>
                                                )) : null}
                                            </TextField>
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mt-block">
                                            <div className="custom-mui-label"><span className="MuiFormLabel-asterisk">*</span>Zip and Extension</div>
                                            <div className="cndt-row">
                                                <div className="cndt-col-6">
                                                <label className="visuallyhidden" for="zip-ext1">Zip number</label>
                                                    <TextField
                                                        required={true} 
                                                        id="zip-ext1"
                                                        value={values.zip}
                                                        onChange={handleChangeNumber('zip')}
                                                        inputProps={{ maxLength: 5 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        error={zipError? zipError :  zipInvalid ? zipInvalid : null}

                                                    // error={firstNameError}
                                                    // helperText={firstNameError ? firstNameErrorText : null}

                                                    />
                                                     <p className="MuiFormHelperText-root MuiFormHelperText-filled Mui-error Mui-required ml-2">
                                                        {zipError
                                                            ? RegistrationConstants.ZIP_REQUIRED : zipInvalid ? RegistrationConstants.ZIP_INVALID
                                                                : null}
                                                    </p>
                                                </div>
                                                <div className="cndt-col-6">
                                                    <label className="visuallyhidden" for="zip-ext2">Extension</label>
                                                    <TextField
                                                        id="zip-ext2"
                                                        value={values.zip1}
                                                        onChange={handleChangeNumber('zip1')}
                                                        inputProps={{ maxLength: 4 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        error={ extnInvalid ? extnInvalid : null}

                                                    // error={firstNameError}
                                                    // helperText={firstNameError ? firstNameErrorText : null}

                                                    />
                                                <p className="MuiFormHelperText-root MuiFormHelperText-filled Mui-error Mui-required ml-2">
                                                        { extnInvalid ? RegistrationConstants.EXTN_INVALID
                                                            : null}
                                                </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mt-block">
                                            <div className="custom-mui-label"><span className="MuiFormLabel-asterisk">*</span>Phone Number</div>
                                            <div className="cndt-row">
                                                <div className="cndt-col-3">
                                                   <label className="visuallyhidden" for="phone-num1">Phone number</label>
                                                    <TextField
                                                        required
                                                        id="phone-num1"
                                                        value={values.phoneNumber}
                                                        onChange={handleChangeNumber('phoneNumber')}
                                                        inputProps={{ maxLength: 3 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        error={phoneNumberError? phoneNumberError :  phoneInvalid ? phoneInvalid : null}

                                                    // error={firstNameError}
                                                    // helperText={firstNameError ? firstNameErrorText : null}


                                                    />
                                                </div>
                                                <div className="cndt-col-3">
                                                <label className="visuallyhidden" for="phone-num2">Phone number extension</label>
                                                    <TextField
                                                        id="phone-num2"
                                                        inputProps={{ maxLength: 3 }}
                                                        value={values.phoneNumber1}
                                                        onChange={handleChangeNumber('phoneNumber1')}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        error={phoneNumberError? phoneNumberError :  phoneInvalid ? phoneInvalid : null}

                                                    />
                                                </div>
                                                <div className="cndt-col-6">
                                                <label className="visuallyhidden" for="phone-num3">Phone number extension next</label>
                                                    <TextField
                                                        id="phone-num3"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        value={values.phoneNumber2}
                                                        onChange={handleChangeNumber('phoneNumber2')}
                                                        inputProps={{ maxLength: 4 }}
                                                        error={phoneNumberError? phoneNumberError :  phoneInvalid ? phoneInvalid : null}

                                                    />
                                                </div>
                                                <p className="MuiFormHelperText-root MuiFormHelperText-filled Mui-error Mui-required ml-2">
                                                    {phoneNumberError
                                                        ? RegistrationConstants.PHONE_REQUIRED : phoneInvalid ? RegistrationConstants.PHONE_INVALID
                                                        : null}
                                                </p>
                                            </div>

                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-6 m-0">
                                            <TextField
                                                required
                                                id="email-id"
                                                label="Email Id"
                                                value={values.emailId}
                                                onChange={handleChanges('emailId')}
                                                inputProps={{ maxLength: 64 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                error={emailIdError ? RegistrationConstants.Email_REQUIRED : emailIdInvalidError ? RegistrationConstants.EMAIL_INVALID : null}
                                                helperText={emailIdError ? RegistrationConstants.Email_REQUIRED : emailIdInvalidError ? RegistrationConstants.EMAIL_INVALID : null}
                                            />
                                        </div>

                                    </div>

                                </div>
                            </div>
                            {/* <!-- section end --> */}

                            {/* <!-- section start --> */}
                            <div className="tabs-inner-container m-3">
                                <div className="tab-header">
                                    <h2 className="tab-heading float-left frm-label">Privacy Policy Questionnaire</h2>
                                    <div className="clearfix" />
                                </div>
                                {/* border block start */}
                                <div className="tab-body-bordered pt-3 mt-0 mb-3">
                                    <div className="questionnaire-area ml-3">
                                        <div className="txt-quest"><span className="MuiFormLabel-asterisk">*</span> Does the app have a publicly available privacy policy, written in plain language, that has been affirmatively shared with the patient prior to the patient authorizing app access to their  health information?</div>


                                        <div className="form-wrapper m-0">
                                                <div className="sub-radio block-elm">
                                                <div className="radio-group float-left">
                                                    <Radio
                                                        color="primary"
                                                        type="radio"
                                                        value={'true'}
                                                        id="ShortStay-TransExclYes1"
                                                        checked={values.privacyPolicyInd === 'true'}
                                                        onChange={handleChanges('privacyPolicyInd')}
                                                    />
                                                    <label for="ShortStay-TransExclYes1" className="radio-label float-left">
                                                        Yes
                                                </label>
                                                </div>
                                                <div className="radio-group float-left ml-3">
                                                    <Radio
                                                        color="primary"
                                                        type="radio"
                                                        value={'false'}
                                                        id="ShortStay-TransExclNo1"
                                                        className="ml-2"
                                                        checked={values.privacyPolicyInd === 'false'}
                                                        onChange={handleChanges('privacyPolicyInd')}
                                                    />
                                                    <label for="ShortStay-TransExclNo1" className="radio-label float-left">
                                                        No
                                                 </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="custom-required-msg">
                                            <p className="MuiFormHelperText-root MuiFormHelperText-filled Mui-error Mui-required">
                                                {privacyPolicyIndError
                                                    ? RegistrationConstants.PRIVACY_REQUIRED
                                                    : null}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* border block end */}
                                {/* border block start */}
                                <div className="tab-body-bordered pt-3 mt-0 mb-3">
                                    <div className="questionnaire-area ml-3">
                                        <div className="txt-quest"><span className="MuiFormLabel-asterisk">*</span> Does the app’s privacy policy include important information, such as, at a minimum:</div>
                                        <div className="txt-desc">
                                            <ul>
                                                <li>How a patient’s health information may be accessed, exchanged, or used by any person or other entity, including whether the patient’s health information may be shared or
sold at any time (including in the future);</li>
                                                <li>A requirement for express consent from a patient before the patient’s health information is accessed, exchanged, or used, including receiving express consent before a patient’s health information is shared or sold (other than disclosures required by law or disclosures necessary in connection with the sale of the application or a similar transaction)</li>
                                                <li>If the app will access any other information from a patient’s device; or</li>
                                                <li>How a patient can discontinue app access to their data, and what the app’s policy and process is for disposing of a patient’s data once the patient has withdrawn consent?</li>
                                            </ul>
                                        </div>

                                        <div className="form-wrapper m-0">
                                            <div className="sub-radio block-elm">
                                                <div className="radio-group float-left">
                                                    <Radio
                                                        color="primary"
                                                        type="radio"
                                                        value={'true'}
                                                        id="ShortStay-TransExclYes2"
                                                        checked={values.privacyPolicyDetailInd === 'true'}
                                                        onChange={handleChanges('privacyPolicyDetailInd')}
                                                    />
                                                    <label for="ShortStay-TransExclYes2" className="radio-label float-left">
                                                        Yes
                                                </label>
                                                </div>
                                                <div className="radio-group float-left ml-3">
                                                    <Radio
                                                        color="primary"
                                                        type="radio"
                                                        value={'false'}
                                                        id="ShortStay-TransExclNo2"
                                                        className="ml-2"
                                                        checked={values.privacyPolicyDetailInd === 'false'}
                                                        onChange={handleChanges('privacyPolicyDetailInd')}
                                                    />
                                                    <label for="ShortStay-TransExclNo2" className="radio-label float-left">
                                                        No
                                                 </label>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="custom-required-msg">
                                            <p className="MuiFormHelperText-root MuiFormHelperText-filled Mui-error Mui-required">
                                                {privacyPolicyDetailIndError
                                                    ? RegistrationConstants.PRIVACY_DETAILS_REQUIRED
                                                    : null}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* border block end */}
                                {/* border block start */}
                                <div className="tab-body-bordered pt-3 mt-0">
                                    <div className="questionnaire-area ml-3">
                                        <div className="txt-quest"><span className="MuiFormLabel-asterisk">*</span> Does the app follow industry best practices when developing a privacy policy and consult relevant resources, such as:</div>
                                        <div className="txt-desc">
                                            <ul>
                                                <li>CARIN Alliance Code of Conduct: <a title="CARIN Alliance Code of Conduct" href="https://www.carinalliance.com/code-of-conduct" target="_blank" rel="noopener noreferrer">https://www.carinalliance.com/code-of-conduct</a></li>
                                                <li>ONC Model Privacy Notice: <a title="ONC Model Privacy Notice" href="https://www.healthit.gov/topic/privacy-security-and-hipaa/model-privacy-notice-mpn" target="_blank" rel="noopener noreferrer">https://www.healthit.gov/topic/privacy-security-and-hipaa/model-privacy-notice-mpn</a></li>
                                                <li>National Institute of Standards and Technology: <a title="National Institute of Standards and Technology" href="https://www.nist.gov" target="_blank" rel="noopener noreferrer">www.nist.gov</a></li>
                                            </ul>
                                        </div>

                                        <div className="form-wrapper m-0">
                                            <div className="sub-radio block-elm">
                                                <div className="radio-group float-left">
                                                    <Radio
                                                        color="primary"
                                                        type="radio"
                                                        value={'true'}
                                                        id="ShortStay-TransExclYes3"
                                                        checked={values.bestPracticesInd === 'true'}
                                                        onChange={handleChanges('bestPracticesInd')}
                                                    />
                                                    <label for="ShortStay-TransExclYes3" className="radio-label float-left">
                                                        Yes
                                                </label>
                                                </div>
                                                <div className="radio-group float-left ml-3">
                                                    <Radio
                                                        color="primary"
                                                        type="radio"
                                                        value={'false'}
                                                        id="ShortStay-TransExclNo3"
                                                        className="ml-2"
                                                        checked={values.bestPracticesInd === 'false'}
                                                        onChange={handleChanges('bestPracticesInd')}
                                                    />
                                                    <label for="ShortStay-TransExclNo3" className="radio-label float-left">
                                                        No
                                                 </label>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="custom-required-msg">
                                            <p className="MuiFormHelperText-root MuiFormHelperText-filled Mui-error Mui-required">
                                                {bestPracticesIndError
                                                    ? RegistrationConstants.PBEST_PACTICE_REQUIRED
                                                    : null}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* border block end */}
                            </div>
                            {/* <!-- section end --> */}

                            {/* <!-- section start --> */}
                            <div className="tabs-inner-container m-3">
                                <div className="tab-header">
                                    <h2 className="tab-heading float-left frm-label">Attachments</h2>
                                    <div className="clearfix" />
                                </div>

                                <div className="tab-body-bordered pb-3 pt-2 mt-0">
                                    <div className="tabs-inner-container mx-3">
                                        <div className="tab-header">
                                            <div className="float-right th-btnGroup">
                                                <Button
                                                    title="Delete Attachment"
                                                    variant="outlined"
                                                    color="primary"
                                                    className="btn btn-ic btn-delete"
                                                    disabled={selectDeleteArray.length < 1 ? true : false}
                                                    onClick={() => { setDialogOpen(true); setDialogType('multiDelete'); }}
                                                >
                                                    Delete
                                                </Button>
                                                <Button
                                                    title="Add Attachment"
                                                    variant="outlined"
                                                    color="primary"
                                                    className="btn btn-ic btn-add"
                                                    onClick={handleOpen}
                                                >
                                                    Add Attachment
                                                </Button>
                                                {/* Modal start */}
                                                <Modal
                                                    open={open}
                                                    onClose={handleClose}
                                                    // aria-labelledby="simple-modal-title"
                                                    // aria-describedby="simple-modal-description"
                                                >

                                                    <form autoComplete="off">
                                                        <div id="frmModal" style={modalStyle} className={classes.paper}>
                                                            <div className="custom-panel">
                                                                <div className="panel-header">ATTACHMENTS</div>
                                                                <div className="close-modal-bx" onClick={handleClose}><i class="fa fa-times"></i></div>
                                                            </div>
                                                            <div className="tab-body p-3">
                                                                <div className="form-wrapper mt-2">
                                                                    <div className="mui-custom-form field-md  col-sm-12 m-0 mb-3">
                                                                        <input
                                                                            id="file_upload"
                                                                            type="file"
                                                                            name="file"
                                                                            required
                                                                            onChange={onChangeChooseFile}
                                                                            ref={inputRef}
                                                                            style={{ display: "none" }}
                                                                        />
                                                                        <div>
                                                                            <div
                                                                                htmlFor="file"
                                                                                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-shrink pb-2"
                                                                            >
                                                                                File<span style={{ color: "#a81f00" }}>&nbsp;*</span>
                                                                            </div>

                                                                            <div className="upload-btn-area">
                                                                                <label for="file_upload">
                                                                                    <a className="btn btn-primary btn-upload">Choose File</a>
                                                                                </label>

                                                                                <span className="upload-file-name">
                                                                                    {selectedFile && selectedFile.name
                                                                                        ? selectedFile.name
                                                                                        : "No file choosen"}
                                                                                </span>
                                                                            </div>

                                                                            <p className="MuiFormHelperText-root MuiFormHelperText-filled Mui-error Mui-required">
                                                                                {showAttachmentErr.showFileNameErr
                                                                                    ? attachmentErrMsg.fileNameErrMsg
                                                                                    : null}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mui-custom-form col-sm-12 m-0 pt-0 mb-3">

                                                                        <TextField
                                                                            id="standard-firstname"
                                                                            label="Description"
                                                                            value={description}
                                                                            onChange={event => { setDescription(event.target.value) }}
                                                                            inputProps={{ maxLength: 50 }}
                                                                            InputLabelProps={{
                                                                                shrink: true
                                                                            }}
                                                                            className="mt-0 pt-0"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="tab-footer p-3">
                                                                    <div className="float-right th-btnGroup">
                                                                        <Button
                                                                            title="Add"
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            className="btn btn-ic btn-add"
                                                                            onClick={saveAttachment}
                                                                        >
                                                                            Add
                        </Button>
                                                                        <Button
                                                                            title="Reset"
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            className="btn btn-ic btn-reset"
                                                                            onClick={() => resetAttachment()}
                                                                        >
                                                                            Reset
                        </Button>

                                                                    </div>
                                                                    <div className="clearfix" />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </form>

                                                </Modal>
                                                {/* Modal end */}
                                            </div>
                                            <div className="clearfix" />
                                        </div>

                                        <div className="tab-holder mt-2">
                                            <TableComponent headCells={headCells} selected={selectDeleteArray}
                                                setSelected={setSelectDeleteArray}
                                                multiDelete
                                                onTableRowClick={editAttachment}
                                                tableData={getTablegetTableData(attachmentsTableData)}
                                                newfun1={editRow}
                                                defaultSortColumn="addedAuditTimeStamp" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- section end --> */}

                            {/* button area start */}
                            <div className="float-center th-btnGroup py-3">
                                <Button
                                    title="Submit Registration"
                                    variant="outlined"
                                    id="submitButton"
                                    color="primary"
                                    className="btn btn-ic btn-save"
                                    onClick={validateRegisterForm}
                                >
                                    Submit
                                                </Button>
                                <Button
                                    title="Cancel Registration"
                                    variant="outlined"
                                    color="primary"
                                    className="btn btn-cancel"
                                    onClick={cancelDialog}
                                >
                                    Cancel
                                                </Button>
                                <div className="clearfix" />
                            </div>
                            {/* button area end */}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import * as RegistrationConstants from './RegistrationConstants';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import SuccessMessage from '../../../SharedModule/MessageHandlers/SuccessMessage';
import EmailVerification from './EmailVerification';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import { VerifyVerificationCodeActions, ResendVerificationCodeActions } from '../Store/Actions/RegistrationActions';
import moment from 'moment';

export default function RegistrationDialog(props) {
    // Dialogue Starts
    const DialogContent = withStyles(theme => ({
        root: {
            padding: theme.spacing(2)
        }
    }))(MuiDialogContent);

    const DialogActions = withStyles(theme => ({
        root: {
            margin: 0,
            padding: theme.spacing(1)
        }
    }))(MuiDialogActions);

    // Dialogue Ends
    const handleClose = () => {
        props.setOpen(false);
    };

    return (
        <div>
            <Dialog className='custom-dialog custom-dialog-new-theme dialog-520' open={props.open} disableBackdropClick>
                <div className="custom-panel">
                    <div className="panel-header">New OTP Sent</div>
                    <div className="close-modal-bx" onClick={handleClose}><i class="fa fa-times"></i></div>
                </div>
                {/* <div className="form-wrapper mt-3 px-0 dialog-err-msg">
                    {popUpErrorMessages && popUpErrorMessages.length > 0 ?
                        <ErrorMessages errorMessages={popUpErrorMessages} /> : null}
                    {popUpErrorMessages && popUpSuccessMessages && popUpErrorMessages.length === 0 && popUpSuccessMessages.length > 0 ?
                        <SuccessMessage successMessages={popUpSuccessMessages} /> : null}
                </div> */}
                <div className="dialog-inner-cont mt-3">
                <DialogContent>
                    <div className="alert alert-success custom-alert mb-0">
                     <p>New <b>OTP (Temporary-Password)</b> has been sent to your registered Email Address. </p> 
                    </div>
                </DialogContent>
                <DialogActions>
                <div className="tab-header mr-2">
                    <div className="float-right th-btnGroup">
                    <Button variant='outlined' color='primary' className='btn btn-ic btn-login-new' onClick={props.goToMemberLogin}>
                            Login </Button>
                        <Button variant='outlined' color='primary' className='btn btn-ic btn-resend-email' onClick={props.handleResendEmail}>
                            Resend EMAIL </Button>
                    </div>
                    <div className="clearfix" />
                    </div>
                </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}

/* eslint-disable no-unused-vars */
import React from "react";
import { Redirect } from 'react-router';
import { Route, Switch } from "react-router-dom";
import Home from './Modules/Login/Components/Home';
// import Login from './Modules/Login/Components/Login';
import Login from './Modules/Login/Components/adminLogin'; // changed for admin login through ForgeRock
import AdminLogin from './Modules/Login/Components/adminLogin';
import TermsOfUse from './Modules/Login/Components/TermsOfUse';
import PrivacyPolicy from './Modules/Login/Components/PrivacyPolicy';
import BrowserRequirements from './Modules/Login/Components/BrowserRequirement';
import AccessibilityCompliance from './Modules/Login/Components/AccessibilityCompliance';
import Registration from './Modules/Registration/Components/Registration';
import MemberConsent from './Modules/Registration/Components/MemberConsent';
import AppRegistration from './Modules/AppRegistration/Components/AppRegistration';
import PayerRegistration from './Modules/PayerRegistration/Components/PayerRegistration';
import AppSearch from './Modules/AppRegistration/Components/AppSearch';
import ConsentViewer from './Modules/Consents/Components/ConsentViewer';
import AppPayerManagement from './Modules/AppRegistration/Components/AppPayerManagement';
import MemberManagement from './Modules/MemberManagement/Components/MemberManagement';
import PayerSearch from './Modules/PayerRegistration/Components/PayerSearch';
import AppApproveReject from './Modules/AppRegistration/Components/AppApproveReject';
import AppPrivacyPolicy from './Modules/AppRegistration/Components/AppPrivacyPolicy';
import PayerApproveReject from './Modules/PayerRegistration/Components/PayerApproveReject';
// import AppRevoke from './Modules/AppRegistration/Components/AppRevoke';
import RegistrationOptions from './Modules/Registration/Components/RegistrationOptions';
import OldPayerDataExchangeConsent from './Modules/Registration/Components/OldPayerDataExchangeConsent';
import OldPayerDataExchangeDetails from './Modules/Registration/Components/OldPayerDataExchangeDetails';
import ForgotUserID from './Modules/ResetProfile/Components/ForgotUserID';
import PasswordRecovery from './Modules/ResetProfile/Components/PasswordRecovery';
import RegistrationSuccess from './Modules/Registration/Components/RegistrationSuccess';
import Claims from './Modules/Claims/Components/Claims';
import ChangePassword from './Modules/ResetProfile/Components/ChangePassword';
import FeedBack from './Modules/FeedBackReport/Components/Feedback';
import AdminView from './Modules/Login/Components/AdminView';
import ContactUs from './Modules/ContactUs/Components/ContactUs';
import GenericNotFound from './Modules/GenericNotFound/Components/404NotFound';
import PDFViewer from './SharedModule/PDF/PDFViewer';
import Wip from './SharedModule/WIP/Wip';
import Help from './Modules/Help/Help';
import DocViewer from './SharedModule/PDF/docViewer';
import Wrapper from './SharedModule/Layout/wrapper';
import NonWrapper from './SharedModule/Layout/nonWrapper';
import Reports from './Modules/Reports/Components/Reports';
import ConsentsManagement from './Modules/ConsentsManagement/Components/ConsentsManagement';
import PDF_View from './Modules/Reports/Components/PDF_Report';
import PayerConsent from './Modules/PayerRegistration/Components/PayerConsent';
import PayerConsentCpy from './Modules/PayerRegistration/Components/payeconsent_cpy';


export default function RoutesApp(props) {
  const isLoggedIn = sessionStorage.getItem('isLoggedIn');
  const cnRole = localStorage.getItem('cnRole');
  const token = sessionStorage.getItem('token');

  return (
    <React.Fragment>
      <Switch>
      <PublicRoute exact path="/" component={Home} />
      <PublicRoute path="/home" component={Home} />
      <PublicRoute path="/login" component={Login} />
      <PublicRoute path="/adminLogin" component={AdminView} />
      <PublicRoute path="/registration" component={Registration} />
      <PublicRoute path="/registrationOptions" component={RegistrationOptions} />
      <PublicRoute path="/oldpayerDataExchangeConsent" component={OldPayerDataExchangeConsent} />
      <PublicRoute path="/oldpayerDataExchangeDetails" component={OldPayerDataExchangeDetails} />
      <PublicRoute path="/forgotUserID" component={ForgotUserID} />
      <PublicRoute path="/passwordRecovery" component={PasswordRecovery} />  
      <PublicRoute path="/changePassword" component={ChangePassword} /> 
      <PublicRoute path="/success" component={RegistrationSuccess} />  
      <PublicRoute path='/claims' component={Claims} /> 
      <PublicRoute path='/feedback' component={FeedBack} />
      <PublicRoute path='/contactUs' component={ContactUs} />
      <PublicRoute path='/termsOfUse' component={TermsOfUse} />
      <PublicRoute path='/privacyPolicy' component={PrivacyPolicy} />
      <PublicRoute  path='/appPrivacyPolicy' component={AppPrivacyPolicy}/>
      <PublicRoute  path='/memberConsent' component={MemberConsent}/>
      <PublicRoute path='/BrowserRequirements' component={BrowserRequirements} />
      <PublicRoute path='/AccessibilityCompliance' component={AccessibilityCompliance} />
      <PublicRoute path='/Help' component={PDFViewer} />
      <PublicRoute path="/appRegistration" component={AppRegistration} />
      <PublicRoute path='/Help2' component={Help} />
      <PublicRoute path='/doc' component={DocViewer} />
      <PublicRoute path='/test' component={PDF_View} />
      <PublicRoute path="/payerRegistration" component={PayerRegistration} />
      <PublicRoute path="/payerConsent" component={PayerConsent} />
      <PublicRoute path="/payerConsentcpy" component={PayerConsentCpy} />
      <PrivateRoute userId={token && cnRole == "admin" ? isLoggedIn : null } path='/appPayerManagement' component={AppPayerManagement} redirectPath='/login' />
      <PrivateRoute userId={token ? isLoggedIn : null} path='/memberManagement' component={MemberManagement} redirectPath='/login' />
      
      <PrivateRoute userId={token ? isLoggedIn : null} path='/consentsManagement' component={ConsentsManagement} redirectPath='/login' />
      <PrivateRoute userId={token && cnRole == "admin" ? isLoggedIn : null } path='/reports' component={Reports} redirectPath='/login' />
      <PrivateRoute userId={cnRole} path='/consentViewer' component={ConsentViewer} redirectPath='/login' />
      <PrivateRoute userId={isLoggedIn} path='/appSearch' component={AppSearch} redirectPath='/login' />
      {/*disabled payer management routes for MO*/}
      {/* <PrivateRoute userId={isLoggedIn} path='/payerSearch' component={PayerSearch} redirectPath='/login' /> */}
      <PrivateRoute userId={token ? isLoggedIn : null} path='/appApproveReject' component={AppApproveReject} redirectPath='/login'/>
      {/* <PrivateRoute userId={isLoggedIn}  path='/payerApproveReject' component={PayerApproveReject} redirectPath='/login'/> */}
      <PublicRoute path='/static' component={GenericNotFound} />
      <PublicRoute path="/wip" component={Wip} />
      <PublicRoute path='*' exact={true} component={GenericNotFound} />
      
      </Switch>
    </React.Fragment>
  );
}


function PrivateRoute ({component: Component, userId, redirectPath, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => userId
        ? <Wrapper><Component {...props} /></Wrapper>
        : <Redirect to={{pathname: redirectPath}} />}
    />
  )
}

function PublicRoute ({component: Component, ...rest}) {
  return (
    <Route {...rest} render={props => (
      <NonWrapper><Component {...props} /></NonWrapper>
    )} />
  )
}
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import * as serviceEndPoint from '../../../SharedModule/Service/service';
import axios from 'axios';
import PDFConsent from './PDF_Consent';
import ReactToPrint from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { setPrintLayout } from "../../../SharedModule/Store/Actions/AppActions";
import dateFnsFormat from "date-fns/format";
import IdleSessionLogout from '../../../SharedModule/SessionManagement/IdleSessionLogout';

export default function ConsentViewer(props) {
    const dispatch = useDispatch();
    const toPrintConsentRef = useRef();
    const search = useLocation().search;
    const [spinnerLoader, setSpinnerLoader] = useState(false);
    const [printConsentView, setPrintConsentView] = useState(false);
    const consentId = new URLSearchParams(search).get('id');
    const [memberId, setMemberId] = useState('');
    const [memberName, setMemberName] = useState('');
    const [consentDate, setConsentDate] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
      axios.get(`${serviceEndPoint.FETCH_CONSENT}?id=${consentId}`)
      .then(response => {
        setConsentDate(response?.data?.data?.consentDate? formatDate(response?.data?.data?.consentDate):'');
        setMessage(response?.data?.data?.consentHtml);
        setMemberId(response?.data?.data?.memberId);
        setMemberName(response?.data?.data?.memberName);
        //setConsentDate(response?.data?.data?.date);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
    
  const formatDate = (dt) => {
    if (!dt) {
      return "";
    }
    dt = new Date(dt);
    if (dt.toString() == "Invalid Date") {
      return dt;
    } else {
      return dateFnsFormat(dt, "MM/dd/yyyy HH:mm:ss");
    }
  };
  
    return (
        <div className="content-wrapper block-elm" >
          <IdleSessionLogout/>
          <div class="tab-footer px-3 block-elm pt-2 pb-2">
            <div class="float-left pt-2">
              <b>Consent for Member</b> : {memberId}
            </div>
            <div class="float-left pt-2 pl-5">
              <b>Member Name</b> : {memberName}
            </div>
            <div class="float-left pt-2 pl-5">
              <b>Consent Date</b> : {consentDate}
            </div>
            <ReactToPrint
              onBeforeGetContent={() => {
                dispatch(setPrintLayout(true));
                setSpinnerLoader(true);
                return new Promise((resolve) =>
                  setTimeout(() => resolve(), 100)
                );
              }}
              onAfterPrint={() => {
                setSpinnerLoader(false);
                dispatch(setPrintLayout(false));
              }}
              trigger={() => (
                <div class="float-right th-btnGroup">
                  <button className="btn btn-default" onClick={()=>setPrintConsentView(true)}>
                      <i className="fa fa-download" /> PDF
                  </button>
                </div>
              )}
              content={() => toPrintConsentRef.current}
            />
          </div>
          
          <div dangerouslySetInnerHTML={{ __html: message}}></div>
          <div className="hide-on-screen" ref={toPrintConsentRef}>
            <PDFConsent memberId={memberId} consentMessage={message} consentDate={consentDate} memberName={memberName}/>
          </div>
        </div>);
}
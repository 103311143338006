import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from 'react-bootstrap';
import GraphView from './GraphView';
import GridView from './GridView';
import GraphViewPrint from './GraphViewPrint';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import { Dropdown } from 'react-bootstrap';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/core/styles';
import ReactToPrint from 'react-to-print';
import { setPrintLayout, LogoutActions, EventsLogActions, resetLogoutData } from '../../../SharedModule/Store/Actions/AppActions';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import Header from '../../../SharedModule/Layout/header';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import moment from 'moment';

function PDFReport(props) {
    const {reportType,reportData,filterType,trendReportData,showTrendGraph,reportsRequestObject, reportID,memberID,trenGraphTitle}=props;
    const drawerWidth = 240;
    const useStyles = makeStyles(theme => ({
        wrapper: {
            display: 'flex',
            height: '100%',
            padding: '20px 0 0 0'
        },
        drawer: {
            boxSizing: 'border-box',
            width: drawerWidth,
            flexShrink: 0
        },
        drawerPaper: {
            position: 'relative',
            boxSizing: 'border-box',
            width: drawerWidth
        },
        drawerPaperShrink: {
            position: 'relative',
            boxSizing: 'border-box',
            width: 100
        },
        loginStatus: {
            display: 'flex',
            alignItems: 'center',
            float: 'right'
        },
        dropDownItem: {
            padding: '10px 15px',
            color: '#D5D5D5'
        },
        nestedFolder: {
            paddingRight: '16px!important'
        }
    }));

    const classes = useStyles();
    const dispatch = useDispatch();
    const toPrintRef = useRef();
    const [filterText,setFilterText]=React.useState("");
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const printLayout =true;
    let printclasses = ['main-toggle-button', 'm-4', 'p-0', 'float-right', 'print-toggle-width','mb-4'];
    let classes1 = ['main-toggle-button', 'm-0', 'p-0', 'float-right', 'toggle-width'];
    let classes2 = ['sec-toggle-button', 'm-0', 'p-0', 'float-right', 'toggle-width'];
    
    useEffect(()=>{
        if(reportsRequestObject){
            const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
            switch (filterType) {
                case "Custom":
                    setFilterText(`${moment(reportsRequestObject?.startDate).format("MM/DD/yyyy")} - ${moment(reportsRequestObject?.endDate).format("MM/DD/yyyy")}`);
                    break;
                case "Monthly":
                    let mstartDt=moment(reportsRequestObject?.startDate).add(1, 'days');
                    setFilterText(`${monthNames[new Date(mstartDt)?.getMonth()]} ${new Date(mstartDt).getFullYear()}`);
                    break;
                case "Quarterly":
                    let qstartDt=moment(reportsRequestObject?.startDate).add(1, 'days');
                    setFilterText(`${monthNames[new Date(qstartDt)?.getMonth()]} ${new Date(qstartDt).getFullYear()} - ${monthNames[new Date(reportsRequestObject?.endDate)?.getMonth()]} ${new Date(reportsRequestObject?.endDate)?.getFullYear()}`);
                    break;
                default:
                    break;
            }
        }
      
    },[reportsRequestObject]);

    return (
        <>
        <div ref={toPrintRef} className="pdfPrint">
        <Header print />
            <div className="body-container" >
                {spinnerLoader ? <Spinner /> : null}
                <div className="form-header-container col hide-on-print" id="header">
                    <div className="reg-heading float-left">Reports</div>
                </div>
                <div className="container-lg pt-2">
                    <div className={`feedback-container mt-5`}>
                        <div className="row mx-0 p-4">
                            <div className={printLayout ? "col-4 text-left px-0" : "col-sm-5 col-lg-4 d-inline-block pb-2 px-0 text-center text-sm-left"}>
                                <span>Report ID: {reportID}</span>
                            </div>
                            <div className={printLayout ? "col-6 text-right" : "col-sm-7 col-lg-5 text-center d-inline-block p-0"}>
                                {reportType === "MemberConsentHistory" && (
                                    <span>
                                        {`MO Medicaid Member Portal - Consent History Report`}
                                        <br/> {moment().format('L')}
                                    </span>
                                )}
                                {reportType !== "MemberConsentHistory" && (
                                    <span>
                                        {`MO Medicaid Member Portal - Feedback Report`}
                                        <br/> {moment().format('L')}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="feedback-data-container">
                            <div className="feedback-data-display-container">
                                <div className="col-10 px-0 d-inline-block">
                                    {reportType !== "MemberConsentHistory" && (
                                        <div className="m-0 mb-3 feedback-dropdown">
                                        {/* <TextField
                                            select
                                            required
                                            id="feedback-month"
                                            label="Reporting Period"
                                            inputProps={{ id: 'feedBackMonth', maxLength: 50 }}
                                            InputLabelProps={{
                                                shrink: true,
                                                for: 'feedBackMonth'
                                            }}
                                            value={filterType}
                                        >
                                            <MenuItem selected key={filterType} value={filterType}>
                                                {filterText}
                                            </MenuItem>
                                        </TextField> */}
                                        {filterText}
                                    </div>
                                    )}
                                </div>
                            </div>
                            {reportData ? <>
                                
                                {/* Print View starts */}
                                <div className="col feedback-data-display-container border-line-color hide-on-screen">
                                    <div className="mt-4 mb-4">
                                    {reportType !== "MemberConsentHistory" && (
                                        <div className="col px-0 toggle-button-margin mb-4">
                                            <Button className={printclasses} >Grid View</Button>
                                        </div>
                                    )}
                                    {reportType === "MemberConsentHistory" && (
                                        <div className="col px-0 toggle-button-margin mb-4">
                                            Member ID : {memberID}
                                        </div>
                                    )}
                                    <div className="col px-0 m-4">
                                        <GridView reportType={reportType} reportData={reportData} ispdf={1}></GridView>
                                    </div>
                                    </div>
                                    <div className="mt-4 mb-4">
                                    {reportType !== "MemberConsentHistory" && (
                                         <div className="col px-0 toggle-button-margin mt-4">
                                            <Button className={printclasses} >Graph View</Button>
                                        </div>
                                    )}
                                    {!showTrendGraph && reportType !== "MemberConsentHistory" && <div className="col px-0 m-4">
                                        {/* <GraphView reportType={reportType} reportData={reportData}></GraphView> */}
                                        <GraphViewPrint reportType={reportType} reportData={reportData} graphType={"bar"}></GraphViewPrint>
                                    </div>}
                                    {showTrendGraph && reportType !== "MemberConsentHistory" && <div className="col px-0 m-4 mt-4">
                                        {/* <GraphView reportType={reportType} reportData={reportData}></GraphView> */}
                                        <GraphViewPrint reportType={reportType} reportData={reportData} trendReportData={trendReportData} graphType={"line"} showTrendGraph={showTrendGraph} trenGraphTitle={trenGraphTitle}></GraphViewPrint>
                                    </div>}
                                    </div>
                                    
                                </div>
                                {/* Print View end */}
                            </> :null}
                            <div className="px-0 pt-3 row mx-0 mt-4">
                                <div className="col-6 px-0 small-size-font font-weight-bold">
                                    Run Date: {moment().format('L')}
                                </div>
                                <div className="col-6 px-0 text-right small-size-font font-weight-bold">
                                    Run Time: {moment().format("HH:mm:ss")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}
export default PDFReport;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import * as RegistrationConstants from './RegistrationConstants';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import SuccessMessage from '../../../SharedModule/MessageHandlers/SuccessMessage';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import moment from 'moment';
import Radio from "@material-ui/core/Radio";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TableComponent from '../../../SharedModule/Table/Table';
import { appDetailsAction} from '../Store/Actions/RegistrationActions';
import {memberGetAttributesActions,memberConsentSaveActions} from '../Store/Actions/RegistrationActions';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import { TrainRounded } from '@material-ui/icons';



export default function MemberConsent(props) {

    const dispatch = useDispatch();
    //const registrationRef = useRef(null);
    const disabled=true;
    
    const [open, setOpen] = React.useState(false);
    const [decision, setDecision] = React.useState(null);
    
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };  
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogType, setDialogType] = React.useState('');
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const [successMessages, setSuccessMessages] = React.useState([]);
    const [memberConsentInfo, setMemberConsentData] = React.useState({
        appName: '',
        appOrganization:'',
        privacyPolicyURL:'',
        privacyQuestionnaireURL:'',
        consentId:'',
        termsOfURL:''

    });

    const hiddenButtonRef = useRef(null);
    const consentRef = React.useRef();
    const memberGetAttributesdata=(data)=>dispatch(memberGetAttributesActions(data));
    const memberConsentSaveDispatch = (data) => dispatch(memberConsentSaveActions(data));
    const query = new URLSearchParams(window.location.search);
    const tokenId = query.get('consent_request');
    //memberGetAttributesdata(tokenId);
    const memberConsentDetails = useSelector(state=>state.registrationState.memberConsentDetails);
    const memberConsentSaveResponse = useSelector(state=>state.registrationState.memberConsentSaveResponse);
    let errorMessagesArray = [];
    useEffect(() => {
        setSpinnerLoader(true);
        let obj = {consentRequestId:tokenId};
        memberGetAttributesdata(obj);
    }, []);

    useEffect(() => {
        if (memberConsentDetails) {
          //alert("iside");
          
            if (memberConsentDetails?.success){
                console.log(memberConsentDetails.data?.appName);
                
                errorMessagesArray = [];
                
                errorMessagesArray = AppConstants.handleAPIErrors(memberConsentDetails, errorMessagesArray, '');
                if (errorMessagesArray && errorMessagesArray.length > 0) {
                    setSpinnerLoader(false);
                    setErrorMessages(errorMessagesArray);
                } else {
                //alert("setting values");
                setSpinnerLoader(false);
                setMemberConsentData(memberConsentDetails.data);
                }
            }
            else{
                setSpinnerLoader(false);
                errorMessagesArray = [];
            
                errorMessagesArray = AppConstants.handleAPIErrors(memberConsentDetails, errorMessagesArray, '');
                if (errorMessagesArray && errorMessagesArray.length > 0) {
                    setErrorMessages(errorMessagesArray);
                }
                else{
                    if (memberConsentDetails.message){
                        setErrorMessages([memberConsentDetails.message]);
                    }
                    else{
                    setErrorMessages(['Unable to process your request. Please try later.']);
                    }
                }
            }
        }
    }, [memberConsentDetails]);

    useEffect(() => {
            if (memberConsentSaveResponse) {
            if (memberConsentSaveResponse?.success){
                //if (decision){
                console.log(memberConsentSaveResponse.data.consentApprovalRedirectUri);
                //window.location.href = "https://cmds.ioxqa.iam.apps.conduent.com/am/oauth2/realms/root/realms/iox/realms/ioxmissouri/authorize?response_type=code&state=1234&client_id=ioxmoclient&scope=openid%20profile&redirect_uri=http%3A%2F%2Flocal1.com%2Flogin&consent_response=eyJ0eXAiOiJKV1QiLCJraWQiOiJOQ3FsVVBsRVNTeWFTUGJyY1pvbXhObStkL0E9IiwiYWxnIjoiUlMyNTYifQ.eyJjbGllbnRJZCI6ImlveG1vY2xpZW50IiwiaXNzIjoicmNzIiwiY3NyZiI6Ik9qSVIvTjFiRGFVb25OSUk5ZnNaNXdMd3hRYXhQVi82cnVhdE8vZENlQzg9IiwiY2xpZW50X2Rlc2NyaXB0aW9uIjoiIiwiYXVkIjoiaHR0cDovL2NtZHMuaW94cWEuaWFtLmFwcHMuY29uZHVlbnQuY29tOjgwL2FtL29hdXRoMi9yZWFsbXMvcm9vdC9yZWFsbXMvaW94L3JlYWxtcy9pb3htaXNzb3VyaSIsInNhdmVfY29uc2VudF9lbmFibGVkIjpmYWxzZSwiY2xhaW1zIjp7fSwic2NvcGVzIjp7Im9wZW5pZCI6Im9wZW5pZCIsInByb2ZpbGUiOiJwcm9maWxlIn0sImV4cCI6MTYzMzcwOTQwMCwiaWF0IjoxNjMzNzA4NDQwLCJjbGllbnRfbmFtZSI6ImlveG1vY2xpZW50IiwiY29uc2VudEFwcHJvdmFsUmVkaXJlY3RVcmkiOiJodHRwOi8vY21kcy5pb3hxYS5pYW0uYXBwcy5jb25kdWVudC5jb206ODAvYW0vb2F1dGgyL3JlYWxtcy9yb290L3JlYWxtcy9pb3gvcmVhbG1zL2lveG1pc3NvdXJpL2F1dGhvcml6ZT9yZXNwb25zZV90eXBlPWNvZGUmc3RhdGU9MTIzNCZjbGllbnRfaWQ9aW94bW9jbGllbnQmc2NvcGU9b3BlbmlkJTIwcHJvZmlsZSZyZWRpcmVjdF91cmk9aHR0cCUzQSUyRiUyRmxvY2FsMS5jb20lMkZsb2dpbiIsInVzZXJuYW1lIjoibWQwMzA2MjEiLCJkZWNpc2lvbiI6dHJ1ZX0.Z9qO3lk-lmaDZ6D2CRZHvcq9jVBBq-9cy3a0G8NQixPcpQ8_emXR_3yYr3UhcC2ThpXsf2flRBQC6HAWER4KxxTjVN2SUXltlRv1XLYTa2aapN8QjZdNzohXDiUYjLYpv1O_6qEXwQFL2pZKlfrs1IaP-ovqYjbhGjKJUK7Xo8oIn_NrXqCMBusNXwadUFeUOKy_qFzYQwLTobVA4yy96Z5pdRVgczg2rrqlvJLzUFEZvqrch53Zh4Nb5_c9mW4uyKzgDXXTnfiHTVlYcbhhMV3-Udb4cXhM8dKUmOadD-zN8GeD7pjFd9pBlOsWYhz8GPUTexYzEVEmeesUFxdn-A";
                hiddenButtonRef.current.click();
                /*}
                else
                {
                    redirectToHomePage();
                }*/
            }
            else{
                setSpinnerLoader(false);
                errorMessagesArray = [];
            
                errorMessagesArray = AppConstants.handleAPIErrors(memberConsentSaveResponse, errorMessagesArray, '');
                if (errorMessagesArray && errorMessagesArray.length > 0) {
                    setErrorMessages(errorMessagesArray);
                }
                else {
                    if (memberConsentSaveResponse.message){
                        setErrorMessages([memberConsentSaveResponse.message]);
                    }
                    else{
                        setErrorMessages(['Unable to process your request. Please try later.']);
                    }
                }
            }
        }
    }, [memberConsentSaveResponse]);

    const submitForm = () => {
        console.log(consentRef.current.value);
    }
    const approveAccess = () => {
        setDialogOpen(false);
        //clearMessages();
        handleClose();
        let obj={
            "consentId": memberConsentInfo.consentId,
            "consentDecision": true,
            "consentRequestId":tokenId
            //"memberReferenceId": registrationValues ? registrationValues?.id : null,
        }
        setDecision(true);
        setSpinnerLoader(true);
        memberConsentSaveDispatch(obj);

       //appStatusUpdateDispatch(appDetailsData);     
    };

    const denyAccess = () => {
        setDialogOpen(false);
        //clearMessages();
        handleClose();
        let obj={
            "consentId": memberConsentInfo.consentId,
            "consentDecision": false,
            "consentRequestId":tokenId
            //"memberReferenceId": registrationValues ? registrationValues?.id : null,
        }
        setDecision(false);
        setSpinnerLoader(true);
        memberConsentSaveDispatch(obj);
       //appStatusUpdateDispatch(appDetailsData);     
    };

    const redirectToHomePage = () =>{
        props.history.push({
            pathname: "/",
          });
    }

    const showApproveDialog = () => {
        setDialogOpen(true);
        setDialogType('submit')
        return true;
        
    };

    return (
        <div className="block-elm" >
            
            {spinnerLoader ? <Spinner /> : null}
            {errorMessages && errorMessages.length > 0 ?
                <div className="form-wrapper col-sm-12 px-0 mt-2">
                <ErrorMessages errorMessages={errorMessages} />
                </div> : null}


            {memberConsentSaveResponse && memberConsentSaveResponse.data?
            <form  action={memberConsentSaveResponse.data.consentApprovalRedirectUri} method="post">
                <input ref={consentRef} type="hidden" name="consent_response" value={memberConsentSaveResponse.data.signedJwt}/>
                <input onClick={submitForm} type="submit" style={{ display: 'none' }} ref={hiddenButtonRef} />
            </form>:null}

            <Dialog
                open={dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="custom-alert-box">
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to allow access?
   
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    
                    {dialogType === 'submit' && (
                        <Button onClick={() => { approveAccess() }} color="primary" className="btn btn-success">
                            Ok
                        </Button>
                    )
                    }


                    <Button onClick={() => { setDialogOpen(false); }} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {/* <!-- main content start --> */}
            {memberConsentDetails != null  && memberConsentDetails.data?
            <div className="tabs-container container">
                <div className="contactUs-container pl-0 row">
                    <div className="contactUs-subContainer"> 
                        <div className="terms-of-use-head p-4">
                            <b className="p-2 dib"> MEMBER CONSENT ACCESS </b>
                        </div>
                                    {/* border block start */}
                                    
                                    {/* border block end */}
                                    {/* border block start */}
                                    
                        <div className="container-body p-4 mt-2">
                            <section className="block-elm px-2 mb-3"><p><b>Allow Access to Your Account</b></p>
                            </section>
                            <section className="block-elm px-2 mb-3"><p>{memberConsentInfo.appName} wants to share your health data with them.
                            </p>
                            <p>What you need to know about {memberConsentInfo.appName}?
                            </p>
                            <p>{memberConsentInfo.appName} is not provided by the State of Missouri - MO HealthNet Division; it is provided by {memberConsentInfo.appOrganization}. {memberConsentInfo.appOrganization} is not covered by the Health Insurance Portability and Accountability Act, or HIPAA.  Please review {memberConsentInfo.appName}'s Privacy Policy at <a href={memberConsentInfo.privacyPolicyURL} target="_blank">{memberConsentInfo.privacyPolicyURL}</a> and Terms of Service at <a href={memberConsentInfo.termsOfURL} target="_blank">{memberConsentInfo.termsOfURL}</a> before allowing {memberConsentInfo.appName} to access to your account.  The privacy policy should explain clearly the answers to the following questions:</p>
                            </section>
                            <div className="block-elm px-2 mb-3">
                                <ul className="numberList">
                                    <li>Who is offering the app?</li>
                                    <li>How is this app funded?</li>
                                    <li>Where does this app save your data?</li>
                                    <li>Who has access to your data when you provide it to this app?  Does this app use third-party persistent cookies, also known as tracking cookies?</li>
                                    <li>Does the app developer allow you to obtain a record of the data collected about you?</li>
                                    <li>Does this app allow you to delete the data stored about you?</li>
                                    <li>How does the app developer use your data?</li>
                                </ul>
                            </div>

                            <section className="block-elm px-2 mb-3">
                            <p>This app will have access to your information for a period of 90 days, after which you will need to sign in again.</p>
                            <p>MO HealthNet may revoke access to this app based on fraud, security, or other concerns that could harm our participants.</p>
                            <p>Ability to access data via an app may be restricted due to MO HealthNet participant age and/or eligibility in accordance with state and federal policies and regulations.</p>
                            <p>{memberConsentInfo.appName} wants your permission to access the following information:</p>
                            </section>
                            <div className="block-elm px-2 mb-3">
                                <ul className="numberList">
                                    <li>Personal Information (Full Name, Date of Birth, Address)</li>
                                    <li>Claims and Clinical Data (Doctor visit, Treatment, Medication, Cost details, Lab Results, Substance Use Disorder Treatment, Procedures , Conditions etc.) </li>

                                </ul>
                            </div>
                            <section className="block-elm px-2 mb-3">
                            <p>{memberConsentInfo.appName} is not provided by the State of Missouri - MO HealthNet Division; it is provided by {memberConsentInfo.appOrganization}. {memberConsentInfo.appOrganization} is not covered by the Health Insurance Portability and Accountability Act (HIPAA) or applicable state laws.  By allowing {memberConsentInfo.appOrganization} access to your personally identifiable information, you acknowledge that the Missouri Department of Social Services/MO HealthNet Division is not responsible for any disclosure of your data by {memberConsentInfo.appOrganization}.  Once your data leaves the Missouri Department of Social Services/MO HealthNet's system, we can no longer protect it.</p>
                            <p>The Privacy Policy Questionnaire responses provided by {memberConsentInfo.appName} may be found <a href={memberConsentInfo.privacyQuestionnaireURL} target="_blank">here</a>.</p>
                            <p >Alcohol and substance use disorder treatment records are specifically protected by federal regulations (42 CFR Part 2) and by agreeing to the release to the {memberConsentInfo.appName} you are allowing the release of any alcohol and/or substance use information or records (if any) that you may have.</p>
                            
                            <p>Prohibitions of Re-Disclosure: Federal regulations (42 CFR Part 2) prohibit the recipient of substance use disorder treatment records from making further disclosure of those records without the specific written authorization of the person to whom those records pertain, or as otherwise specified by such regulation.</p>
                           
                            <p>Do you approve or deny the release of your data, including alcohol and substance use disorder treatment information:</p>
                            
                            </section>
                        </div>
                        <div className="float-center th-btnGroup py-3">
                            <Button variant="outlined" color="primary" className='btn btn-cancel' 
                            onClick={denyAccess}>
                                Deny Access </Button>
                            <Button variant="outlined" color="primary" className='btn btn-ic btn-next' id="submitButton"
                                onClick={showApproveDialog}>
                                Allow access </Button>
                            
                        </div>
                                    
                        {/* border block end */}
                        {/* border block start */}
                        
                        {/* border block end */}
                    </div>

                </div>
                        
            </div>:null}
        </div>
    )
}

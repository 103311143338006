import React from 'react';

export default function ContactUsForm(props) {    
    return (
        <>
            <div className="form-header-container col" id="header">
                <div className="form-heading float-left">Contact Us</div>
            </div>
            <div className="tabs-container container">
                <div className="contactUs-container pl-0 row">
                    <div className="contactUs-subContainer">                   
                        <div className="col-12 contactUs-headers-padding semi-bold border-bottom-contactUs">
                            TELEPHONE INQUIRIES
                        </div>  
                        <div className="col-12 contactUs-headers-padding">
                        If you need further information, you may contact Participant Inquiries (800) 392-2161
                        </div>             
{/*                         <div className="col-12 contactUs-headers-padding semi-bold border-bottom-contactUs">
                            INTERNET ADDRESSES
                        </div>
                        <div className="col-12 contactUs-headers-padding">
                            <div className="col-12 semi-bold contactUs-table-headers">
                                Name & Link
                            </div>
                            <div className="row mx-0">
                                <div className=" col-md-6 col-lg-5 contactUs-table-data d-inline-block">
                                    Alaska Medical Assistance<br></br>
                                    <a href="http://dhss.alaska.gov/dhcs/Pages/medicaid_medicare/" className="contact-hyperlink">
                                        http://dhss.alaska.gov/dhcs/Pages/medicaid_medicare/
                                    </a>
                                </div>
                                <div className="col-md-6 col-lg-7 contactUs-table-data d-inline-block">
                                    Conduent<br></br>
                                    <a href="https://www.medicaidalaska.com/" className="contact-hyperlink">
                                        https://www.medicaidalaska.com/
                                    </a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {/* Telephone Inquiries */}
                    {/* <div className="contactUs-subContainer contactUs-subHeader-margin">
                        <div className="col-12 contactUs-headers-padding semi-bold border-bottom-contactUs">
                            TELEPHONE INQUIRIES
                        </div>
                        <div className="col-12 contactUs-questions-padding contactUs-Question-color">
                            <p className="m-0">
                                Questions? Please call Conduent at (907) 644-6800 or our in-state toll free number, (800) 770-5650,
                                about your participation in Alaska Medical Assistance. The Conduent staff has been fully trained to answer most of your questions immediately.
                                <br></br><br></br>
                                The following numbers can help you with other more specific question
                            </p>
                        </div>
                        <div className="col-12 contactUs-headers-padding">
                            <table className="mytable mobile-optimised" >
                                <thead>
                                    <tr>
                                        <th scope="col" className="contactUs-table-headers">Department</th>
                                        <th scope="col" className="contactUs-table-headers">Toll-Free</th>
                                        <th scope="col" className="contactUs-table-headers">Regular Line</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td data-th="Department" className="contactUs-table-data">
                                            <p className="m-0">Member Eligibility<br></br>
                                            (8:00 AM - 5.00 PM, Monday - Friday)</p>
                                        </td>
                                        <td data-th="Toll-Free" className="contactUs-table-data"><p className="m-0">(800) 770-5650 (option 1, 1, 2)</p></td>
                                        <td data-th="Regular Line" className="contactUs-table-data"><p className="m-0">(907) 644-6800 (option 1, 2)</p></td>
                                    </tr>
                                    <tr>
                                        <td data-th="Department" className="contactUs-table-data">
                                            <p className="m-0">Member Services and Fair Hearings<br></br>
                                            (8:00 AM - 5.00 PM, Monday - Friday)</p>
                                        </td>
                                        <td data-th="Toll-Free" className="contactUs-table-data"><p className="m-0">(800) 770-5650 (option 2)</p></td>
                                        <td data-th="Regular Line" className="contactUs-table-data"><p className="m-0">(907) 644-6800 (option 6)</p></td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div> */}
                    {/*  HIPPA CONTACTS */}
                    {/* <div className="contactUs-subContainer contactUs-subHeader-margin">
                        <div className="col-12 contactUs-headers-padding semi-bold border-bottom-contactUs">
                            HIPPA CONTACTS
                        </div>
                        <div className="col-12 contactUs-headers-padding">
                            <table className="mytable mobile-optimised hippa" >
                                <thead>
                                    <tr>
                                        <th scope="col" className="contactUs-table-headers">Topic</th>
                                        <th scope="col" className="contactUs-table-headers">Organization</th>
                                        <th scope="col" className="contactUs-table-headers">Department</th>
                                        <th scope="col" className="contactUs-table-headers">Phone Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td data-th="Topic" className="contactUs-table-data">
                                            <p className="m-0">Privacy & Security</p>
                                        </td>
                                        <td data-th="Organization" className="contactUs-table-data">
                                            <p className="m-0">
                                                Office of Civil Rights (Federal)<br></br>
                                                <a href="http://www.hhs.gov/ocr/hippa" className="contact-hyperlink-color">http://www.hhs.gov/ocr/hippa</a>
                                            </p>
                                        </td>
                                        <td data-th="Department" className="contactUs-table-data"><p className="m-0">Division of Administrative Services</p></td>
                                        <td data-th="Phone Number" className="contactUs-table-data"><p className="m-0">(877) 696-6775</p></td>
                                    </tr>
                                    <tr >
                                        <td data-th="Topic" className="contactUs-table-data">
                                            <p className="m-0">Privacy & Security</p>
                                        </td>
                                        <td data-th="Organization" className="contactUs-table-data">
                                            <p className="m-0">
                                                Office of Civil Rights (State)<br></br>
                                                <a href="http://www.hhs.state.ak.us" className="contact-hyperlink-color">http://www.hhs.state.ak.us</a>
                                            </p>
                                        </td>
                                        <td data-th="Department" className="contactUs-table-data"><p className="m-0">Division of Administrative Services</p></td>
                                        <td data-th="Phone Number" className="contactUs-table-data"><p className="m-0">(907) 269-5100</p></td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div> */}
                </div>
            </div>
        </>                        
    )
}

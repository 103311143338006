import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from 'redux-persist/integration/react';
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { BrowserRouter as Router } from "react-router-dom";
import './App.scss';
import Layout from './SharedModule/Layout/layout';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import sharedReducer from './SharedModule/Store/Reducers/AppReducer';
import loginReducer from './Modules/Login/Store/Reducers/LoginReducer';
import registrationReducer from './Modules/Registration/Store/Reducers/RegistrationReducer';
import AppRegistrationReducer from './Modules/AppRegistration/Store/Reducers/AppRegistrationReducer';
import PayerRegistrationReducer from './Modules/PayerRegistration/Store/Reducers/payerRegistartionReducer';
import resetProfileReducer from './Modules/ResetProfile/Store/Reducers/ResetProfileReducer';
import claimsReducer from './Modules/Claims/Store/Reducers/ClaimsReducer';
import feedbackReducer from './Modules/FeedBackReport/Store/Reducers/FeedBackReducer';
import reportReducer from './Modules/Reports/Store/Reducers/ReportReducer';
import consentsManagementReducer from './Modules/ConsentsManagement/Store/Reducers/ConsentsManagmentReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//AppRegistrationReducer.js

const appRegistrationReducerConfig = {
  key: "AppRegistrationState",
  storage: storage,
  whitelist: ["appDetails"],
};
const payerRegistrationReducerConfig = {
  key: "payerRegState",
  storage: storage,
  whitelist: ["payerDetails"],
};
const ConsentsManagementReducerConfig = {
  key: "ConsentsManagementState",
  storage: storage,
  whitelist: ["consents"],
};

const rootReducer = combineReducers({
  sharedState: sharedReducer,
  loginState: loginReducer,
  registrationState: registrationReducer,
  resetProfileState: resetProfileReducer,
  claimsState: claimsReducer,
  feedbackState: feedbackReducer,
  reportState: reportReducer,
  payerRegState: persistReducer(payerRegistrationReducerConfig,PayerRegistrationReducer),
  AppRegistrationState: persistReducer(appRegistrationReducerConfig, AppRegistrationReducer),
  consentsManagementState: persistReducer(ConsentsManagementReducerConfig, consentsManagementReducer),
});

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));

const persistor = persistStore(store);
const app = (
  <Provider store={store}>
     <Router>
     <PersistGate persistor={persistor}>
      <Layout/>
      </PersistGate>
    </Router>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import * as actionTypes from '../Actions/ReportActionTypes';

const initialStates={
     reportsData:null,
     trendReportsData:null
}

const reportReducer=(state=initialStates,action)=>{
    switch (action.type) {
        case actionTypes.GET_REPORTS_DATA:
            return {...state,reportsData:action.payload}
        case actionTypes.GET_TREND_REPORTS_DATA:
            return {...state,trendReportsData:action.payload}
        case actionTypes.RESET_REPORT_DATA:
              return { ...state, reportsData: null,trendReportsData:null }
        default:
            return state
    }
}
export default reportReducer;
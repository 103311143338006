/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import * as RegistrationConstants from './RegistrationConstants';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import SuccessMessage from '../../../SharedModule/MessageHandlers/SuccessMessage';
import { ResendEmailActions } from '../Store/Actions/RegistrationActions';
import moment from 'moment';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import ResentOTPDialog from './resendOTPDialog';

export default function RegistrationSuccessForm(props) {
    const dispatch = useDispatch();
    let successMessagesArray = [];
    let errorMessagesArray = [];
    // eslint-disable-next-line no-unused-vars
    const [registrationData, setRegistrationData] = React.useState(
        props.location.state && props.location.state.registrationValue
            ? props.location.state.registrationValue
            : []
    );
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [successMessages, setSuccessMessages] = React.useState([]);
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    // Dispatch Actions
    const resendEmailDispatch = (resendEmailCriteria) => dispatch(ResendEmailActions(resendEmailCriteria));

    const resendEmailData = useSelector(state => state.registrationState.resendEmail);

    useEffect(() => {
        if (resendEmailData) {
            setSpinnerLoader(false);
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(resendEmailData, errorMessagesArray, RegistrationConstants.REGISTRATION_TECHNICAL_ERROR);
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                setErrorMessages(errorMessagesArray);
            } else {
                successMessagesArray.push(RegistrationConstants.RESEND_EMAIL_SUCCESS);
                setSuccessMessages(successMessagesArray); 
                // setOpen(true);
            }
        }
    }, [resendEmailData]);

    // Method to resend Email
    const handleResendEmail = () => {
        setOpen(false);
        const resendEmailRequest = {
            userId: registrationData.userID ? registrationData.userID : '',
            firstName: registrationData.firstName ? registrationData.firstName : '',
            lastName: registrationData.lastName ? registrationData.lastName : '',
            email: registrationData.emailAddress ? registrationData.emailAddress : '',
            dob: registrationData.dob ? moment(registrationData.dob).format('YYYY-MM-DD') : ''
        };
        setSpinnerLoader(true);
        resendEmailDispatch(resendEmailRequest);
    }

    //Method toredirect to Member Login
    const goToMemberLogin = () => {
        setOpen(false);
        window.location.href = process.env.REACT_APP_MEMBER;
    }

    return (
        <div className="container">
            {spinnerLoader ? <Spinner /> : null}
            <div className="success-alert-container">
                {errorMessages && errorMessages.length > 0 ?
                    <ErrorMessages errorMessages={errorMessages} /> : null}
                {errorMessages && successMessages && errorMessages.length === 0 && successMessages.length > 0 ?
                    <SuccessMessage successMessages={successMessages} /> : null}
            </div>
            <div className="contact-us-block-success font-weight-600"> 
                { <a href="/contactUs" target="_blank">Contact Us</a> }
                {/* <a href="https://www.conduent.com/contact-us/" target="_blank">Contact Us</a>*/}
                <span className="blue-font px-1">|</span> 
                {/* <div className="float-right"> */}
                    <a href="/Help2" onClick={()=>{localStorage.setItem("ioxHelpDoc","member")}} target="_blank" className="dib fw-600 py-2 mt-1"> Help </a>
                {/* </div> */}
                {/* <a href="https://www.conduent.com/contact-us/frequently-asked-questions/" target="_blank">Help</a>           */}
            </div>
            <div className="success-container custom-success-container">
                <div className="">
                    <img src="Successfully_submitted.svg" className="mx-auto d-block" alt="Successfully Submitted" />
                    <h6 className="text-center font-weight-bold my-3">Congratulations!</h6>
                    <div className="alert alert-success custom-alert">
                    <p>You have successfully registered for web access. </p> 
                    <p>You will receive a confirmation of your registration containing your one-time password via the email 
                        you entered. 
                    </p>
                    <p>If you requested to receive your data from previous Payer, please follow instructions that you will receive on your registered email id.</p>
                    </div>
                    {/* <p className="text-center px-lg-5 m-0 mb-5">Please login to continue.</p> */}
                    <div className="tab-header mr-2">
                    <div className="float-right th-btnGroup">
                    {/*disabled for MO*/}
                    {/* <Button variant='outlined' color='primary' className='btn btn-ic btn-login-new' onClick={goToMemberLogin}>
                            Login </Button>  */}
                        <Button variant='outlined' color='primary' className='btn btn-ic btn-resend-email' onClick={handleResendEmail}>
                            Resend EMAIL </Button>
                        
                    </div>
                    </div>
                </div>
            </div>
            {open ? <ResentOTPDialog open={open} setOpen={setOpen} goToMemberLogin={goToMemberLogin} handleResendEmail={handleResendEmail}/> : null}
        </div>
    )
}

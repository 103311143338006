export const USER_LOGIN = 'LOGIN';
export const ADMIN_USER_LOGIN = 'ADMIN LOGIN';
export const USER_PASSWORD = 'PASSWORD';
export const ADMIN_USER_PASSWORD = 'ADMIN PASSWORD';
export const USER_ID = 'User ID';
export const PASSWORD = 'Password';
export const REQUIRED = ' is required.';
export const INVALID = ' is invalid.';
export const DISCLAIMER = 'Forgot User ID? or Forgot Password? Please contact our Technical Support Team for your User ID/Password reset.';
export const CAPTCHA_ERROR = ' Captcha is required.'
export const TERMS_ERROR = ' Please read and agree to the Terms of Use and Privacy Policy.'
export const INVALID_CREDENTIALS_ERROR = 'Your login credentials are invalid. Please try again.';
export const ADMIN_USER_ID = 'ioxadmin';
export const ADMIN_PASSWORD = 'Passw0rd#';
export const PASSWORD_EXPIRED_ERROR = 'Your password has expired. You must change your password now. Please click OK to continue.'
export const PASSWORD_LONG_EXPIRED_ERROR = 'You cannot login into Member Portal because your account is locked. Please click the Forgot Password/Unlock Account to unlock your account.'
export const LOGIN_TECHNICAL_ERROR = 'System is unable to process your login request at this time. Please try again later. If problem persists please contact Customer Service (907) 644-6800 or toll-free in Alaska at (800) 770-5650.'

import React,{useState,useEffect} from 'react';
import PDFViewer from '../../SharedModule/PDF/PDFViewer';
import APIDevPDF from './IOX API Documentation_ProductDev.pdf';
import APIQAPDF from './IOX API Documentation_ProductQA.pdf';
import APIMOPDF from './IOX API Documentation_ProductMO.pdf';
import MemEnPDF from './Member educational material V0.1.pdf';
// import AppPDF from './IOX API Documentation_Product-converted.pdf';
import queryString from 'query-string';

const Help=(props)=>{
   const [moduleName,setModulename]=useState("");
   const [PDFData,setPDFData]=useState(null);
   const [docTitle,setDocTitle]=useState("");

   let docName=localStorage.getItem("IoxDocName");
   useEffect(()=>{
      setModulename(docName);
   },[docName])

   useEffect(()=>{
      switch (moduleName) {
         case "API":
            setDocTitle("API Documentation");
            if(process.env.REACT_APP_ENV == "LOCAL" || process.env.REACT_APP_ENV == "DEV" ){
               setPDFData(APIDevPDF);
            }else if(process.env.REACT_APP_ENV == "QA"){
               setPDFData(APIQAPDF);
            }else if(process.env.REACT_APP_ENV == "UAT"){
               setPDFData(APIMOPDF);
            }
            else if(process.env.REACT_APP_ENV == "PROD"){
               setPDFData(APIMOPDF);
            }
            break;
         case "Education_Materials":
            setDocTitle("Review Educational Materials");
            setPDFData(MemEnPDF);
            break;
         default:
            break;
      }
   },[moduleName]);

    return (
   <div className="content-wrapper">
      <div className="page-header mob-main-head">
         <h2 className="page-heading float-left">{docTitle}</h2>
         <div className="clearfix"></div>
      </div>
      <div className="tab-body-bordered pt-3">
         <div className="block-elm container-space">
            <PDFViewer PDF={PDFData}/>
         </div>
      </div>
</div>
    )
}

export default Help;
import * as actionTypes from '../Actions/RegistrationActionTypes';

const axiosPayLoad = {
  payload: null,
  registrationValues:null,
  newPayerDetails:null,
  memberSearchDetails:null
  //,memberConsentDetails:null
};
const reducer = (state = axiosPayLoad, action) => {
  
  switch (action.type) {
    case actionTypes.VALIDATE_REGISTRATION:
      return { ...state, validateRegistration: action.validateRegistration };
    case actionTypes.REGISTRATION_OPTIONS:
      return { ...state, registrationOptions: action.registrationOptions };
    case actionTypes.VALIDATE_REGISTRATION_OPTIONS:
      return { ...state, validateRegistrationOptions: action.validateRegistrationOptions };
    case actionTypes.VERIFY_VERIFICATION_CODE:
      return { ...state, verifyVerificationCode: action.verifyVerificationCode };
    case actionTypes.RESEND_VERIFICATION_CODE:
      return { ...state, resendVerificationCode: action.resendVerificationCode };
    case actionTypes.RESEND_EMAIL:
      return { ...state, resendEmail: action.resendEmail };
    case actionTypes.PAYER_TO_PAYER_SEARCH:
      return { ...state, payerToPayerList: action.payload };
    case actionTypes.SAVE_PAYER_CONSENT_DATA_EXCHANGE:
      return { ...state, PayerConsentData: action.payload };
    case actionTypes.REGISTRATION_VALUES:
      return { ...state, registrationValues: action.payload };
    case actionTypes.SAVE_NEW_PAYER_DETAILS:
      return { ...state, newPayerDetails: action.payload };
    case actionTypes.MEMBER_SEARCH:
      return { ...state, memberSearchDetails: action.payload };
    case actionTypes.RESET_REGISTRATION_DATA:
      return { ...state, [action.key]: '' };
    case actionTypes.MEMBER_CONSENT_GET:
      return { ...state, memberConsentDetails: action.memberConsentDetails };
    case actionTypes.SAVE_MEMBER_CONSENT_DATA_EXCHANGE:
        return { ...state, memberConsentSaveResponse: action.memberConsentSaveResponse };
    default: return state;
  }
};

export default reducer;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CustomCarousel from './CustomCarousel';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import * as LoginConstants from './LoginConstants';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import Captcha from './Captcha';
import { resetLogoutData } from '../../../SharedModule/Store/Actions/AppActions';

export default function AdminViewForm(props) {
    // Page Constants
    const dispatch = useDispatch();
    const captcha = React.createRef();
    const passwordRef = useRef(null);
    let errorMessagesArray = [];
    //const [captchaValue, setCaptchaValue] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [{
        userIDError,
        passwordError,
        captchError
    }, setShowError] = React.useState(false);
    const [{
        userIDErrorText,
        passwordErrorText,
        captchErrorText
    }, setShowErrorText] = React.useState('');
    const [showPasswordForm, setShowPasswordForm] = React.useState(false);

    // Field bindings
    const [values, setValues] = React.useState({
        userId: '',
        password: ''
    });

    useEffect(() => {
        sessionStorage.removeItem('authToken');
        dispatch(resetLogoutData('logout'));
    }, []);

    // Method to set field values on change
    const handleChanges = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    // Method to set Captcha
    const handleChangeCaptcha = (value) => {
       // setCaptchaValue(value);
    };

    // Moving cursor to the end of the text
    useEffect(() => {
        if (passwordRef && passwordRef.current) {
            passwordRef.current.selectionStart = passwordRef.current.value.length;
            passwordRef.current.selectionEnd = passwordRef.current.value.length;
        }
    }, [showPassword]);

    // Method to handle Password visibility
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
        passwordRef.current.focus();
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // Method to validate Login Form
    const validateLoginForm = () => {
        errorMessagesArray = [];
        setErrorMessages([]);
        var userIDError = false;
        var userIDErrorText = '';

        if (values.userId === '' || (values.userId && values.userId.trim() === '')) {
            userIDError = true;
            userIDErrorText = LoginConstants.USER_ID + LoginConstants.REQUIRED;
            errorMessagesArray.push(userIDErrorText);
        }
        if (!userIDError && !AppConstants.USER_ID_REGEX.test(values.userId)) {
            userIDError = true;
            userIDErrorText = AppConstants.USER_ID_INVALID;
            errorMessagesArray.push(userIDErrorText);
        }

        setErrorMessages(errorMessagesArray);
        setShowError({
            userIDError: userIDError
        });
        setShowErrorText({
            userIDErrorText: userIDErrorText
        });

        if (errorMessagesArray && errorMessagesArray.length > 0) {
            return true
        } else {
            return false;
        }
    };

    // Method to submit Login Form
    const submitLoginForm = () => {
        if (!validateLoginForm()) {
            setShowPasswordForm(true)
        }
    };

    // Method to validate Password Form
    const validatePasswordForm = () => {
        errorMessagesArray = [];
        setErrorMessages([]);
        var passwordError; var captchError = false;
        var passwordErrorText; var captchErrorText = '';

        if (values.password === '' || (values.password && values.password.trim() === '')) {
            passwordError = true;
            passwordErrorText = LoginConstants.PASSWORD + LoginConstants.REQUIRED;
            errorMessagesArray.push(passwordErrorText);
        }
        // if (captchaValue === '') {
        //     captchError = true;
        //     captchErrorText = LoginConstants.CAPTCHA_ERROR;
        //     errorMessagesArray.push(captchErrorText);
        // }

        setErrorMessages(errorMessagesArray);
        setShowError({
            passwordError: passwordError,
            captchError: captchError
        });
        setShowErrorText({
            passwordErrorText: passwordErrorText,
            captchErrorText: captchErrorText
        });

        if (errorMessagesArray && errorMessagesArray.length > 0) {
            return true
        } else {
            return false;
        }
    };

    // Method to submit Login Form
    const submitPasswordForm = () => {
        errorMessagesArray = [];
        if (!validatePasswordForm()) {
            if (values.userId && (values.userId !== LoginConstants.ADMIN_USER_ID || values.password !== LoginConstants.ADMIN_PASSWORD)) {
                errorMessagesArray.push(LoginConstants.INVALID_CREDENTIALS_ERROR);
                sessionStorage.removeItem('authToken');
                setValues({
                    userId: '',
                    password: ''
                })
                setShowPasswordForm(false);
            } else {
                // window.grecaptcha.reset();
                captcha.current.reset();
                sessionStorage.setItem('authToken', 'medicaidPortal');
                props.history.push({
                    pathname: '/feedback',
                    state: { userID: values.userId }
                });
            }
        }
    };

    return (
        <div className="container-xl">
            {errorMessages && errorMessages.length > 0 ?
                <div className="login-tab-error pt-4">
                    <div className="form-wrapper col-sm-12 p-0">
                        <ErrorMessages errorMessages={errorMessages} />
                    </div>
                </div> : null}
            <div className="login-tab mt-4">
                <div className="row vertical-wrapper">
                    <div className="show-on-md col p-0">
                        <CustomCarousel />
                    </div>
                    <div className="col px-lg-0">
                        <div hidden={showPasswordForm} aria-hidden={showPasswordForm}>
                            <div className="login-header pb-0">ADMIN LOGIN</div>
                            <div className="login-form-container">
                                <form id="loginForm" autoComplete="off" onSubmit={e => { e.preventDefault(); }}>
                                    <div className="p-2">
                                        <div className="form-wrapper col pl-0">
                                            <div className="mui-custom-form mx-3">
                                                <TextField
                                                    id="standard-admin-userId"
                                                    required
                                                    label={LoginConstants.USER_ID}
                                                    fullWidth
                                                    value={values.userId}
                                                    onChange={handleChanges('userId')}
                                                    placeholder=""
                                                    inputProps={{ maxLength: 30 }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    error={userIDError}
                                                    helperText={userIDError ? userIDErrorText : null}
                                                />
                                            </div>
                                            <div className="mui-custom-form my-3 mx-3 mb-2">
                                                <Button id="enter" className='btn-primary btn-primary-pw my-1' onClick={submitLoginForm}>
                                                    ENTER </Button><br />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div hidden={!showPasswordForm} aria-hidden={!showPasswordForm}>
                            <div className="col pl-lg-0 pl-sm-1">
                                <div className="login-header pb-0">ADMIN PASSWORD</div>
                                <div className="login-form-container">
                                    <form id="passwordForm" autoComplete="off" onSubmit={e => { e.preventDefault(); }}>
                                        <div className="p-2">
                                            <div className="form-wrapper col px-0">
                                                <div className="mui-custom-form ml-3 mx-lg-3">
                                                    <TextField
                                                        inputRef={passwordRef}
                                                        id="standard-admin-password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        required
                                                        label="Password"
                                                        fullWidth
                                                        value={values.password}
                                                        onChange={handleChanges('password')}
                                                        onBlur={() => setShowPassword(false)}
                                                        placeholder=""
                                                        inputProps={{ maxLength: 20 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">
                                                                <IconButton
                                                                    id="pass"
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {values.password ? !showPassword ? <Visibility /> : <VisibilityOff /> : null}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }}
                                                        error={passwordError}
                                                        helperText={passwordError ? passwordErrorText : null}
                                                    />
                                                </div>
                                                <Captcha handleChangeCaptcha={handleChangeCaptcha} captcha={captcha} />
                                                {captchError ? <p className=" mui-custom-form attest-error mx-3 mt-lg-3 mt-md-2 my-0">{captchErrorText}</p> : null}
                                            </div>
                                            <div className="mui-custom-form mt-3 mx-3">
                                                <Button id="login" className='btn-primary btn-primary-pw' onClick={submitPasswordForm}>
                                                    LOGIN </Button><br />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="mt-3">
                            <div className="admin-footer-container py-2 px-3 small">
                                <span className="align-middle">{LoginConstants.DISCLAIMER}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="show-on-xs">
                    <CustomCarousel />
                </div>
            </div>
        </div>
    )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Radio } from '@material-ui/core';
import * as RegistrationConstants from './RegistrationConstants';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import { GetRegistrationOptionsActions, ValidateRegistrationOptionsActions,resetRegistrationData,setRegistrationValues } from '../Store/Actions/RegistrationActions';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import moment from 'moment';
import RegistrationDialog from './RegistrationDialog';

export default function RecentVisits(props) {
    // Page Constants
    const dispatch = useDispatch();
    let errorMessagesArray = [];
    // eslint-disable-next-line no-unused-vars
    const [registrationValue, setRegistrationValue] = React.useState(
        props.location.state && props.location.state.registrationValue
            ? props.location.state.registrationValue
            : []
    );
    const [open, setOpen] = React.useState(false);
    const [disableSubmit, setDisableSubmit] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [selectedRecord, setSelectedRecord] = React.useState(null);
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const [registrationQuestions, setRegistrationQuestions] = React.useState([]);

    // Dispatch Actions
    const getRegistrationOptionsDispatch = (getRegistrationOptionsCriteria) => dispatch(GetRegistrationOptionsActions(getRegistrationOptionsCriteria));
    const validateRegistrationOptionsDispatch = (validateRegistrationOptionsCriteria) => dispatch(ValidateRegistrationOptionsActions(validateRegistrationOptionsCriteria));
    const resetRegistrationDataDispatch = (resetRegistrationOptionsCriteria) => dispatch(resetRegistrationData(resetRegistrationOptionsCriteria));

    const registrationOptionsData = useSelector(state => state.registrationState.registrationOptions);
    const validateRegistrationOptionsData = useSelector(state => state.registrationState.validateRegistrationOptions);
    const validateRegistrationData = useSelector(state => state.registrationState.validateRegistration);
    const [id, setId] = React.useState('');

    useEffect(() => {
        /* if (registrationValue && registrationValue.memberSystemId) {
            const registrationOptions = {
                memberSystemId: registrationValue.memberSystemId
            };
            getRegistrationOptionsDispatch(registrationOptions);
        } */
        if (registrationValue && registrationValue.memberSystemId) {
            setRegistrationQuestions(validateRegistrationData?.memberrecentvisitqus);
        } 
    }, []);

    useEffect(() => {
        if (registrationOptionsData) {
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(registrationOptionsData, errorMessagesArray, RegistrationConstants.REGISTRATION_TECHNICAL_ERROR);
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                setErrorMessages(errorMessagesArray);
            } else {
                setRegistrationQuestions(registrationOptionsData);
            }
        }
    }, [registrationOptionsData]);

    useEffect(() => {
        if (validateRegistrationOptionsData) {
            setSpinnerLoader(false);
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(validateRegistrationOptionsData, errorMessagesArray, RegistrationConstants.REGISTRATION_TECHNICAL_ERROR);
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                setErrorMessages(errorMessagesArray);
                setDisableSubmit(true);
            } else {
                registrationValue.id = validateRegistrationOptionsData.id;
                setId(validateRegistrationOptionsData.id);
                dispatch(setRegistrationValues(registrationValue));
                setOpen(true); 
                /* props.history.push({
                    pathname: '/oldpayerDataExchangeConsent'
                }); */
            }
        }
    }, [validateRegistrationOptionsData]);

    // Method to submit Registration Options Form
    const submitRegistrationOptionsForm = () => {
        errorMessagesArray = [];
        setErrorMessages([]);
        if (selectedRecord) {
           /*  const validateRegistrationOptionsRequest = {
                userId: registrationValue.userID,
                firstName: registrationValue.firstName,
                lastName: registrationValue.lastName,
                email: registrationValue.emailAddress,
                phoneNumber: parseInt(registrationValue.phoneNumber),
                dob: moment(registrationValue.dob).format('YYYY-MM-DD'),
                ssn: registrationValue.memberSSN,
                mailingAddress: {
                    address: registrationValue.address,
                    city: registrationValue.city,
                    state: registrationValue.state !== AppConstants.SELECT ? registrationValue.state : '',
                    postalCode: registrationValue.postalCode
                },
                challengeResponses: [
                    { challenge: registrationValue.secQs1, response: registrationValue.answer1 },
                    { challenge: registrationValue.secQs2, response: registrationValue.answer2 },
                    { challenge: registrationValue.secQs3, response: registrationValue.answer3 }
                ],
                memberId: registrationValue.memberID,
                memberSystemId: registrationValue.memberSystemId,
                tcn: selectedRecord
            }; */
            const validateRegistrationOptionsRequest= { 
               memberId: registrationValue.memberID,
                memberSystemId: registrationValue.memberSystemId,
                tcn: selectedRecord,
                userId: registrationValue.userID,
                firstName: registrationValue.firstName,
                lastName: registrationValue.lastName,
                email: registrationValue.emailAddress,
                dob: moment(registrationValue.dob).format('YYYY-MM-DD')
            } 

           /* const validateRegistrationOptionsRequest =  {
                "memberSystemId":"100000552",
                "memberId":"100000552",
                "tcn":"0",
                "userId": registrationValue.userID,
                "firstName": registrationValue.firstName,
                "lastName": registrationValue.lastName,
                "email": registrationValue.emailAddress,
                "dob": moment(registrationValue.dob).format('YYYY-MM-DD')
            } */
 
            setSpinnerLoader(true);
            validateRegistrationOptionsDispatch(validateRegistrationOptionsRequest);
        } else {
            errorMessagesArray.push(RegistrationConstants.NO_REGISTRATION_OPTION_ERROR);
            setErrorMessages(errorMessagesArray);
        }
    }

    const onClickCancel=()=>{
        resetRegistrationDataDispatch("validateRegistration");
        resetRegistrationDataDispatch("validateRegistrationOptions");
        AppConstants.backToMemberRegistration(props);
    }
    return (
        <div className="content-wrapper">
        {spinnerLoader ? <Spinner /> : null}
  
        {/* <!-- main content start --> */}
        <div className="page-header mob-main-head">
           <h1 className="page-heading float-left"> Member Registration </h1>
           <div className="float-right"><a href="/Help2" onClick={()=>{localStorage.setItem("ioxHelpDoc","member")}} target="_blank" className="dib fw-600 py-2 mt-1"> Help </a></div>
           <div className="clearfix" />
        </div>
         {errorMessages && errorMessages.length > 0 ?
                      <div className="form-wrapper col-sm-12 px-3 mt-3">
                          <ErrorMessages errorMessages={errorMessages} />
                      </div> : null}
  
        {registrationQuestions && registrationQuestions.length > 0 ? <div className="tab-body-bordered container-space">
            <div className="tab-header mob-main-head">
                <h2 className="tab-heading float-left"> Member Recent Visit Details </h2>
            </div>
           <div className="tab-header mt-2">
              <h2 className="tab-heading float-left">
                 CHOOSE YOUR RECENT VISIT IN THE LAST 6 MONTHS TO COMPLETE REGISTRATION
                      </h2>
           </div>
  
           <ul className="list-with-radio my-3 p-0">
             
              {registrationQuestions.map((registrationQuestion, index) => {
                  return (<>
                   <li>
                 <div className="sub-radio float-left m-0">
                    <Radio
                       type="radio"
                       color="primary"
                       className="m-0"
                       disableRipple
                      id={index}
                      className="form-check-input"
                      value={registrationQuestion.tcn}
                      name="check"
                      checked={selectedRecord && selectedRecord === registrationQuestion.tcn}
                      onChange={() => {
                          setSelectedRecord(registrationQuestion.tcn)
                      }
                      }
                    />
                 </div>
                 <label for={index} className="radio-notes"> {registrationQuestion.tcn == "0" ? ["None of the above"] : ['Visit on ', <strong>{registrationQuestion.dateOfVisit ? moment(registrationQuestion.dateOfVisit).format('DD MMM YYYY') : ''}</strong>, ' to ', <strong>{registrationQuestion.providerRole}</strong>, ' - ', <strong>{registrationQuestion.providerName}</strong>, ' for ', <strong>{registrationQuestion.reason}</strong>, '.']} </label> </li>
                 
                  </>)
              })}
           </ul>
              <div className="row m-0 reg-footer">
              <span className="gray-font">Page 2 of 2</span>
          </div>
           <div className="tab-footer text-left my-3">
                  <button className="btn btn-success" id="submitButton"
              disabled={disableSubmit} onClick={submitRegistrationOptionsForm}>
                  Submit
                          </button>
                  <button className="btn btn-cancel ml-3" onClick={onClickCancel}>
                  Cancel
                          </button>
              </div>
        </div>:null}
  
  
        {/* <!-- main content end --> */}
        {open ? <RegistrationDialog open={open} setOpen={setOpen} history={props.history}
                      registrationValue={registrationValue} id={id} /> : null}
     </div >
    )
}
import React,{useState,useEffect} from 'react';
import TableComponent from '../../../SharedModule/Table/Table';
import {getAppstatusCount} from './utility' ;

const appRegistrationHeadCells = [
    {
        id: 'reportName', numeric: false, disablePadding: true, label: 'Reports', enableHyperLink: false, fontSize: 12, width: '32%'
    },
    {
        id: 'submitted', numeric: false, disablePadding: false, label: 'Submitted', enableHyperLink: true, fontSize: 12, width: '17%'
    },
    {
        id: 'approved', numeric: false, disablePadding: false, label: 'Approved', enableHyperLink: true, fontSize: 12, width: '17%'
    },
    {
        id: 'rejected', numeric: false, disablePadding: false, label: 'Rejected', enableHyperLink: true, fontSize: 12, width: '17%'
    },
    {
        id: 'revoked', numeric: false, disablePadding: false, label: 'Revoked', enableHyperLink: true, fontSize: 12, width: '17%'
    }
];
const memberRegistrationHeadCells = [
    {
        id: 'reportName', numeric: false, disablePadding: true, label: 'Reports', enableHyperLink: false, fontSize: 12, width: '32%'
    },
    {
        id: 'Registrations', numeric: false, disablePadding: false, label: 'Registrations', enableHyperLink: true, fontSize: 12, width: '17%'
    },
];
const apiAccessHeadCells = [
    {
        id: 'reportName', numeric: false, disablePadding: true, label: 'Reports', enableHyperLink: false, fontSize: 12, width: '32%'
    },
    {
        id: 'patientCount', numeric: false, disablePadding: false, label: 'Patient Access API', enableHyperLink: true, fontSize: 12, width: '17%'
    },
    {
        id: 'providerCount', numeric: false, disablePadding: false, label: 'Provider Directory API', enableHyperLink: true, fontSize: 12, width: '17%'
    },
    {
        id: 'drugCount', numeric: false, disablePadding: false, label: 'Covered Drug List API', enableHyperLink: true, fontSize: 12, width: '17%'
    },
];
const memberDataAccessHeadCells = [
    {
        id: 'reportName', numeric: false, disablePadding: true, label: 'Reports', enableHyperLink: false, fontSize: 12, width: '32%'
    },
    {
        id: 'accessDistinct', numeric: false, disablePadding: false, label: 'Unique member access data once', enableHyperLink: true, fontSize: 12, width: '17%'
    },
    {
        id: 'accessMTOnce', numeric: false, disablePadding: false, label: 'Unique member access data more than once', enableHyperLink: true, fontSize: 12, width: '17%'
    },
];

const consentHistoryAccessHeadCells = [
    {
        id: 'appName', numeric: false, disablePadding: true, label: 'App Name', enableHyperLink: false, fontSize: 12, width: '17%'
    },
    {
        id: 'status', numeric: false, disablePadding: false, label: 'Status', enableHyperLink: false, fontSize: 12, width: '17%'
    },
    {
        id: 'updateDate', numeric: false, disablePadding: false, label: 'Update Date', enableHyperLink: false, fontSize: 12, width: '17%'
    },
    {
        id: 'details', isHref: true, numeric: false, disablePadding: false, label: 'Details', enableHyperLink: true, fontSize: 12, width: '17%'
    },
];
const GridView=props=>{
    const { reportType, reportData,toGetTrenGraphChartData,setShowTrendGraph,ispdf} = props;

    const [gridData,setGridData]=useState([]);
    
    useEffect(()=>{
         async function getFormattedData() {
            let response = await getStatusCount(reportData.data);
            setGridData([response]);
          }
        getFormattedData();  
    },[reportData])


    const getTableData = (val) => {

        if (val.length && val[0].consents) { //for member consent history
            return val[0].consents;
        }
        /*Productcode*/
        else if (val && val?.length) {
            Object.keys(val[0]).map(e=>val[0][e] == null || val[0][e] == "" ? val[0][e] ="0" : val[0][e]);
            return val;
        }
        /*Productcode*/ 
        else {
            return [];
        }

    };
    
    const getRowCount = (val) => {
    	/*Productcode : testing needed*/
    	//if (val && val?.length) {
        //    Object.keys(val[0]).map(e=>val[0][e] == null || val[0][e] == "" ? val[0][e] ="0" : val[0][e]);
        //    return val;
		/*Productcode*/
        if (val) {
            if (val.length && val[0].consents) {
                return val[0].consents.length;
            } else {
                return 5;
            }
        }
        else 
        {   
            return 5
        } 
    };

    const getStatusCount=(data)=>{
        let reportNameList = {
            "App":"Third Party Apps Registration",
            "Member":"Member Registration",
            "API":"Third Party API Access",
            "MemberData":"Member API Access",
            "MemberConsentHistory": "Member Consent History",
        };
        if(reportType === "App"){
              return {...data,reportName:reportNameList[reportType]};
        }else if(reportType == "Member"){
              return {"Registrations" : data,reportName:reportNameList[reportType]};
        }else if(reportType == "MemberData"){
              return {...data,reportName:reportNameList[reportType]};
        }else if(reportType == "API"){
            return {...data,reportName:reportNameList[reportType]};
        }else if(reportType == "MemberConsentHistory"){
            return {...data,reportName:reportNameList[reportType]};
        } else{
            return {}
        }
        
    }

    const editRow =(id)=>(event) => {  
        if(event.target.innerText &&  event.target.innerText != "0" && event.target.innerText != 0){  
            let headCells = reportType== "App" ? appRegistrationHeadCells : reportType== "Member" ? memberRegistrationHeadCells : reportType== "API" ? apiAccessHeadCells : reportType== "MemberData" ? memberDataAccessHeadCells : [];         
            headCells.map(e=>{
                if(e.id == id){
                    toGetTrenGraphChartData(e.label);
                }
            })
        }else{
            setShowTrendGraph(false);
        }
    }

    return (
        <>
        {reportType !== "MemberConsentHistory" && (
        <TableComponent
        headCells={reportType== "App" ? appRegistrationHeadCells
        : reportType== "Member" ? memberRegistrationHeadCells 
        : reportType== "API" ? apiAccessHeadCells 
        : reportType== "MemberData" ? memberDataAccessHeadCells 
        : reportType== "MemberConsentHistory" ? consentHistoryAccessHeadCells 
        : []} 
        tableData={getTableData(gridData)}  onTableCellClick={editRow} rowsPerPage = {5}/>
        )}

        {reportType === "MemberConsentHistory" && ispdf === 0 && (
        <TableComponent 
        headCells={reportType== "App" ? appRegistrationHeadCells
        : reportType== "Member" ? memberRegistrationHeadCells 
        : reportType== "API" ? apiAccessHeadCells 
        : reportType== "MemberData" ? memberDataAccessHeadCells 
        : reportType== "MemberConsentHistory" ? consentHistoryAccessHeadCells 
        : []} 
        tableData={getTableData(gridData)}  onTableCellClick={editRow} />
        )}

       {reportType === "MemberConsentHistory" && ispdf === 1 && (
        <TableComponent 
        headCells={reportType== "App" ? appRegistrationHeadCells
        : reportType== "Member" ? memberRegistrationHeadCells 
        : reportType== "API" ? apiAccessHeadCells 
        : reportType== "MemberData" ? memberDataAccessHeadCells 
        : reportType== "MemberConsentHistory" ? consentHistoryAccessHeadCells 
        : []} 
        tableData={getTableData(gridData)}  onTableCellClick={editRow} rowsPerPage={getRowCount(gridData)}/>
                       
        )} 
        </>
    )
}
export default GridView;
import React from 'react';

export default function PrivacyPolicyForm(props) {
    return (
        <div className="tabs-container container">
            <div className="contactUs-container pl-0 row">
                <div className="contactUs-subContainer">
                    <div className="terms-of-use-head p-4">
                        <b className="p-2 dib"> PRIVACY POLICY </b>
                    </div>
                    <div className="container-body p-4 mt-2">
                        <section className="block-elm px-2 mb-3">
                            <h3 className="privacy-heading">MO HealthNet IOX Privacy Resources</h3><br/>
                        </section>

                        <section className="block-elm px-2 mb-3">
                            <p> <b>MO HealthNet IOX</b> </p>
                            <p>The MO HealthNet IOX solution allows patients easy access to their Medicaid claims and encounter information through Third-Party Applications, also called Apps, of their choice. Please review the information in this document and at the links provided below to make an informed decision.</p>
                        </section>
                        <section className="block-elm px-2 mb-3">
                            <p> <b>Personal Health Information</b> </p>
                            <p>Once you provide consent to a Third-Party App organization to access your personal health information, they will have access to your claims and encounter data concerning your interactions with healthcare providers. This may include information about treatment for Substance Use Disorders, mental health treatments, HIV status, or other sensitive information. Please consider this carefully when selecting a Third-Party App and before deciding to participate in MO HealthNet IOX.</p>
                        </section>
                        <section className="block-elm px-2 mb-3">
                            <p> <b>Third-Party Applications</b> </p>
                            <p>In order to use the MO HealthNet IOX initiative, you are required to consent to providing very personal information to a Third-Party Application. Some Third-Party Apps will not be bound to the same rules as other parties, such as hospitals and physicians, who may have access to your health information. It is important to understand your rights and the implications of participating in MO HealthNet IOX before connecting with a Third-Party App.</p>
                        </section>
                        <section className="block-elm px-2 mb-3">
                            <p> <b>MO HealthNet Member Education</b> </p>
                            <p>Please review the MO HealthNet IOX Fact Sheet below for more detailed information about your responsibilities, factors to consider when selecting a Third-Party App, and basic privacy information.</p>
                        </section>
                        <section>
                        <p>The MO HealthNet website provides additional information.</p>
                        <a href="https://dss.mo.gov/mhd/">Interoperability and Patient Access Rule (mo.gov)</a><br/>&nbsp;
                        </section>
                        <section>
                        <p>For details about how to register for MO HealthNet IOX and for information about opting out or other questions, please refer to the MO HealthNet Member IOX User Manual.</p>
                        <a href="https://iox.mohealthnet.conduent.com/">MO HealthNet IOX Home Page</a><br/>&nbsp;
                        </section>
                        <section>
                        <p> <b>Personal Information Required to Register</b> </p>
                        <p>If you choose to use the MO HealthNet IOX solution, you will have to provide personal information necessary for registration, identification, and authentication purposes, including:</p>
                        <ul>
                        <li className="privacy-bullets-circle">First Name and Last Name</li>
                        <li className="privacy-bullets-circle">Address</li>
                        <li className="privacy-bullets-circle">Phone Number</li>
                        <li className="privacy-bullets-circle">Valid e-mail address</li>
                        <li className="privacy-bullets-circle">Social Security Number</li>
                        <li className="privacy-bullets-circle">Medicaid ID</li>
                        <li className="privacy-bullets-circle">Date of Birth</li>
                        </ul>
                        </section>

                        <section className="block-elm px-2 mb-3">
                            <h3 className="privacy-heading">MO HealthNet IOX Fact Sheet</h3><br/>
                        </section>


                        <section className="block-elm px-2 mb-3">
                            <h3 className="privacy-subHeading">Department of Social Services</h3><br/>
                            <h3 className="privacy-subHeading">Frequently Asked Questions: MO HealthNet IOX</h3>
                            <hr/><br/>
                        </section>

                     <section className="block-elm px-2 mb-3">
                        <ul>
                            <li className="privacy-faq-bold">What is the Interoperability and Patient Access Rule?</li>
                        </ul>
                        <p>The Centers for Medicare & Medicaid Services (CMS) requires MO HealthNet to offer a <a href="https://www.cms.gov/Regulations-and-Guidance/Guidance/Interoperability/index">Patient Access Application Programming Interface (API)</a> to allow MO HealthNet participants easy access to their claims and health information through a third-party application (app) of their choice. The Missouri Department of Social Services implementation of this rule is called “MO HealthNet IOX”. The rule also requires MO HealthNet to offer privacy and security resources</p>

                        <ul>
                            <li className="privacy-faq-bold">What is a third-party application?</li>
                        </ul>
                        <p>A third-party application is an interface that is developed, managed, and maintained by an outside organization, <b>not</b> by MO HealthNet.</p>

                        <ul>
                            <li className="privacy-faq-bold">Will my health information be protected?</li>
                        </ul>
                        <p>The Health Insurance Portability and Accountability Act (HIPAA) requires your healthcare provider and MO HealthNet (Missouri Medicaid) to protect your health information. Because most third-party apps are not HIPAA-covered entities, they might not be liable for protecting your information or bound by the same rules and regulations as HIPAA-covered entities.</p>
                        <p>You <b>are not</b> required to use MO HealthNet IOX, but if you choose to, you will be required to consent to giving personal and protected health information to a third-party app. It is important that you take the time to read and understand an app’s privacy policy and terms of service before giving them access to your personal data and health information.</p>

                        <ul>
                            <li className="privacy-faq-bold">What is Personal Information?</li>
                        </ul>
                        <p>Personal information is any information directly linked to you, including your:</p>
                         <ul>
                             <li className="privacy-bullets">Name or date of birth</li>
                             <li className="privacy-bullets">Contact information (ex. address, phone number, email address, etc.)</li>
                             <li className="privacy-bullets">Social Security Number or Medicaid ID Number</li>
                             <li className="privacy-bullets">Provider ID Number</li>
                             <li className="privacy-bullets">Any combination of information that could be used to identify you</li>
                         </ul>

                        <ul>
                            <li className="privacy-faq-bold">What is protected health information (PHI)?</li>
                        </ul>
                        <p>This is information about your health and healthcare, including any:</p>
                        <ul>
                            <li className="privacy-bullets">Past, present, or future physical or mental health conditions</li>
                            <li className="privacy-bullets">Written data from doctor’s visits about treatments, illnesses, disorders, etc.</li>
                            <li className="privacy-bullets">Provision of healthcare to an organization</li>
                            <li className="privacy-bullets">Future payment of the provision of healthcare to an individual</li>
                        </ul>

                        <ul>
                            <li className="privacy-faq-bold">What are my responsibilities when it comes to my information?</li>
                        </ul>
                        <p>Once you choose an app, it is your responsibility to read and understand the app’s privacy policy, terms of service, and other relevant information, so you are aware of how the app is permitted to use or even sell your private and protected information to other organizations and entities.</p>

                        <ul>
                            <li className="privacy-faq-bold">What third-party apps can be used to view my protected health information?</li>
                        </ul>
                        <p>Large, well-known companies, like Google or Apple, and small companies, like your hospital or physician’s office, can use these apps. They are usually accessed from a phone or tablet.</p>

                        <ul>
                            <li className="privacy-faq-bold">What should I consider when choosing an app?</li>
                        </ul>
                        <p>Because certain health data can be shared by multiple people, you should consider how sharing your data with an app could affect others, such as family members. Please remember your health information is very sensitive.</p>
                        <p>MO HealthNet recommends reviewing the third-party app’s Privacy Policy and Terms of Service before installing it on your device. If the app does not have a privacy policy, you should consider a different app. Violations of these terms could be reportable to the Federal Trade Commission (FTC). The Privacy Policy should clearly answer questions like:</p>
                        <ul className="privacy-bullets-two-col">
                            <li className="privacy-bullets">Who is offering the app? How is it funded?</li>
                            <li className="privacy-bullets">What data will be collected? Will non-health data be collected, like my location?.</li>
                            <li className="privacy-bullets">How will my data be used?</li>
                            <li className="privacy-bullets">Where will my data be stored? Will it be stored anonymously?</li>
                            <li className="privacy-bullets">Will I be notified about the data collected about me?</li>
                            <li className="privacy-bullets">Can I delete the data stored about me?</li>
                            <li className="privacy-bullets">Will third-party persistent cookies, also known as tracking cookies, be used? Tracking cookies are small text files that can track you, as you use the internet.</li>
                            <li className="privacy-bullets">Can the app sell or disclose my data to other organizations or entities? Am I able to limit this?</li>
                            <li className="privacy-bullets">Who can view my data when I consent to access?</li>
                            <li className="privacy-bullets">How can I correct inaccuracies, if I notice any?</li>
                            <li className="privacy-bullets">Is there a way for me to voice concerns or complaints?</li>
                            <li className="privacy-bullets">Will I be informed, if there are changes that affect my privacy? If so, how?</li>
                            <li className="privacy-bullets">What security measures are used to protect my data?</li>
                            <li className="privacy-bullets">Can I terminate the app’s access to my data once it has been granted?</li>
                            <li className="privacy-bullets">Will they still have access to my data, if I terminate access?</li>
                        </ul>

                        <ul>
                            <li className="privacy-faq-bold">What personal information do I have to provide?</li>
                        </ul>
                        <p>If you choose to use the MO HealthNet IOX solution, you will have to provide personal information necessary for registration, identification, and authentication purposes. This includes your:</p>
                        <ul>
                            <li className="privacy-bullets">First and last name</li>
                            <li className="privacy-bullets">Address</li>
                            <li className="privacy-bullets">Phone number</li>
                            <li className="privacy-bullets">Valid email address</li>
                            <li className="privacy-bullets">Social Security Number</li>
                            <li className="privacy-bullets">Medicaid ID</li>
                            <li className="privacy-bullets">Date of birth</li>
                        </ul>

                        <ul>
                            <li className="privacy-faq-bold">What are permissions?</li>
                        </ul>
                        <p>Apps need your permission to access to your location, contacts, camera, or microphone. Pay close attention to the permissions requested when you install an app or use it for the first time. These requests often come in the form of pop-up windows you must agree to.</p>
                        <p>Regularly updating an app can protect you from malware or out-of-date software that could be at a higher risk for hacking. Here are some tips to keep in mind</p>
                        <ul>
                            <li className="privacy-bullets">Only download apps from official app stores (like the Apple Store or Google Play) and research the developer before installing it</li>
                            <li className="privacy-bullets">Understand what information the app will request access to</li>
                            <li className="privacy-bullets">Check the permissions settings for requests like gaining access to your location, camera, or microphone (you can also review the app’s permissions by selecting it in your device settings)</li>
                        </ul>

                        <ul>
                            <li className="privacy-faq-bold">Can my personal representative access my data through the app I select?</li>
                        </ul>
                        <p>Designated personal representatives can access another individual’s personal health information using an app, if they have all of the correct information needed to register for or access the patient’s MO HealthNet IOX portal.</p>

                        <ul>
                            <li className="privacy-faq-bold">Can I withdraw consent once I’ve given it?</li>
                        </ul>
                        <p>You can withdraw consent for a third-party app at any time. Please review the <a href="https://iox.mohealthnet.conduent.com">MO HealthNet Member IOX User Manual</a> for instructions on how to withdraw your consent. Once you withdraw your consent, MO HealthNet will stop sending information to the app.</p>
                        <p><b>NOTE:</b> We also recommend you also reach out to the app directly to ask them to delete your data.</p>

                        <ul>
                            <li className="privacy-faq-bold">What if the app returns inaccurate data?</li>
                        </ul>
                        <p>If an app returns healthcare data that appearsinaccurate, please ask your MO HealthNet provider to call <b>573-751-2896</b> to report this issue with our team for review and follow up. Please note that our team can only help MO HealthNet providers.</p>

                        <ul>
                            <li className="privacy-faq-bold">Is there any accountability for third-party applications?</li>
                        </ul>
                        <p>Although most third-party apps are not HIPAA-covered entities, they are still required to comply with Federal Trade Commission (FTC) protections. If you believe there has been a breach with your data or it has been used inappropriately, you can report it to:</p>
                        <ul>
                            <li className="privacy-bullets">The FTC at <a href="https://reportfraud.ftc.gov">ReportFraud.ftc.gov</a></li>
                            <li className="privacy-bullets">MO HealthNet at <a href="mailto:MHD.Privacy@dss.mo.gov">MHD.Privacy@dss.mo.gov</a></li>
                        </ul>

                        <ul>
                            <li className="privacy-faq-bold">Where can I find more information and resources?</li>
                        </ul>
                        <p>For more information about HIPAA rights and coverage, please visit:</p>
                        <ul>
                            <li className="privacy-bullets"><a href="https://www.hhs.gov/hipaa/for-individuals/guidance-materials-for-consumers/index.html">Your Rights Under HIPAA | HHS.gov</a></li>
                            <li className="privacy-bullets"><a href="https://www.hhs.gov/hipaa/for-individuals/faq/index.html">HIPAA FAQs for Individuals | HHS.gov</a></li>
                            <li className="privacy-bullets"><a href="https://dss.mo.gov/hipaa/">Privacy forms | Missouri Department of Social Services (mo.gov)</a></li>
                        </ul>
                        <p>For more information about FTC protections, please visit:</p>
                        <ul>
                            <li className="privacy-bullets"><a href="https://consumer.ftc.gov/identity-theft-and-online-security">Identity Theft & Online Security</a></li>
                            <li className="privacy-bullets"><a href="https://consumer.ftc.gov/articles/how-protect-your-phone-data-it">How To Protect Your Phone and the Data on It</a></li>
                            <li className="privacy-bullets"><a href="https://consumer.ftc.gov/articles/how-protect-your-privacy-apps">How To Protect Your Privacy on Apps</a></li>
                        </ul>
                        </section>

                    </div>
                </div>
            </div>
        </div>
    )
}
import React,{useState,useEffect} from 'react';
import { Chart, Series, CommonSeriesSettings, Label, Format, Legend, Export, ValueAxis, Size,TickInterval,Margin } from 'devextreme-react/chart';
import {getAppstatusCount} from './utility';

export default function GraphView(props) {

    const { reportType, reportData,scrollBottomOfPage,graphType,trendReportData,showTrendGraph, trenGraphTitle } = props;
    const [graphData, setGraphData] = useState([]);
    const [trendData, setTrendData] = useState([]);
    let reportNameList = {
      App: "Third Party Apps Registration",
      Member: "Member Registration",
      API: "Third Party API Access",
      MemberData: "Member API Access",
    };
   
    useEffect(() => {
      if(reportData)
      setGraphData(getGraphData(reportData.data));
    },[reportData]);
    
    useEffect(() => {
      if(trendReportData)
      setTrendData(getTrendData(trendReportData.data));
    },[trendReportData]);
  
    const getGraphData = (data) => {
      if(reportType === "App"){
        return [{label: "Submitted",count: Number(data["submitted"])},{label:"Approved",count:Number(data["approved"])},{label:"Rejected",count:Number(data["rejected"])},{label:"Revoked",count:Number(data["revoked"])}];
      }else if(reportType === "Member"){
        return [{label: "Registrations",count : Number(data)}];
      }else if(reportType === "MemberData"){
        return [{label:"Unique member access data once",count : Number(data["accessDistinct"])},{label:"Unique member access data more than once",count:Number(data["accessMTOnce"])}];
      }else if(reportType === "API"){
        return [{label:"Patient Access API",count:Number(data["patientCount"])},{label:"Provider Directory API",count:Number(data["providerCount"])},{label:"Covered Drug List API",count:Number(data["drugCount"])}];
      }else{
        return [];
      }
    };

    const getTrendData = (data) => {
      let tempArr=[];
      data.map(e=>{
        tempArr.push({label:e.xvalue,count:Number(e.yvalue)});
      })
      return tempArr;
    };

  return (
    <>
    {showTrendGraph ? 
      <>
      <div>
         <Chart id="chart" dataSource={graphData}>
      <Size
          height={600}
          width={800}
      />
      <Margin
          top={60}
          bottom={60}
          left={30}
          right={30}
      />
      <CommonSeriesSettings
        
          barPadding={0.5}
        />
        <ValueAxis 
              title={`Count of ${reportNameList[reportType]}`}
              allowDecimals={false}
          />
          <Label visible={true}>
          <Format type="fixedPoint" precision={1} />
        </Label>
        <Series
          valueField="count"
          argumentField="label"
          name=""
          type="bar"
          series={false}
          color="rgb(0,71,186)" />
          <Legend visible={false}></Legend>
      </Chart>
      </div>

      <div>
      <div  className="tab-holder">
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      <div className="tab-header mb-2">
                    <h2 className="tab-heading float-left">
                      {" "}
                      {`Trend Graph for ${trenGraphTitle}`}{" "}
                    </h2>
                    <div className="clearfix" />
                  </div>
                  </div>
      <Chart id="chart1" dataSource={trendData}>
      <Size
          height={600}
          width={800}
      />
      <Margin
          top={10}
          bottom={60}
          left={30}
          right={30}
      />
       <CommonSeriesSettings
        
          barPadding={0.5}
          allowDecimals={false}
        />
         <ValueAxis 
               allowDecimals={false}
          />
        <Series
          valueField="count"
          argumentField="label"
          name={`Count of ${reportNameList[reportType]}`}
          type="line"
          series={false}
          color="rgb(0,71,186)" />
          <Legend visible={false}></Legend>
      </Chart>
      </div>
</>:<Chart id="chart" dataSource={graphData}>
      <Size
          height={400}
          width={800}
      />
      <Margin
          top={20}
          left={30}
          right={30}
      />
      <CommonSeriesSettings
        
          barPadding={0.5}
        />
        <ValueAxis 
              title={`Count of ${reportNameList[reportType]}`}
              allowDecimals={false}
          />
          <Label visible={true}>
          <Format type="fixedPoint" precision={1} />
        </Label>
        <Series
          valueField="count"
          argumentField="label"
          name=""
          type="bar"
          series={false}
          color="rgb(0,71,186)" />
          <Legend visible={false}></Legend>
      </Chart>}
</>
  );
}

import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import Header from '../../../SharedModule/Layout/header';
import moment from 'moment';

function PDFConsent(props) {
    const {memberId, consentMessage, memberName, consentDate}=props;
    const drawerWidth = 240;
    const useStyles = makeStyles(theme => ({
        wrapper: {
            display: 'flex',
            height: '100%',
            padding: '20px 0 0 0'
        },
        drawer: {
            boxSizing: 'border-box',
            width: drawerWidth,
            flexShrink: 0
        },
        drawerPaper: {
            position: 'relative',
            boxSizing: 'border-box',
            width: drawerWidth
        },
        drawerPaperShrink: {
            position: 'relative',
            boxSizing: 'border-box',
            width: 100
        },
        loginStatus: {
            display: 'flex',
            alignItems: 'center',
            float: 'right'
        },
        dropDownItem: {
            padding: '10px 15px',
            color: '#D5D5D5'
        },
        nestedFolder: {
            paddingRight: '16px!important'
        }
    }));

    const classes = useStyles();
    const dispatch = useDispatch();
    const toPrintRef = useRef();
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const printLayout =true;
    let printclasses = ['main-toggle-button', 'm-4', 'p-0', 'float-right', 'print-toggle-width','mb-4'];
    let classes1 = ['main-toggle-button', 'm-0', 'p-0', 'float-right', 'toggle-width'];
    let classes2 = ['sec-toggle-button', 'm-0', 'p-0', 'float-right', 'toggle-width'];
    

    return (
        <div ref={toPrintRef}>
            <Header print />
            <div className="body-container" style={{'margin-top':"80px"}} >
                {spinnerLoader ? <Spinner /> : null}
                <div className="form-header-container col hide-on-print" id="header">
                    <div className="reg-heading float-left">Reports</div>
                </div>
                <div className="container-lg pt-2">
                    <div className={`feedback-container mt-5`}>
                        <div className="row mx-0 p-4">
                            <div className={printLayout ? "col-6 text-left px-0" : "col-sm-5 col-lg-4 d-inline-block pb-2 px-0 text-center text-sm-left"}>
                                <span>Report ID: RPT-IOX-MEM-001</span>
                            </div>
                            <div className={printLayout ? "col-6 text-right" : "col-sm-7 col-lg-5 text-center d-inline-block p-0"}>
                                <span>
                                    {`MO Medicaid Member Portal - Member Consent`}
                                    <br/> {moment().format('L')}
                                </span>
                            </div>
                        </div>
                        <div className="feedback-data-container">
                            <div className="feedback-data-display-container">
                            
                            </div>                                
                            {/* Print View starts */}
                            <div className="col feedback-data-display-container border-line-color hide-on-screen">
                                <div className="col px-0 toggle-button-margin mb-4">
                                    <div class="float-left pt-2">
                                        <b>Consent for Member</b> : {memberId}
                                    </div>
                                    <div class="float-left pt-2 pl-5">
                                        <b>Member Name</b> : {memberName}
                                    </div>
                                    <div class="float-left pt-2 pl-5">
                                        <b>Consent Date</b> : {consentDate}
                                    </div>
                                    <br/>&nbsp;
                                    <div dangerouslySetInnerHTML={{ __html: consentMessage}}></div>
                                </div>
                            </div>
                            {/* Print View end */}
                        
                            <div className="px-0 pt-3 row mx-0 mt-4">
                                <div className="col-6 px-0 small-size-font font-weight-bold">
                                    Run Date: {moment().format('L')}
                                </div>
                                <div className="col-6 px-0 text-right small-size-font font-weight-bold">
                                    Run Time: {moment().format("HH:mm:ss")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PDFConsent;
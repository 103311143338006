import * as actionTypes from './ReportActionTypes';
import * as serviceEndPoint from '../../../../SharedModule/Service/service';
import axios from 'axios';

const dispatchGetReportData=(data)=>{
  return {
      type:actionTypes.GET_REPORTS_DATA,
      payload:data
  }
}
export const getReportsDataActions = (values) => {
    let serviceApiURL= {
        "App":"GET_APP_REPORTS_DATA",
        "Member":"GET_MEMBER_REPORTS_DATA",
        "MemberData":"GET_MEMBER_ACCESS_REPORTS_DATA",
        "API":"GET_API_ACCESS_REPORTS_DATA",
        "MemberConsentHistory": "GET_CONSENT_HISTORY_REPORTS_DATA",
    };
    let url;
    if(values.reportType === "MemberConsentHistory") {
        url = serviceEndPoint[serviceApiURL[values.reportType]] + '?memberID='+values.memberID+'&memberName='+values.memberName;
    } else {
        url = serviceEndPoint[serviceApiURL[values.reportType]] + '?startDate='+values.startDate+'&endDate='+values.endDate;
    }

  return dispatch=>{
        return axios.get(url)
        .then(res=>{
            dispatch(dispatchGetReportData(res.data))
        }).catch(err=>{
            dispatch(dispatchGetReportData(err))
        })
    }
}
const dispatchGetTrendReportData=(data)=>{
  return {
      type:actionTypes.GET_TREND_REPORTS_DATA,
      payload:data
  }
}
export const getTrendReportsDataActions=(values)=>{
    let serviceApiURL= {"App":"GET_APP_TREND_REPORTS_DATA","Member": "GET_MEMBER_TREND_REPORTS_DATA","MemberData":"GET_MEMBER_TREND_REPORTS_DATA","API":"GET_APP_TREND_REPORTS_DATA"};
    let url = serviceEndPoint[serviceApiURL[values.reportType]] + '?startDate='+values.startDate+'&endDate='+values.endDate+ '&trendGraph='+values.trendType+'&status='+values.subTrendType;
     
  return dispatch=>{
        return axios.get(url)
        .then(res=>{
            dispatch(dispatchGetTrendReportData(res.data))
        }).catch(err=>{
            dispatch(dispatchGetTrendReportData(err))
        })
    }
}

export const resetReportsDataActions = (key) => {
    return {
        type: actionTypes.RESET_REPORT_DATA,
        key: key
      };
}


  
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from 'react-bootstrap';
import GraphView from './GraphView';
import GridView from './GridView';
import * as FeedbackConstants from './FeedbackConstants';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import { Dropdown } from 'react-bootstrap';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/core/styles';
import ReactToPrint from 'react-to-print';
import { setPrintLayout, LogoutActions, EventsLogActions, resetLogoutData } from '../../../SharedModule/Store/Actions/AppActions';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import Header from '../../../SharedModule/Layout/header';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import { GetFeedbackMonthsActions, FeedbackReportActions, resetFeedbackData } from '../Store/Actions/FeedBackActions';
import moment from 'moment';
import IdleTimer from '../../../SharedModule/SessionManagement/IdleTimer';

function Feedback(props) {
    const drawerWidth = 240;
    const useStyles = makeStyles(theme => ({
        wrapper: {
            display: 'flex',
            height: '100%',
            padding: '20px 0 0 0'
        },
        drawer: {
            boxSizing: 'border-box',
            width: drawerWidth,
            flexShrink: 0
        },
        drawerPaper: {
            position: 'relative',
            boxSizing: 'border-box',
            width: drawerWidth
        },
        drawerPaperShrink: {
            position: 'relative',
            boxSizing: 'border-box',
            width: 100
        },
        loginStatus: {
            display: 'flex',
            alignItems: 'center',
            float: 'right'
        },
        dropDownItem: {
            padding: '10px 15px',
            color: '#D5D5D5'
        },
        nestedFolder: {
            paddingRight: '16px!important'
        }
    }));

    const classes = useStyles();
    const dispatch = useDispatch();
    const toPrintRef = useRef();
    let errorMessagesArray = [];
    const userID = props.location.state && props.location.state.userID ? props.location.state.userID : '';
    const printLayout = useSelector(state => state.sharedState.printLayout);
    const isAuthorisedUser = sessionStorage.getItem('authToken');

    const [logout, setLogout] = React.useState(false);
    const [showView, setShowView] = React.useState(true);
    const [showErrorView, setShowErrorView] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [showGridView, setShowGridView] = React.useState(false);
    const [showGraphView, setShowGraphView] = React.useState(false);
    const [feedbackMonths, setFeedbackMonths] = React.useState([]);
    const [feedbackData, setFeedbackData] = React.useState(null);
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    let printclasses = ['main-toggle-button', 'm-0', 'p-0', 'float-right', 'print-toggle-width'];
    let classes1 = ['main-toggle-button', 'm-0', 'p-0', 'float-right', 'toggle-width'];
    let classes2 = ['sec-toggle-button', 'm-0', 'p-0', 'float-right', 'toggle-width'];
    

    // Dispatch Actions
    const getFeedbackMonthsDispatch = (feedbackMonthsCriteria) => dispatch(GetFeedbackMonthsActions(feedbackMonthsCriteria));
    const feedbackReportDispatch = (feedbackReportCriteria) => dispatch(FeedbackReportActions(feedbackReportCriteria));
    const logoutDispatch = (logoutCriteria) => dispatch(LogoutActions(logoutCriteria));

    const feedbackMonthsData = useSelector(state => state.feedbackState.feedbackMonths);
    const feedbackReportData = useSelector(state => state.feedbackState.feedbackReport);
    const logoutData = useSelector(state => state.sharedState.logout);

    useEffect(() => {
        if (!isAuthorisedUser) {
            AppConstants.backToAdminLogin(props);
        } else {
            getFeedbackMonthsDispatch();
            setShowGridView(false);
            setShowGraphView(false);
        }
    }, []);

    useEffect(() => {
        if (feedbackMonthsData) {
            setSpinnerLoader(false);
            setShowView(false);
            setShowErrorView(false);
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(feedbackMonthsData, errorMessagesArray, '');
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                setErrorMessages(errorMessagesArray);
            } else {
                setFeedbackMonths(feedbackMonthsData.Months);
            }
        }
    }, [feedbackMonthsData]);

    useEffect(() => {
        if (feedbackReportData) {
            setSpinnerLoader(false);
            setShowView(false);
            setShowErrorView(false);
            setErrorMessages([]);
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(feedbackReportData, errorMessagesArray, '');
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                setErrorMessages(errorMessagesArray);
            } else {
                if (feedbackReportData && feedbackReportData.length > 0) {
                    feedbackReportData.map((feedback, index) => {
                        if (feedback.feedbackQuestionId === 'CFQ01') {
                            feedback.feedback = "Correct Service"
                        }
                        if (feedback.feedbackQuestionId === 'CFQ02') {
                            feedback.feedback = "Correct Rendering Provider"
                        }
                        if (feedback.feedbackQuestionId === 'CFQ03') {
                            feedback.feedback = "Correct Date(s) of Service"
                        }
                        if (feedback.feedbackQuestionId === 'CFQ04') {
                            feedback.feedback = "Correct Copay"
                        }
                    });
                    const feedbackReportList = JSON.parse(JSON.stringify(feedbackReportData.sort((a, b) => a.feedbackQuestionId.toLocaleUpperCase() < b.feedbackQuestionId.toLocaleUpperCase() ? -1 : 1)));
                    setFeedbackData(feedbackReportList);
                    setShowView(true);
                    setShowErrorView(false);
                    gridView();
                } else {
                    setShowView(false);
                    setShowErrorView(true);
                }
            }
        }
    }, [feedbackReportData]);

    useEffect(() => {
        if (logoutData) {
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(logoutData, errorMessagesArray, AppConstants.LOGOUT_ERROR);
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                if (errorMessagesArray[0].indexOf(AppConstants.LOGOUT_ERROR) > -1) {
                    const eventLog = {
                        memberId: "",
                        eventSource: logout ? "Logout" : "Session Logout",
                        eventType: "Failed",
                        eventDescription: logout ? 'The system was unable to log off. Please terminate the browser window.' : `${userID} has failed to logout from the AK MMIS system ${new Date()}`
                    }
                    setLogout(false);
                    dispatch(EventsLogActions(eventLog));
                }
                setErrorMessages(errorMessagesArray);
            } else {
                dispatch(resetFeedbackData('feedbackReport'));
                dispatch(resetLogoutData('logout'));
                AppConstants.backToAdminLogin(props);
            }
        }
    }, [logoutData]);

    const graphView = () => {
        setShowGridView(true);
        setShowGraphView(false);
    }

    const gridView = () => {
        setShowGraphView(true);
        setShowGridView(false);
    }

    // Field bindings
    const [values, setValues] = React.useState({
        feedbackMonth: AppConstants.SELECT
    });

    // Method to set field values on change
    const handleChanges = name => event => {
        errorMessagesArray = [];
        setErrorMessages([]);
        setValues({ ...values, [name]: event.target.value });
        if (name === 'feedbackMonth' && event.target.value !== AppConstants.SELECT) {
            const feedbackReportRequest = {
                monthYear: event.target.value.replace(/ /g, '')
            };
            feedbackReportDispatch(feedbackReportRequest);
            setSpinnerLoader(true);
        } else {
            setShowView(false);
            setShowErrorView(false);
        }
    };

    // Method to handle Logout
    const handleLogout = () => {
        setLogout(true);
        const logoutRequest = {
            logoutType: "logout",
            userId: userID
        }
        logoutDispatch(logoutRequest);
    }

    return (
        <div ref={toPrintRef}>
            <Header print />
            <div className="body-container" >
                {spinnerLoader ? <Spinner /> : null}
                <div className="form-header-container col hide-on-print" id="header">
                    <div className="reg-heading float-left">Reports</div>
                    <div className="secondary-form-heading">
                        <Dropdown className="user-action-btn mx-0" alignRight>
                            <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic" className="">
                                <AccountCircleIcon className="mr-1" />
                                {props.location.state && props.location.state.userID ? props.location.state.userID : ''}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="arrow-up">
                                <Dropdown.Item
                                    onClick={handleLogout}
                                    className={classes.dropDownItem}>
                                    <i className="fa fa-sign-out" aria-hidden="true"></i>
                                    &nbsp; Logout
                        </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="container-lg pt-2">
                    {errorMessages && errorMessages.length > 0 ?
                        <div className="form-wrapper col-sm-12 px-0">
                            <ErrorMessages errorMessages={errorMessages} />
                        </div> : null}
                    <div className={`feedback-container ${errorMessages && errorMessages.length > 0 ? 'mt-2' : 'mt-5'}`}>
                        <div className="row mx-0 p-4">
                            <div className={printLayout ? "col-4 text-left px-0" : "col-sm-5 col-lg-4 d-inline-block pb-2 px-0 text-center text-sm-left"}>
                                <span><b>Enterprise Operational Reports<br /></b></span>
                                <span>Report ID: AK-RP-PYR-07H-0004</span>
                            </div>
                            <div className={printLayout ? "col-6 text-center" : "col-sm-7 col-lg-5 text-center d-inline-block p-0"}>
                                <span className="headerSize"><b>Department of Health and Social Services<br /></b></span>
                                <span>Alaska Medicaid Member Portal - Feedback Report<br />{moment().format('L')}</span>
                            </div>
                        </div>
                        <div className="feedback-data-container">
                            <div className="feedback-data-display-container">
                                <div className="col-10 px-0 d-inline-block">
                                    <div className="mui-custom-form m-0 mb-3 feedback-dropdown">
                                        <TextField
                                            select
                                            required
                                            id="feedback-month"
                                            label="Reporting Period"
                                            value={values.feedbackMonth}
                                            onChange={handleChanges('feedbackMonth')}
                                            inputProps={{ id: 'feedBackMonth', maxLength: 15 }}
                                            InputLabelProps={{
                                                shrink: true,
                                                for: 'feedBackMonth'
                                            }}
                                        >
                                            <MenuItem selected key={AppConstants.SELECT} value={AppConstants.SELECT}>
                                                {AppConstants.SELECT}
                                            </MenuItem>
                                            {feedbackMonths && feedbackMonths.length > 0 ?
                                                feedbackMonths.map((item, index) => (
                                                    <MenuItem key={index} value={item}>
                                                        {item.toString().toUpperCase()}
                                                    </MenuItem>)) : null}
                                        </TextField>
                                    </div>
                                </div>
                                <div className="col-2 px-0 d-inline-block">
                                    {showView && feedbackData ?
                                        <ReactToPrint
                                            onBeforeGetContent={() => {
                                                dispatch(setPrintLayout(true));
                                                setSpinnerLoader(true);
                                                return new Promise(resolve => setTimeout(() => resolve(), 100));
                                            }}
                                            onAfterPrint={() => {
                                                setSpinnerLoader(false);
                                                dispatch(setPrintLayout(false));
                                            }}
                                            trigger={() => (
                                                <img src="PDF_Normal.svg" alt="pdf icon" className="align-image hide-on-print" />
                                            )}
                                            content={() => toPrintRef.current}
                                        />
                                        :
                                        <img src="PDF_Disable.svg" alt="pdf icon" className="align-image hide-on-print" />}
                                </div>
                            </div>
                            {showView && feedbackData ? <>
                                {/* Screen View starts */}
                                <div className="col feedback-data-display-container border-line-color hide-on-print">
                                    <div className="col px-0 toggle-button-margin">
                                        <Button id="graph" className={(!showGraphView) ? classes1 : classes2} onClick={graphView}>Graph View</Button>
                                        <Button className={(!showGridView) ? classes1 : classes2} onClick={gridView}>Grid View</Button>
                                    </div>
                                    <div className="col px-0">
                                        <div hidden={showGridView}>
                                            <GridView feedbackData={feedbackData}></GridView></div>
                                        <div hidden={showGraphView}>
                                            <GraphView feedbackData={feedbackData} printLayout={printLayout}></GraphView>
                                        </div>
                                    </div>
                                </div>
                                {/* Screen View ends */}

                                {/* Print View starts */}
                                <div className="col feedback-data-display-container border-line-color hide-on-screen">
                                    <div className="col px-0 toggle-button-margin">
                                        <Button className={printclasses} onClick={gridView}>Grid View</Button>
                                    </div>
                                    <div className="col px-0">
                                        <GridView feedbackData={feedbackData}></GridView>
                                    </div>
                                    <div className="col px-0 toggle-button-margin">
                                        <Button className={printclasses} onClick={graphView}>Graph View</Button>
                                    </div>
                                    <div className="col px-0">
                                        <GraphView feedbackData={feedbackData} printLayout={printLayout}></GraphView>
                                    </div>
                                </div>
                                {/* Print View end */}
                            </> :
                                <div className="col feedback-data-display-container border-line-color">
                                    {showErrorView ?
                                        <div className="feedback-alert text-danger">
                                            <img className="p-1" src="Alert_red.svg" alt="Claims Details Unavailable" />
                                            <div className="p-1">{FeedbackConstants.NO_REPORTING_DATA}</div>
                                        </div> :
                                        <div className="feedback-alert">
                                            <img className="p-1" src="Alert_icon.svg" alt="Claims Details Unavailable" />
                                            <div className="p-1">{FeedbackConstants.SELECT_REPORTING_PERIOD}</div>
                                        </div>}
                                </div>}
                            <div className="px-0 pt-3 row mx-0">
                                <div className="col-6 px-0 small-size-font font-weight-bold">
                                    Run Date: {moment().format('L')}
                                </div>
                                <div className="col-6 px-0 text-right small-size-font font-weight-bold">
                                    Run Time: {moment().format("HH:mm:ss")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default IdleTimer(Feedback);
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Radio from "@material-ui/core/Radio";
import Spinner from '../../../SharedModule/Spinner/Spinner';

import { GET_PATIENT_FHIRPROFILE, UPDATE_PATIENT_FHIRPROFILE_CONF_CODE } from '../../../SharedModule/Service/service';
import Axios from 'axios';

export default function AppRestriction(props) {
    const {setErrorMessages} = props;

    const [canUpdate, setcanUpdate] = useState(true);
    useEffect(() => {
        const adminValue = localStorage.getItem('cnRole');
        if (adminValue?.toLowerCase() == "adminhelpdesk"){
            setcanUpdate(false);
        }

    }, []);

    const onClickUpdateConfCode = () => {
        let url = `${UPDATE_PATIENT_FHIRPROFILE_CONF_CODE}`;
        Axios.post(url, {
            "memberSystemId": `${memberID}`,
            "confidentialityCode": `${selectedAccessCode}`
          })
          .then(function (response) {
            if(response.data.status === 'Successfully Saved') {
                setAccessCode(selectedAccessCode);
                setUpdateResponse(response?.data.status);
            }
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
    };
    const onClickRetrieveMemberDetails = () => {
        let fhirUrl = `${GET_PATIENT_FHIRPROFILE}?memberID=${memberID}`;
        
        Axios.get(fhirUrl).then(res => {
            setMemberName(res.data.patientName || '');
            setAccessCode(res.data.confCode || 'NOT_FOUND');
            setSelectedAccessCode(res.data.Code || '');
            if(res.data.pflag === 'false') {
                setErrorMessages(['Please enter correct Member ID']);
            } else {
                setErrorMessages([]);
            }
        }).catch(err => {
            
            console.error(err);
        });
    };
    // Page Constants
    const dispatch = useDispatch();
    const registrationRef = useRef(null);
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    let [memberID, setMemberID] = React.useState('');
    let [memberName, setMemberName] = React.useState('');
    let [accessCode, setAccessCode] = React.useState('');
    let [updateResponse, setUpdateResponse] = React.useState('');
    let [selectedAccessCode, setSelectedAccessCode] = React.useState('');

    return (
        <div className="content-wrapper block-elm" >
            {spinnerLoader ? <Spinner /> : null}

            {/* <!-- main content start --> */}


            <div className="frm-app-registration mt-1">
                <div className="tabs-container" ref={registrationRef}>
                    <div className="tab-body mt-2 pb-3">
                        {/* <!-- section start --> */}
                        <div className="tabs-inner-container mx-3">
                            <div className="tab-header">
                                <h1 className="tab-heading float-left frm-label">Select Member</h1>
                                <div className="clearfix" />
                            </div>

                            <div className="tab-body-bordered p-3 mt-0 plr-none">
                                <div className="form-wrapper col-sm-12 px-0 my-1" id="frm-app-restriction">
                                    <div className="mui-custom-form col-sm-12 col-md-6 m-0">
                                        <div class='row'>
                                            <div class='col-sm-12 col-md-6 m-0'>
                                                <label for="memberId">Member ID</label>
                                                <TextField
                                                    id='memberId'
                                                    value={memberID}
                                                    inputProps={{ maxLength: 50 }}
                                                    onChange={(e) => { setMemberID(e.target.value); }}
                                                    type='text'
                                                    placeholder="Member ID"
                                                />
                                            </div>
                                            
                                            {memberName !== '' && (
                                                <div class='col-sm-12 col-md-6 m-0'>
                                                    <label for="memberName">Member Name</label>
                                                    <TextField
                                                        id='memberName'
                                                        value={memberName}
                                                        type='text'
                                                        placeholder="Member Name"
                                                        disabled
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {accessCode !== 'NOT_FOUND' && accessCode !== '' && (
                                            <div>
                                                <div>
                                                    <br/>
                                                    Current Member Status :&nbsp;
                                                    {accessCode === 'U' && (<>
                                                        Unrestricted
                                                    </>)}
                                                    {accessCode === 'R' && (<>
                                                        Restricted by administrator
                                                    </>)}
                                                    {accessCode === 'V' && (<>
                                                        Restricted by ME code
                                                    </>)}
                                                </div>
                                                
                                                <div>

                                                    <br/>
                                                    {canUpdate == true?
                                                    <div>
                                                    Update to:
                                                    <div className="sub-radio">
                                                    
                                                        <Radio
                                                            type="radio"
                                                            value="U"
                                                            id="access-unrestricted"
                                                            name="access-unrestricted"
                                                            className="ml-2"
                                                            checked={selectedAccessCode === 'U'}
                                                            onClick={(e) => {setSelectedAccessCode(e.target.value);}}
                                                            disabled={accessCode === 'V' || accessCode === 'U'}
                                                        />
                                                        <label for="access-unrestricted" className="radio-label">
                                                            Unrestricted
                                                        </label><br/>
                                                        <Radio
                                                            type="radio"
                                                            value="R"
                                                            id="access-restricted"
                                                            name="access-restricted"
                                                            className="ml-2"
                                                            checked={selectedAccessCode === 'R'}
                                                            onClick={(e) => {setSelectedAccessCode(e.target.value);}}
                                                            disabled={accessCode === 'V' || accessCode === 'R'}
                                                        />
                                                        <label for="access-restricted" className="radio-label">
                                                            Force Restricted
                                                        </label><br/>
                                                        {memberID !== '' && updateResponse !== '' && (
                                                            <div class='text-success'>
                                                                {updateResponse}
                                                            </div>
                                                        )}
                                                    </div>
                                                    </div>:null}
                                                    <div>
                                                        {canUpdate == true?
                                                        <button
                                                            className="btn btn-primary mr-3"
                                                            onClick={onClickUpdateConfCode}
                                                            disabled={memberID === '' }
                                                            >
                                                            Update
                                                        </button>:null}
                                                        
                                                        <button
                                                            className="btn btn-primary btn-align"
                                                            onClick={(e) => {setAccessCode(''); setMemberID('');setMemberName('');setUpdateResponse('');}}
                                                            disabled={memberID === ''}>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {(accessCode === '' || accessCode === 'NOT_FOUND') && (
                                    <div className="tab-footer px-3 block-elm">
                                        <div className="float-right th-btnGroup">
                                            <button
                                                className="btn btn-primary"
                                                onClick={onClickRetrieveMemberDetails}
                                                disabled={memberID === ''}>
                                                Retrieve Member Detail
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- main content end --> */}

        </div>
    )
}


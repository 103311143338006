import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as serviceEndPoint from '../Service/service';
import Dialog from '@material-ui/core/Dialog';
import { Button } from 'react-bootstrap';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

    const IdleSessionLogout = () => {
        const [showPopup, setshowPopup] = useState(false);
        const [signoutTime, setSignoutTime] = useState(1000 * 60 * process.env.REACT_APP_SIGNOUT_TIME);
        const [warningTime, setWarningTime] = useState(1000 * 60 * process.env.REACT_APP_WARNING_TIME);
        let warnTimeout;
        let logoutTimeout;

        const warn = () => {
            console.log('Warning');
            setshowPopup(true);
        };
        const logout = () => {
            console.log('You have been logged out');
            if (window.location.pathname.toLowerCase().indexOf('/membermanagement') > -1 ||
                window.location.pathname.toLowerCase().indexOf('/apppayermanagement') > -1 ||
                window.location.pathname.toLowerCase().indexOf('/reports') > -1 ||
                window.location.pathname.toLowerCase().indexOf('/appapprovereject') > -1) 
                {
                
                    let isAdmin = localStorage.getItem('isAdmin') === 'true';
                    const reqObj = {
                    token: sessionStorage.getItem("token"),
                    client_id: isAdmin ?
                        process.env.REACT_APP_ADMIN_CLIENTID :
                        process.env.REACT_APP_MEMBER_CLIENTID,
                    client_secret: isAdmin ?
                        process.env.REACT_APP_ADMIN_CLIENT_SECRETE :
                        process.env.REACT_APP_MEMBER_CLIENT_SECRET,
                    };
                    axios
                    .post(
                        isAdmin ? serviceEndPoint.INVALIDATE_TOKEN_FOR_ADMIN :
                        serviceEndPoint.INVALIDATE_TOKEN_FOR_MEMBER,
                        reqObj
                    );
                }
            destroy();
        }

        const destroy = () => {
            console.log('Session destroyed');
            
            sessionStorage.clear();
            localStorage.clear();
            window.open(process.env.REACT_APP_ADMIN_OAUTH_LOGOUT_URI, "_self");
        }
        const setTimeouts = () => {
            warnTimeout = setTimeout(warn, warningTime);
            logoutTimeout = setTimeout(logout, signoutTime);
        };

        const clearTimeouts = () => {
            if (warnTimeout) clearTimeout(warnTimeout);
            if (logoutTimeout) clearTimeout(logoutTimeout);
        };

        const extendSession= () => {
            setshowPopup(false);
            if (warnTimeout) clearTimeout(warnTimeout);
            if (logoutTimeout) clearTimeout(logoutTimeout);
        };

        useEffect(() => {

            const events = [
                'load',
                'mousemove',
                'mousedown',
                'click',
                'scroll',
                'keypress'
            ];

            const resetTimeout = () => {
                clearTimeouts();
                setTimeouts();
            };

            for (let i in events) {
                window.addEventListener(events[i], resetTimeout);
            }

            setTimeouts();
            return () => {
                for(let i in events){
                    window.removeEventListener(events[i], resetTimeout);
                    clearTimeouts();
                }
            }
        },[]);


        return (
        <div>
            <Dialog className='alert-dialog' open={showPopup}>
            <div className="row">
                <ErrorOutlineIcon className="alert-font" />
                <p className="col-md-11 col-sm-11 col-10 px-1 py-3 font-weight-bold">Your session is about to expire. Do you want to continue with this session?</p>
            </div>
            <div className="flex-row">
                <Button className="btn btn-primary btn-alert mr-2" onClick={extendSession}>YES</Button>
            </div>
            </Dialog>
      </div>
      )
    }
    export default IdleSessionLogout;
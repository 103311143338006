import moment from 'moment';
export const getFirstAndLastDateOfMonth=(val)=>{
    var nowdate = new Date(val);
    var startDate = new Date(nowdate.getFullYear(), nowdate.getMonth(), 1) ;
    var endDate = new Date(nowdate.getFullYear(), nowdate.getMonth() + 1, 0) ;
    return {
        startDate,
        endDate
    }
}
export const getFirstAndLastDateOfQuarter=(val)=>{

  const [months,year]=val.split(" ");
  const [fmonth,lmonth]=months.split("-");
  var startDate = new Date(`1 ${fmonth} ${year}`) ;
  var nowdate = new Date(`1 ${lmonth} ${year}`);
    var endDate = new Date(nowdate.getFullYear(), nowdate.getMonth() + 1, 0) ;
    return {
        startDate,
        endDate
    }
}

export const generateRequestObject=(val)=>{
  const {startDate,endDate,reportType, memberID, memberName} = val;
  let obj;
  if(reportType === "MemberConsentHistory") {
    obj={
      memberID,
      memberName,
      reportType,
    }
  } else {
    obj={
      startDate:moment(startDate).format("yyyy-MM-DD"),
      endDate:moment(endDate).format("yyyy-MM-DD"),
      reportType:reportType,
    }
  }
  return obj;
}

export const getAppstatusCount=(data)=>{
  var counts = data.reduce((p, c) => {
    var name = c.status;
    if (!p.hasOwnProperty(name)) {
      p[name] = 0;
    }
    p[name]++;
    return p;
  }, {});
  return counts;
}
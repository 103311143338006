import React, { useEffect } from 'react';
import axios from 'axios';
import * as serviceEndPoint from '../../../SharedModule/Service/service';
import { useDispatch, useSelector } from 'react-redux';
/* import { useRouter } from 'next/router';
// import { memHttp } from '../../config/index';
import { jsonHelper } from '../../../SharedModule/Utils/AppConstants';
import { es } from 'date-fns/locale'; */

export default function Home(props) {
  const dispatch=useDispatch();

  const getUserInfo = (clientId) => {
    const GET_USER_INFO_SERVICE = clientId === 'ioxconsentmanagement' ?
          serviceEndPoint.GET_USER_INFO : serviceEndPoint.GET_USER_INFO_FOR_ADMIN;
    axios.post( GET_USER_INFO_SERVICE, {
      "token": `${sessionStorage.getItem("token")}`
    }).then(async (res) => {
      if (window) {
        await sessionStorage.setItem("userId", res.data.name);
        dispatch({ type: 'ADMIN_USER_INFO', payload: res.data });
        const adminData = res.data;
        await sessionStorage.setItem("adminDetails", JSON.stringify(adminData));
        sessionStorage.setItem('authToken', 'medicaidPortal');
        sessionStorage.setItem('isLoggedIn', adminData.name);
        localStorage.setItem('appStatus', 'SUBMITTED');
        localStorage.setItem('payerStatus', 'SUBMITTED');
        const adminArr = adminData.memberof?.split(",");
        const cnStr = adminArr?.filter(a=>a.includes('cn='));
        if (cnStr){
          const adminValue = cnStr[0]?.substr(3,cnStr[0]?.length-1);
          localStorage.setItem('cnRole', adminValue);
          localStorage.setItem('isAdmin', 'true');
          
          if (adminValue?.toLowerCase() == "admin") {
            props.history.push('/appPayerManagement');
          }
          else if (adminValue?.toLowerCase() == "adminhelpdesk") {
            props.history.push('/memberManagement');
          }
          else {
            props.history.push('/');
          }
        } else {
          localStorage.setItem('isAdmin', 'false');
          props.history.push('/consentsManagement');
        }
      }
    }).catch(err => { props.history.push('/') })
  }

  
  useEffect(() => {
    const queryString = window.location.search;
    try {
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.get("code") && urlParams.get("client_id")) {
        const GET_ACCESS_TOKEN_SERVICE = urlParams.get('client_id') === 'ioxconsentmanagement' ?
          serviceEndPoint.GET_ACCESS_TOKEN : serviceEndPoint.GET_ACCESS_TOKEN_FOR_ADMIN;
        axios.post(GET_ACCESS_TOKEN_SERVICE, {
          "grant_type": "authorization_code",
          "redirect_uri": process.env.REACT_APP_ADMIN_LOGIN_URI, //"http://10.142.129.228/login",
          "code": urlParams.get("code")
        }).then(async (res) => {
          if(res && res.data && res.data.access_token){
            await sessionStorage.setItem("token", res.data.access_token);
            let token = sessionStorage.getItem("token");
            if (token) {
              axios.defaults.headers.common['Authorization'] = `${token}`;
          } else {
              axios.defaults.headers.common['Authorization'] = null;
          }
            getUserInfo(urlParams.get('client_id'));
          }else{
            props.history.push('/');
          }
          
        }
        ).catch((err) => {
          props.history.push('/');
        })
      } else {
        if(window.sessionStorage.getItem('token')){
          const role = window.localStorage.getItem('cnRole');
          if (role && role.toLowerCase() == "adminhelpdesk")
          {
            props.history.push('/');
          }
          else if (role && role.toLowerCase() == "admin"){
            props.history.push('/appPayerManagement');
          }
          else{
            props.history.push('/');
          }

        }else{
          props.history.push('/');
        }
      }

    }
    catch {
      props.history.push('/');
    }

  }, []) 

  return (
    <div>
      Loading...
    </div>
  )
}
export const APP_NMAE_REQUIRED = 'App Name is required.';
export const APP_TYPE_REQUIRED = 'App Type is required.';
export const APP_TYPE_DES_REQUIRED = 'App Type Description is required.';
export const DEV_FIRTS_NAME_REQUIRED = 'Developer First Name is required.';
export const DEV_LAST_NAME_REQUIRED = 'Developer Last Name is required.';
export const ORGN_NAME_REQUIRED = 'Organization Name is required.';
export const HOME_URL_REQUIRED = 'Homepage URL is required.';
export const TERM_URL_REQUIRED = 'Terms of Services URL is required.';
export const PRIVACY_URL_REQUIRED = 'Privacy Policy URL is required.';
export const REDIRECT_REQUIRED = 'Redirect URL is required.';
export const ADDRESS1_REQUIRED = 'Address is required.';
export const PHONE_REQUIRED = 'Phone Number is required.';
export const PHONE_INVALID = 'Phone Number is invalid.';
export const Email_REQUIRED = 'Email Id is required.';
export const EMAIL_INVALID = 'Email Address must be in xxx@yyy.zzz format and only allowed characters are (A-Z)(a-z)(0-9)(@._-).';
export const ZIP_REQUIRED = 'Zip is required.';
export const ZIP_INVALID = 'Zip is invalid.';
export const EXTN_INVALID = 'Extension is invalid.';
export const PRIVACY_REQUIRED = 'Please confirm if privacy policy is publicly available.';
export const PRIVACY_DETAILS_REQUIRED = 'Please confirm if privacy policy includes the above information.';
export const PBEST_PACTICE_REQUIRED = 'Please confirm if the app follow industry best practices.';
export const ADDRESS_LINE_REQUIRED = 'Address Line2 is required.';
export const CITY_REQUIRED = 'City is required.';
export const STATE_REQUIRED = 'State is required.';
export const FILES_REQUIRED = 'File is required.';
export const FILES_TYPE = 'The type of file you are trying to attach is not valid. Please contact Technical Support for information on valid file types.';
export const FILES_SIZE_ZERO = 'The file uploaded is empty/zero bytes. The attachment will not be saved.';
export const FILES_SIZE_ERROR = 'The file(s) you are attempting to attach is/are larger than the maximum allowed size of 10 mb. Please try again by reducing the file size or by uploading the files separately.';
export const FILES_DUPLICATE_ERROR = 'Duplicate file names are not allowed for attachment.';
export const REJECT_NOTE_REQUIRED = 'Reject reason is required.';
export const REVOKE_NOTE_REQUIRED = 'Revoke reason is required.';
export const APPROVED_SUCCESS = 'Status of the application is updated to Approved. Approved By & Approved Date is captured.';
export const REJECTED_SUCCESS = 'Status of the application is updated to Rejected. Rejected By, Rejected Date & Rejection Reason is captured.';
export const REVOKED_SUCCESS = 'Status of the application is updated to Revoked. Revoked By, Revoked Date’ & Revocation Reason is captured.';
export const REGISTRATION_TECHNICAL_ERROR = 'We are unable to process your request now. If you need further information, you may contact Participant Inquiries (800) 392-2161.';
export const INVALID_HOME_PAGE_URL_ERROR = 'Home page URL must start with https://';
export const INVALID_PRIVACY_POLICY_URL_ERROR = 'Privacy policy URL must start with https://';
export const INVALID_TERMS_OF_SERVICE_URL_ERROR = 'Terms of services URL must start with https://';
export const INVALID_REDIRECT_URL_ERROR = 'Redirect URL must start with https://';
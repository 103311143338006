import React,{useState,useEffect} from 'react';
import PDFViewer from '../../SharedModule/PDF/PDFViewer';
import AdminPDF from '../../SharedModule/PDF/Admin Help Documentation.pdf';
import MemberPDF from '../../SharedModule/PDF/Member Registration Help Documentation.pdf';
import AppPDF from '../../SharedModule/PDF/Third-Party App Help Documentation.pdf';
import MemberConsentPDF from '../../SharedModule/PDF/Member Consent Help Documentation.pdf';
import MemberRestrictionPDF from '../../SharedModule/PDF/Member Restriction Help Documentation.pdf';
import queryString from 'query-string';

const Help=(props)=>{
   const [moduleName,setModulename]=useState("");

   let docName=localStorage.getItem("ioxHelpDoc");
   useEffect(()=>{
      // setModulename(queryString.parse(props.location.search).name);
      setModulename(docName);
      
   },[])

    return (
   <div className="content-wrapper">
      <div className="page-header mob-main-head">
         <h2 className="page-heading float-left">Help Documentation</h2>
         <div className="clearfix"></div>
      </div>
      <div className="tab-body-bordered pt-3">
         <div className="block-elm container-space">
            <PDFViewer PDF={moduleName == "app" ? AppPDF :
            moduleName == "admin" ? AdminPDF :
            moduleName == "member" ? MemberPDF :
            moduleName == "memberConsent" ? MemberConsentPDF :
            moduleName == "memberRestriction" ? MemberRestrictionPDF :
            null }/>
         </div>
      </div>
</div>
    )
}

export default Help;
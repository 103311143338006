import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

const GraphView = (props) => {
  const { reportType, reportData,scrollBottomOfPage,toGetTrenGraphChartData } = props;
  const [graphData, setGraphData] = useState([]);
  let reportNameList = {
    App: "Third Party Apps Registration",
    Member: "Member Registration",
    API: "Third Party API Access",
    MemberData: "Member API Access",
  };

  useEffect(() => {
    setTimeout(() => {
      if(document.querySelector('div[aria-label="A tabular representation of the data in the chart."]'))
    document.querySelector('div[aria-label="A tabular representation of the data in the chart."]').remove();  
    }, 5000);
    
  });
  
  useEffect(() => {
    async function getFormattedData() {
      let response = await getStatusCount(reportData.data);
      //response=[[{v:1,f:"Revoked"},8],[{v:2,f:"Approved"},3],[{v:3,f:"Submitted"},3],[{v:4,f:"Rejected"},2]];
      setGraphData(response);
    }
    getFormattedData();
  },[reportData]);

  const getStatusCount = (data) => {
      if(reportType === "App"){
        return [["Submitted",Number(data["submitted"])],["Approved",Number(data["approved"])],["Rejected",Number(data["rejected"])],["Revoked",Number(data["revoked"])]];
      }else if(reportType === "Member"){
        return [["Registrations",Number(data)]];
      }else if(reportType === "MemberData"){
        return [["Unique member access data once",Number(data["accessDistinct"])],["Unique member access data more than once",Number(data["accessMTOnce"])]];
      }else if (reportType === "API"){
        return [["Patient Access API",Number(data["patientCount"])],["Provider Directory API",Number(data["providerCount"])],["Covered Drug List API",Number(data["drugCount"])]];
      }else{
        return [];
      }
    };
  
    
    
   

  return (
    <div className="row">
    <div className="col-3"></div>
    <div className="col-6" id="reactgooglegraph">
      <Chart
        height="400px"
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        data={[["", ""], ...graphData]}
        options={{
          /* title: reportNameList[reportType], */
          hAxis: { title: "" },
          vAxis: { title: `Count of ${reportNameList[reportType]}` ,minValue: 0,titleTextStyle: {italic: false},format:'0'},
          legend: "none",
          bar: {groupWidth: "40%"},
          trendlines: { 0: {} },
        }}
        chartEvents={[
          {
            eventName: "ready",
            callback: ({ chartWrapper, google }) => {
              const chart = chartWrapper.getChart();
              var handler = function(e) {
                google.visualization.events.removeListener();
                let selection=chart.getSelection();
                if(selection){
                  toGetTrenGraphChartData(graphData[selection[0]?.row][0]);
                }
              };
              function mouseHandlerPointer(e) {
                 document.getElementById('reactgooglegraph').style.cursor = 'pointer';
              }  
             
             function mouseHandlerDefault(e) {
                document.getElementById('reactgooglegraph').style.cursor = 'default';
              }
              google.visualization.events.addListener(
                chartWrapper.getChart(),
                "select",
                handler
              );
              google.visualization.events.addListener(chart, 'onmouseover', mouseHandlerPointer);                 
              google.visualization.events.addListener(chart, 'onmouseout', mouseHandlerDefault);
            }
          }
        ]}
        rootProps={{ "data-testid": "2" }}
      />
    </div>
    <div className="col-3"></div>
    </div>
  );
};

export default GraphView;

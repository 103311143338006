import React, { useEffect } from 'react';
import Routes from "../../Routes";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Dropdown } from 'react-bootstrap';
import Header from "./header";
import Footer from "./footer";
import $ from 'jquery';
import { useHistory, useLocation } from 'react-router-dom';
const drawerWidth = 285;

const useStyles = makeStyles(theme => ({

  root: {
    display: 'flex',
    paddingTop: '0px',
  },
  hide: {
    display: 'none'
  },
  containerFluid: {
    padding: 0
  }
}));
export default function Layout(props) {
  const isLoggedIn = sessionStorage.getItem('isLoggedIn');
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);

  const onSideMenuClick = (pathname) => {
    history.push(pathname);
  }
  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1024) {
        $('body').addClass('leftNavOff');
      }
    }
    window.addEventListener('resize', handleResize);
  });

  const toggleSideBar = () => {

    if ($('body').hasClass('leftNavOff')) {
      $('body').removeClass('leftNavOff');
    } else {
      $('body').addClass('leftNavOff');
    }
  }

  return (
    <div className="block-elm  hide-controls">
    <div className='main-container'>
      <div className='page-container'>
        <Header print={false} />
        {props.children}
      </div>
    </div>
    <Footer />
    </div>
  );
}

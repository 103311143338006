import * as actionTypes from './RegistrationActionTypes';
import * as serviceEndPoint from '../../../../SharedModule/Service/service';
import axios from 'axios';

export const ValidateRegistration = (validateRegistration) => {
  return ({
    type: actionTypes.VALIDATE_REGISTRATION,
    validateRegistration: validateRegistration
  });
};
export const ValidateRegistrationActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.VALIDATE_REGISTRATION}`, value)
      .then(response => {
        dispatch(ValidateRegistration(response.data));
      })
      .catch(error => {
        dispatch(ValidateRegistration({ systemFailure: true }));
      });
  };
};
export const GetRegistrationOptions = (registrationOptions) => {
  return ({
    type: actionTypes.REGISTRATION_OPTIONS,
    registrationOptions: registrationOptions
  });
};
export const GetRegistrationOptionsActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.REGISTRATION_OPTIONS}`, value)
      .then(response => {
        dispatch(GetRegistrationOptions(response.data));
      })
      .catch(error => {
        dispatch(GetRegistrationOptions({ systemFailure: true }));
      });
  };
};

export const ValidateRegistrationOptions = (validateRegistrationOptions) => {
  return ({
    type: actionTypes.VALIDATE_REGISTRATION_OPTIONS,
    validateRegistrationOptions: validateRegistrationOptions
  });
};

export const ValidateRegistrationOptionsActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.VALIDATE_REGISTRATION_OPTIONS}`, value)
      .then(response => {
        dispatch(ValidateRegistrationOptions(response.data));
      })
      .catch(error => {
        dispatch(ValidateRegistrationOptions({ systemFailure: true }));
      });
  };
};
export const VerifyVerificationCode = (verifyVerificationCode) => {
  return ({
    type: actionTypes.VERIFY_VERIFICATION_CODE,
    verifyVerificationCode: verifyVerificationCode
  });
};

export const VerifyVerificationCodeActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.VERIFY_VERIFICATION_CODE}`, value)
      .then(response => {
        dispatch(VerifyVerificationCode(response.data));
      })
      .catch(error => {
        dispatch(VerifyVerificationCode({ systemFailure: true }));
      });
  };
};
export const ResendVerificationCode = (resendVerificationCode) => {
  return ({
    type: actionTypes.RESEND_VERIFICATION_CODE,
    resendVerificationCode: resendVerificationCode
  });
};

export const ResendVerificationCodeActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.RESEND_VERIFICATION_CODE}`, value)
      .then(response => {
        dispatch(ResendVerificationCode(response.data));
      })
      .catch(error => {
        dispatch(ResendVerificationCode({ systemFailure: true }));
      });
  };
};
export const ResendEmail = (resendEmail) => {
  return ({
    type: actionTypes.RESEND_EMAIL,
    resendEmail: resendEmail
  });
};

export const ResendEmailActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.RESEND_EMAIL}`, value)
      .then(response => {
        dispatch(ResendEmail(response.data));
      })
      .catch(error => {
        dispatch(ResendEmail({ systemFailure: true }));
      });
  };
};


export const resetRegistrationData = (key) => {
  return {
    type: actionTypes.RESET_REGISTRATION_DATA,
    key: key
  };
};

export const getPayerToPayerSearch = (data) => {
  return ({
    type: actionTypes.PAYER_TO_PAYER_SEARCH,
    payload: data
  });
};

export const getPayerToPayerSearchActions = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.PAYER_TO_PAYER_SEARCH}`)
      .then(response => {
        dispatch(getPayerToPayerSearch(response.data));
      })
      .catch(error => {
        dispatch(getPayerToPayerSearch({ systemFailure: true }));
      });
  };
};

export const savePayerConsentExchangeData = (data) => {
  return ({
    type: actionTypes.SAVE_PAYER_CONSENT_DATA_EXCHANGE,
    payload: data
  });
};

export const savePayerConsentExchangeDataActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SAVE_PAYER_CONSENT_DATA_EXCHANGE}`, value)
      .then(response => {
        dispatch(savePayerConsentExchangeData(response.data));
      })
      .catch(error => {
        dispatch(savePayerConsentExchangeData({ systemFailure: true }));
      });
  };
};


export const saveNewPayerDetails = (data) => {
  return ({
    type: actionTypes.SAVE_NEW_PAYER_DETAILS,
    payload: data
  });
};

export const saveNewPayerDetailsActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SAVE_NEW_PAYER_DETAILS}`, value)
      .then(response => {
        dispatch(saveNewPayerDetails(response.data));
      })
      .catch(error => {
        dispatch(saveNewPayerDetails({ systemFailure: true }));
      });
  };
};

export const memberSearchDetails = (data) => {
  return ({
    type: actionTypes.MEMBER_SEARCH,
    payload: data
  });
};

export const memberSearchDetailsActions = (value) => {
  return dispatch => {
    return axios.get(serviceEndPoint.MEMBER_SEARCH + `?lastName=${value.lastName}&dob=${value.dob}&memberID=${value.memberID}&memberSSN=${value.memberSSN}`)
      .then(response => {

        dispatch(memberSearchDetails(response.data));
      })
      .catch(error => {
        dispatch(memberSearchDetails({ systemFailure: true }));
      });
  };
};

export const setRegistrationValues = (data) => {
  return ({
    type: actionTypes.REGISTRATION_VALUES,
    payload: data
  });
};

export const memberGetAttributesActions = (value) => {
  //var url = `${serviceEndPoint.MEMBER_CONSENT_POST + '?consentRequestId='+value}`;
  //alert(url);
  /* const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type":"application/x-www-form-urlencoded"
  } */
  return dispatch => {
    return axios.post(`${serviceEndPoint.MEMBER_CONSENT_POST }`,value)
      .then(response => {
        console.log(response.data);
        dispatch(memberConsentDetails(response.data));
      })
      .catch(error => {
        console.log(error);
        dispatch(memberConsentDetails({ systemFailure: true }));
      });
  };
};
/*   return dispatch => {
    var response = {appName : "GoogleHealth", appOrganization : "Google Incorporated",termsOfURL:"https://www.conduent.com/website-terms-and-conditions/", privacyQuestionnaireURL: "https://www.conduent.com/privacy-policy/",privacyPolicyURL : "https://google.com",
  consentId:"1234", redirectURL:"https://www.conduent.com/privacy-policy/"};
    
    dispatch(memberConsentDetails(response));
    
    return ; */
    /* axios.post(`${serviceEndPoint.MEMBER_CONSENT_POST + '?consentRequestId='+value}`)
      .then(response => {

        dispatch(memberConsentDetails(response.data));
      })
      .catch(error => {
        dispatch(memberConsentDetails({ systemFailure: true }));
      }); */
/*   };
}; */

export const memberConsentDetails = (data) => {
  
  return ({
    type: actionTypes.MEMBER_CONSENT_GET,
    memberConsentDetails: data
  });
};

export const memberConsentSave = (data) => {
  return ({
    type: actionTypes.SAVE_MEMBER_CONSENT_DATA_EXCHANGE,
    memberConsentSaveResponse: data
  });
};


export const memberConsentSaveActions= (value) => {
  //PUT call with consentid + boolean flag(0 for deny, 1 for allow)
  //response - "Success", consentResponse: JWT 
  /*return dispatch => {
    var response = {success : true, data:{"signedJwt": "signedJWT",
    //"consentApprovalRedirectUri":"https://www.conduent.com/privacy-policy/"}};
    "consentApprovalRedirectUri":"http://cmds.ioxqa.iam.apps.conduent.com:80/am/oauth2/realms/root/realms/iox/realms/ioxmissouri/authorize?response_type=code&state=1234&client_id=ioxmoclient&scope=openid%20profile&redirect_uri=http%3A%2F%2Flocal1.com%2Flogin"}};
    if (response.success == true)
    {
      dispatch(memberConsentSave(response.data)); 
    }
    return ;*/
  return dispatch => {
    return axios.post(`${serviceEndPoint.SAVE_MEMBER_CONSENT_DATA_EXCHANGE}`, value)
      .then(response => {
          console.log(response.data);
          dispatch(memberConsentSave(response.data)); 
      })
      .catch(error => {
        console.log(error);
        dispatch(memberConsentSave({ systemFailure: true }));
      });
  };
  };
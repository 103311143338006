/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as RegistrationConstants from './RegistrationConstants';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import SuccessMessage from '../../../SharedModule/MessageHandlers/SuccessMessage';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import { AppConfigDropdownActions, EventsLogActions } from '../../../SharedModule/Store/Actions/AppActions';
import { ValidateRegistrationActions,setRegistrationValues } from '../Store/Actions/RegistrationActions';
import moment from 'moment';
import InputMask from 'react-input-mask';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import RegistrationDialog from './RegistrationDialog';
// import { ValidateRegistrationOptionsActions } from '../Store/Actions/RegistrationActions';


export default function RegistrationForm(props) {
    const [showWelcome, setShowWelcome] = React.useState(true);
    // Page Constants
    const dispatch = useDispatch();
    const registrationRef = useRef(null);
    const ssnRef = useRef(null);
    let errorMessagesArray = [];
    const [selectedDOBDate, setSelectedDOBDate] = React.useState(null);
    //const [addrRequired, setAddrRequired] = React.useState(false);
    const [showSSN, setShowSSN] = React.useState(false);
    const [ageCheckBox, setAgeCheckBox] = React.useState(false);
    const [showAgeCheckBox, setShowAgeCheckBox] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [successMessages, setSuccessMessages] = React.useState([]);
    const [stateList, setStateList] = React.useState([]);
    const [securityQsList, setSecurityQsList] = React.useState([]);
    const [PhoneNumber, setPhoneNumber] = React.useState('');
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [bnrMsg, setBnrMsg] = React.useState('');

    const [{
        firstNameError,
        lastNameError,
        dobError,
        phoneNumberError,
        emailAddressError,
        userIDError,
        memberIDError,
        memberSSNError,
        addressError,
        cityError,
        stateError,
        postalCodeError,
        securityQs1Error,
        securityQs2Error,
        securityQs3Error,
        answer1Error,
        answer2Error,
        answer3Error,
        dupSecurityQs2Error,
        dupSecurityQs3Error,
        under13Error
    }, setShowError] = React.useState(false);

    const [{
        firstNameErrorText,
        lastNameErrorText,
        dobErrorText,
        phoneNumberErrorText,
        emailAddressErrorText,
        userIDErrorText,
        memberIDErrorText,
        memberSSNErrorText,
        addressErrorText,
        cityErrorText,
        stateErrorText,
        postalCodeErrorText,
        securityQs1ErrorText,
        securityQs2ErrorText,
        securityQs3ErrorText,
        answer1ErrorText,
        answer2ErrorText,
        answer3ErrorText,
        dupSecurityQs2ErrorText,
        dupSecurityQs3ErrorText,
        under13ErrorText
    }, setShowErrorText] = React.useState('');

    // Field bindings
    const [values, setValues] = React.useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        emailAddress: '',
        userID: '',
        memberID: '',
        memberSSN: '',
        address: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: AppConstants.SELECT,
        postalCode: '',
        secQs1: AppConstants.SELECT_QUESTION,
        secQs2: AppConstants.SELECT_QUESTION,
        secQs3: AppConstants.SELECT_QUESTION,
        answer1: '',
        answer2: '',
        answer3: '',
        id: '',
        dob: ''
    });
    const [id, setId] = React.useState('');
    // Dispatch Actions
    const dropDownDispatch = (configKeyList) => dispatch(AppConfigDropdownActions(configKeyList));
    const validateRegistrationDispatch = (registrationCriteria) => dispatch(ValidateRegistrationActions(registrationCriteria));

    const dropdownData = useSelector(state => state.sharedState.appConfigDropdown);
    const validateRegistrationData = useSelector(state => state.registrationState.validateRegistration);

    //const validateRegistrationOptionsDispatch = (validateRegistrationOptionsCriteria) => dispatch(ValidateRegistrationOptionsActions(validateRegistrationOptionsCriteria));
    //const validateRegistrationOptionsData = useSelector(state => state.registrationState.validateRegistrationOptions);

    const handleWelcome = () => {
        setShowWelcome(false);
    }
    // Method to make API calls on Load
    useEffect(() => {
        const configKeyList = ["STATE_LIST", "MEMBER_SECURITY_QUESTIONS","MEMBER_REGISTRATION_SUPPORT_CONTACT"];
        dropDownDispatch(configKeyList);
        
    }, []);

    useEffect(() => {
        if (dropdownData) {
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(dropdownData, errorMessagesArray, RegistrationConstants.REGISTRATION_TECHNICAL_ERROR);
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                setErrorMessages(errorMessagesArray);
            } else {
                if (dropdownData.STATE_LIST && dropdownData.STATE_LIST.configKey === AppConstants.STATE_LIST) {
                    setStateList(dropdownData.STATE_LIST.configDetail);
                }
                if (dropdownData.MEMBER_SECURITY_QUESTIONS && dropdownData.MEMBER_SECURITY_QUESTIONS.configKey === AppConstants.MEMBER_SECURITY_QUESTIONS) {
                    setSecurityQsList(dropdownData.MEMBER_SECURITY_QUESTIONS.configDetail);
                }
                if(dropdownData.MEMBER_REGISTRATION_SUPPORT_CONTACT && 
                    dropdownData.MEMBER_REGISTRATION_SUPPORT_CONTACT.configKey === 
                    AppConstants.MEMBER_REGISTRATION_SUPPORT_CONTACT) {
                      setBnrMsg(dropdownData.MEMBER_REGISTRATION_SUPPORT_CONTACT.configDetail[0].value);
                    }
            }
        }
    }, [dropdownData]);

    // useEffect(() => {
    //     if (validateRegistrationOptionsData) {
    //         const registrationValue = { ...values };
    //         setSpinnerLoader(false);
    //         errorMessagesArray = [];
    //         errorMessagesArray = AppConstants.handleAPIErrors(validateRegistrationOptionsData, errorMessagesArray, RegistrationConstants.REGISTRATION_TECHNICAL_ERROR);
    //         if (errorMessagesArray && errorMessagesArray.length > 0) {
    //             setErrorMessages(errorMessagesArray);
    //            // setDisableSubmit(true);
    //         } else {
    //             registrationValue.id = validateRegistrationOptionsData.id;
    //             setOpen(true);
    //         }
    //     }
    // }, [validateRegistrationOptionsData]);

    useEffect(() => {
        if (validateRegistrationData) {
            setSpinnerLoader(false);
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(validateRegistrationData, errorMessagesArray, RegistrationConstants.REGISTRATION_TECHNICAL_ERROR);
            if (errorMessagesArray && errorMessagesArray.length > 0 ) {
                setErrorMessages(errorMessagesArray);
                /* document.getElementById("error-scroll").scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                }); */
                registrationRef.current.scrollIntoView({
                    behavior: "smooth"
                });
            } else {
                const registrationValue = { ...values };
                registrationValue.dob = selectedDOBDate;
                registrationValue.phoneNumber = PhoneNumber;
                registrationValue.memberSystemId = validateRegistrationData.memberSystemId;
                registrationValue.memberSystemId = validateRegistrationData.memberSystemId;
                setId(validateRegistrationData.id);
                values.id = validateRegistrationData.id;
                values.dob = selectedDOBDate;
                values.memberSystemId = validateRegistrationData.memberSystemId;
                dispatch(setRegistrationValues(registrationValue));
                 props.history.push({
                     pathname: '/registrationOptions',
                     state: { registrationValue: registrationValue }
                 });
                // setOpen(true);

            }
        }
    }, [validateRegistrationData]);
        // Method to clear error/success messages
        const clearMessages = () => {
            setErrorMessages([]);
            setSuccessMessages([]);
        }
    // Method to set field values on change
    const handleChanges = name => event => {
        if (name === 'phoneNumber') {
            setValues({ ...values, [name]: event.target.value.replace(AppConstants.PHONE_NUMBER_ONLY_REGEX, '') });
            var unmaskedPhone = event.target.value.replace(AppConstants.PHONE_NUMBER_UNMASK_REGEX, '');
            setPhoneNumber(unmaskedPhone);
        } else if (name === 'memberID' || name === 'postalCode' || name === 'memberSSN') {
            setValues({ ...values, [name]: event.target.value.replace(AppConstants.NUMBER_ONLY_REGEX, '') });
            event.target.value = event.target.value.replace(AppConstants.NUMBER_ONLY_REGEX, '');
        }
        else {
            setValues({ ...values, [name]: event.target.value });
        }
        // // Conditional check if Address Section is required

        // if ((name === 'address' && (event.target.value !== '' ||
        //     values.city !== '' || values.state !== AppConstants.SELECT || values.postalCode !== '')) ||
        //     (name === 'city' && (event.target.value !== '' ||
        //         values.address !== '' || values.state !== AppConstants.SELECT || values.postalCode !== '')) ||
        //     (name === 'state' && (event.target.value !== AppConstants.SELECT ||
        //         values.address !== '' || values.city !== '' || values.postalCode !== '')) ||
        //     (name === 'postalCode' && (event.target.value !== '' ||
        //         values.address !== '' || values.city !== '' || values.state !== AppConstants.SELECT)) ||
        //     (name !== 'address' && name !== 'city' && name !== 'state' && name !== 'postalCode' &&
        //         (values.address !== '' || values.city !== '' || values.state !== AppConstants.SELECT || values.postalCode !== ''))) {
        //   //  setAddrRequired(true);
        // } else {
        //    // setAddrRequired(false);
        // }
    };



    // Method to handle Date change
    const handleDateChange = date => {
        setSelectedDOBDate(date);
        // Logic to show Age Check Box
        if (moment(new Date()).diff(moment(date), 'years') < 13) {
            setShowAgeCheckBox(true);
        } else {
            setShowAgeCheckBox(false);
        }
    };

    // Method to set Age Check Box
    const handleChangeAgeCheckBox = () => {
        setAgeCheckBox(!ageCheckBox);
    };

    // Moving cursor to the end of the text
    useEffect(() => {
        if (ssnRef && ssnRef.current) {
            ssnRef.current.selectionStart = ssnRef.current.value.length;
            ssnRef.current.selectionEnd = ssnRef.current.value.length;
        }
    }, [showSSN]);

    // Method to handle Password visibility
    const handleClickShowSSN = () => {
        setShowSSN(!showSSN);
        ssnRef.current.focus();
    };

    const handleMouseDownSSN = (event) => {
        event.preventDefault();
    };

    // Method to validate Registration Form
    const validateRegisterForm = () => {
        var firstNameError; var lastNameError; var dobError; var phoneNumberError; var emailAddressError;
        var userIDError; var memberIDError; var memberSSNError; var addressError; var cityError; var stateError; var postalCodeError;
        var securityQs1Error; var securityQs2Error; var securityQs3Error; var answer1Error; var answer2Error;
        var answer3Error; var dupSecurityQs1Error; var dupSecurityQs2Error; var dupSecurityQs3Error;
        var verificationCodeError; var under13Error = false;

        var firstNameErrorText; var lastNameErrorText; var dobErrorText; var phoneNumberErrorText; var emailAddressErrorText;
        var userIDErrorText; var memberIDErrorText; var memberSSNErrorText; var addressErrorText; var cityErrorText; var stateErrorText; var postalCodeErrorText;
        var securityQs1ErrorText; var securityQs2ErrorText; var securityQs3ErrorText; var answer1ErrorText; var answer2ErrorText
        var answer3ErrorText; var dupSecurityQs1ErrorText; var dupSecurityQs2ErrorText; var dupSecurityQs3ErrorText;
        var verificationCodeErrorText; var under13ErrorText = '';

        if (values.firstName === '' || (values.firstName && values.firstName.trim() === '')) {
            firstNameError = true;
            firstNameErrorText = RegistrationConstants.FIRST_NAME + RegistrationConstants.REQUIRED;
            errorMessagesArray.push(firstNameErrorText);
        }
        if (values.lastName === '' || (values.lastName && values.lastName.trim() === '')) {
            lastNameError = true;
            lastNameErrorText = RegistrationConstants.LAST_NAME + RegistrationConstants.REQUIRED;
            errorMessagesArray.push(lastNameErrorText);
        }
        if (!selectedDOBDate) {
            dobError = true;
            dobErrorText = RegistrationConstants.DOB + RegistrationConstants.REQUIRED;
            errorMessagesArray.push(dobErrorText);
        }
        if (selectedDOBDate && selectedDOBDate.toString() === 'Invalid Date') {
            dobError = true;
            dobErrorText = RegistrationConstants.DOB + RegistrationConstants.INVALID;
            errorMessagesArray.push(dobErrorText);
        }
        if (PhoneNumber === '' || (PhoneNumber && PhoneNumber.trim() === '')) {
            phoneNumberError = true;
            phoneNumberErrorText = RegistrationConstants.PHONE_NUMBER + RegistrationConstants.REQUIRED;
            errorMessagesArray.push(phoneNumberErrorText);
        }
        if (!phoneNumberError && PhoneNumber.length < 10) {
            phoneNumberError = true;
            phoneNumberErrorText = RegistrationConstants.PHONE_NUMBER_FORMAT;
            errorMessagesArray.push(phoneNumberErrorText);
        }
        if (values.emailAddress === '' || (values.emailAddress && values.emailAddress.trim() === '')) {
            emailAddressError = true;
            emailAddressErrorText = RegistrationConstants.EMAIL_ADDRESS + RegistrationConstants.REQUIRED;
            errorMessagesArray.push(emailAddressErrorText);
        }
        if (!emailAddressError && !AppConstants.EMAIL_ADDRESS_REGEX.test(values.emailAddress)) {
            emailAddressError = true;
            emailAddressErrorText = AppConstants.EMAIL_INVALID;
            errorMessagesArray.push(emailAddressErrorText);
        }
        if (values.userID === '' || (values.userID && values.userID.trim() === '')) {
            userIDError = true;
            userIDErrorText = RegistrationConstants.USER_ID + RegistrationConstants.REQUIRED;
            errorMessagesArray.push(userIDErrorText);
        }
        if (!userIDError && !AppConstants.USER_ID_REGEX.test(values.userID)) {
            userIDError = true;
            userIDErrorText = AppConstants.USER_ID_INVALID;
            errorMessagesArray.push(userIDErrorText);
        }
        if (values.memberID === '' || (values.memberID && values.memberID.trim() === '')) {
            memberIDError = true;
            memberIDErrorText = RegistrationConstants.MEMBER_ID + RegistrationConstants.REQUIRED;
            errorMessagesArray.push(memberIDErrorText);
        }
        if (values.memberSSN === '' || (values.memberSSN && values.memberSSN.trim() === '')) {
            memberSSNError = true;
            memberSSNErrorText = RegistrationConstants.MEMBER_SSN + RegistrationConstants.REQUIRED;
            errorMessagesArray.push(memberSSNErrorText);
        }
        if (!memberSSNError && values.memberSSN.length < 9) {
            memberSSNError = true;
            memberSSNErrorText = RegistrationConstants.SSN_NUMBER_FORMAT;
            errorMessagesArray.push(memberSSNErrorText);
        }
        if (values.addressLine1 === '' || (values.addressLine1 && values.addressLine1.trim() === '')) {
            addressError = true;
            addressErrorText = RegistrationConstants.ADDRESS + RegistrationConstants.REQUIRED;
            errorMessagesArray.push(addressErrorText);
        }
      //  if (addrRequired) {

            if (values.city === '' || (values.city && values.city.trim() === '')) {
                cityError = true;
                cityErrorText = RegistrationConstants.CITY + RegistrationConstants.REQUIRED;
                errorMessagesArray.push(cityErrorText);
            }
            if (values.state && values.state === AppConstants.SELECT) {
                stateError = true;
                stateErrorText = RegistrationConstants.STATE + RegistrationConstants.REQUIRED;
                errorMessagesArray.push(stateErrorText);
            }
            if (values.postalCode === '' || (values.postalCode && values.postalCode.trim() === '')) {
                postalCodeError = true;
                postalCodeErrorText = RegistrationConstants.POSTAL_CODE + RegistrationConstants.REQUIRED;
                errorMessagesArray.push(postalCodeErrorText);
            }
       // }
        if (values.secQs1 && values.secQs1 === AppConstants.SELECT_QUESTION) {
            securityQs1Error = true;
            securityQs1ErrorText = RegistrationConstants.SECURITY_QUESTION_1 + RegistrationConstants.REQUIRED;
            errorMessagesArray.push(securityQs1ErrorText);
        }
        if (values.secQs2 && values.secQs2 === AppConstants.SELECT_QUESTION) {
            securityQs2Error = true;
            securityQs2ErrorText = RegistrationConstants.SECURITY_QUESTION_2 + RegistrationConstants.REQUIRED;
            errorMessagesArray.push(securityQs2ErrorText);
        }
        if (values.secQs3 && values.secQs3 === AppConstants.SELECT_QUESTION) {
            securityQs3Error = true;
            securityQs3ErrorText = RegistrationConstants.SECURITY_QUESTION_3 + RegistrationConstants.REQUIRED;
            errorMessagesArray.push(securityQs3ErrorText);
        }
        if (!securityQs1Error && !securityQs2Error && (values.secQs1 === values.secQs2)) {
            dupSecurityQs2Error = true;
            dupSecurityQs2ErrorText = RegistrationConstants.DUPLICATE_SECURITY_QUESTIONS;
        }
        if (!securityQs2Error && !securityQs3Error && (values.secQs2 === values.secQs3)) {
            dupSecurityQs3Error = true;
            dupSecurityQs3ErrorText = RegistrationConstants.DUPLICATE_SECURITY_QUESTIONS;
        }
        if (!securityQs1Error && !securityQs3Error && (values.secQs1 === values.secQs3)) {
            dupSecurityQs3Error = true;
            dupSecurityQs3ErrorText = RegistrationConstants.DUPLICATE_SECURITY_QUESTIONS;
        }
        if (dupSecurityQs2Error || dupSecurityQs3Error) {
            errorMessagesArray.push(RegistrationConstants.DUPLICATE_SECURITY_QUESTIONS);
        }
        if (values.answer1 === '' || (values.answer1 && values.answer1.trim() === '')) {
            answer1Error = true;
            answer1ErrorText = RegistrationConstants.ANSWER_1 + RegistrationConstants.REQUIRED;
            errorMessagesArray.push(answer1ErrorText);
        }
        if (values.answer2 === '' || (values.answer2 && values.answer2.trim() === '')) {
            answer2Error = true;
            answer2ErrorText = RegistrationConstants.ANSWER_2 + RegistrationConstants.REQUIRED;
            errorMessagesArray.push(answer2ErrorText);
        }
        if (values.answer3 === '' || (values.answer3 && values.answer3.trim() === '')) {
            answer3Error = true;
            answer3ErrorText = RegistrationConstants.ANSWER_3 + RegistrationConstants.REQUIRED;
            errorMessagesArray.push(answer3ErrorText);
        }
        if (showAgeCheckBox && !ageCheckBox) {
            under13Error = true;
            under13ErrorText = RegistrationConstants.UNDER_13_ERROR;
            errorMessagesArray.push(under13ErrorText);
        }

        if (!firstNameError && !lastNameError && !dobError && !phoneNumberError && !emailAddressError &&
            !userIDError && !memberIDError && !memberSSNError && !addressError && !cityError && !stateError &&
            !postalCodeError && !securityQs1Error && !securityQs2Error && !securityQs3Error &&
            !dupSecurityQs2Error && !dupSecurityQs3Error && !answer1Error && !answer2Error && !answer3Error &&
            under13Error) {
            const eventLog = {
                memberId: values.memberID,
                eventSource: "Registration",
                eventType: "attestation not checked",
                eventDescription: `Member ${values.memberID} Attestation checkbox not selected`
            }
            dispatch(EventsLogActions(eventLog));
        }

        setErrorMessages(errorMessagesArray);
        setShowError({
            firstNameError: firstNameError,
            lastNameError: lastNameError,
            dobError: dobError,
            phoneNumberError: phoneNumberError,
            emailAddressError: emailAddressError,
            userIDError: userIDError,
            memberIDError: memberIDError,
            memberSSNError: memberSSNError,
            addressError: addressError,
            cityError: cityError,
            stateError: stateError,
            postalCodeError: postalCodeError,
            securityQs1Error: securityQs1Error,
            securityQs2Error: securityQs2Error,
            securityQs3Error: securityQs3Error,
            answer1Error: answer1Error,
            answer2Error: answer2Error,
            answer3Error: answer3Error,
            dupSecurityQs1Error: dupSecurityQs1Error,
            dupSecurityQs2Error: dupSecurityQs2Error,
            dupSecurityQs3Error: dupSecurityQs3Error,
            verificationCodeError: verificationCodeError,
            under13Error: under13Error
        });

        setShowErrorText({
            firstNameErrorText: firstNameErrorText,
            lastNameErrorText: lastNameErrorText,
            dobErrorText: dobErrorText,
            phoneNumberErrorText: phoneNumberErrorText,
            emailAddressErrorText: emailAddressErrorText,
            userIDErrorText: userIDErrorText,
            memberIDErrorText: memberIDErrorText,
            memberSSNErrorText: memberSSNErrorText,
            addressErrorText: addressErrorText,
            cityErrorText: cityErrorText,
            stateErrorText: stateErrorText,
            postalCodeErrorText: postalCodeErrorText,
            securityQs1ErrorText: securityQs1ErrorText,
            securityQs2ErrorText: securityQs2ErrorText,
            securityQs3ErrorText: securityQs3ErrorText,
            answer1ErrorText: answer1ErrorText,
            answer2ErrorText: answer2ErrorText,
            answer3ErrorText: answer3ErrorText,
            dupSecurityQs1ErrorText: dupSecurityQs1ErrorText,
            dupSecurityQs2ErrorText: dupSecurityQs2ErrorText,
            dupSecurityQs3ErrorText: dupSecurityQs3ErrorText,
            verificationCodeErrorText: verificationCodeErrorText,
            under13ErrorText: under13ErrorText
        });

        if (errorMessagesArray && errorMessagesArray.length === 0) {
            return true;
        } else {
            /* document.getElementById("error-scroll").scrollIntoView({
                behavior: "smooth",
            }); */
            registrationRef.current.scrollIntoView({
                behavior: "smooth"
            });
            return false;
        }
    };

    // Method to submit Registration Form
    const submitRegistrationForm = () => {
        clearMessages();
        if (validateRegisterForm()) {
            const registrationRequest = {
                userId: values.userID,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.emailAddress,
                phoneNumber: parseInt(PhoneNumber),
                dob: moment(selectedDOBDate).format('YYYY-MM-DD'),
                ssn: values.memberSSN,
                mailingAddress: {
                    address: null,
                    addressLine1: values.addressLine1,
                    addressLine2: values.addressLine2,
                    city: values.city,
                    state: values.state !== AppConstants.SELECT ? values.state : '',
                    postalCode: values.postalCode
                },
                kbarequest: {
                    input: {
                        kba: [
                            {
                                answer: values.answer1,
                                questionId: values.secQs1
                            },
                            {
                                answer: values.answer2,
                                questionId: values.secQs2
                            },
                            {
                                answer: values.answer3,
                                questionId: values.secQs3
                            }
                        ]
                    }
                },
                // challengeResponses: [
                //     { challenge: values.secQs1, response: values.answer1 },
                //     { challenge: values.secQs2, response: values.answer2 },
                //     { challenge: values.secQs3, response: values.answer3 }
                // ],
                memberId: values.memberID
            };
            setSpinnerLoader(true);
            validateRegistrationDispatch(registrationRequest);
        }
    };
    return (
        <div className="content-wrapper">
            <div className="frm-app-registration mt-1">
                <div className="tabs-container">
                    <div className="page-header mob-main-head"  ref={registrationRef}>
                        <h1 className="page-heading float-left">Member Registration</h1>
                        <div className="float-right"><a href="/Help2" onClick={()=>{localStorage.setItem("ioxHelpDoc","member")}} target="_blank" className="dib fw-600 py-2 mt-1"> Help </a></div>
                        <div className="clearfix" />
                    </div>
                    {showWelcome && (
                            <div className="welcome-msg info-msg p-3 mb-4">
                                A valid e-mail address is required to complete registration. If you do not have an e-mail address, please contact {bnrMsg}
                                <div className="close-msg-box" onClick={handleWelcome}>x</div>
                            </div>
                        )}
                    {spinnerLoader ? <Spinner /> : null}
                    
                    <div id="error" >
                        <div>
                            <form autoComplete="off">
                                
                                {/* <div className="row">
                                    <InfoOutlinedIcon className="red-font" />
                                    <p className="red-font col px-2 pt-3 pb-1">A valid e-mail address is required to complete registration. If you do not have an e-mail address, please contact Member Services at (907) 644-6800 or toll-free in IOX at (800) 770-5650.</p>
                                </div> */}
                                <div className="form-wrapper col-sm-12 px-0">
                                    {errorMessages && errorMessages.length > 0 ?
                                        <ErrorMessages errorMessages={errorMessages} /> : null}
                                    {errorMessages && successMessages && errorMessages.length === 0 && successMessages.length > 0 ?
                                        <SuccessMessage successMessages={successMessages} /> : null}
                                </div>
                                <div className="tab-body mt-2 pb-3">
                                    <div className="tabs-inner-container m-3">
                                        <div className="tab-header">
                                            <h2 className="tab-heading float-left frm-label">Member Demographics</h2>
                                            <div className="clearfix" />
                                        </div>
                                        <div className="tab-body-bordered pb-3 pt-3 mt-0">
                                            <div className="form-wrapper col-sm-12 px-2 my-1">
                                                <div className="mui-custom-form col-sm-6 col-md-3 m-0 pb-3">
                                                    <TextField
                                                        required
                                                        id="standard-firstname"
                                                        label={RegistrationConstants.FIRST_NAME}
                                                        value={values.firstName}
                                                        onChange={handleChanges('firstName')}
                                                        inputProps={{ maxLength: 35 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        placeholder="Letters only (do not use ' . # - &quot;)"
                                                        error={firstNameError}
                                                        helperText={firstNameError ? firstNameErrorText : null}
                                                    />
                                                </div>
                                                <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                                    <TextField
                                                        required
                                                        id="standard-lastname"
                                                        label={RegistrationConstants.LAST_NAME}
                                                        value={values.lastName}
                                                        onChange={handleChanges('lastName')}
                                                        inputProps={{ maxLength: 35 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        placeholder="Letters only (do not use ' . # - &quot;)"
                                                        error={lastNameError}
                                                        helperText={lastNameError ? lastNameErrorText : null}
                                                    />
                                                </div>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <div className="custom-date-picker mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                                        <KeyboardDatePicker
                                                            fullWidth
                                                            required
                                                            id="date-picker-inline"
                                                            name='date'
                                                            maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                                                            label={RegistrationConstants.DOB}
                                                            value={selectedDOBDate}
                                                            onChange={handleDateChange}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            placeholder="MM/DD/YYYY"
                                                            format="MM/dd/yyyy"
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date'
                                                            }}
                                                            error={dobError}
                                                            helperText={dobError ? dobErrorText : null}
                                                        />
                                                    </div>
                                                </MuiPickersUtilsProvider>
                                                <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                                    <InputMask
                                                        mask="(999) 999-9999"
                                                        disabled={false}
                                                        maskChar=" "
                                                        value={values.phoneNumber}
                                                        onChange={handleChanges('phoneNumber')}
                                                        inputProps={{ maxLength: 10 }}
                                                    >
                                                        {() => <TextField
                                                            required
                                                            id="standard-phone"
                                                            label={RegistrationConstants.PHONE_NUMBER}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            error={phoneNumberError}
                                                            helperText={phoneNumberError ? phoneNumberErrorText : null}
                                                        />}
                                                    </InputMask>
                                                </div>
                                                <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                                    <TextField
                                                        required
                                                        id="standard-email"
                                                        label={RegistrationConstants.EMAIL_ADDRESS}
                                                        value={values.emailAddress}
                                                        onChange={handleChanges('emailAddress')}
                                                        inputProps={{ maxLength: 64 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        error={emailAddressError}
                                                        helperText={emailAddressError ? emailAddressErrorText : null}
                                                    />
                                                </div>
                                                <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                                    <TextField
                                                        required
                                                        id="standard-username"
                                                        label={RegistrationConstants.USER_ID}
                                                        value={values.userID}
                                                        onChange={handleChanges('userID')}
                                                        inputProps={{ maxLength: 30 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        error={userIDError}
                                                        helperText={userIDError ? userIDErrorText : null}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="tabs-inner-container m-3">
                                        <div className="tab-header">
                                            <h2 className="tab-heading float-left frm-label">Identification Details</h2>
                                            <div className="clearfix" />
                                        </div>
                                        <div className="tab-body-bordered pb-3 pt-3 mt-0">
                                            <div className="form-wrapper col-sm-12 px-2 my-1">
                                                <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                                    <TextField
                                                        required
                                                        id="standard-memberid"
                                                        label={RegistrationConstants.MEMBER_ID}
                                                        value={values.memberID}
                                                        onChange={handleChanges('memberID')}
                                                        inputProps={{ maxLength: 15 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        error={memberIDError}
                                                        helperText={memberIDError ? memberIDErrorText : null}
                                                    />
                                                </div>
                                                <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                                    <TextField
                                                        required
                                                        inputRef={ssnRef}
                                                        id="standard-member-ssn"
                                                        type={showSSN ? 'text' : 'password'}
                                                        label={RegistrationConstants.MEMBER_SSN}
                                                        value={values.memberSSN}
                                                        onChange={handleChanges('memberSSN')}
                                                        onBlur={() => setShowSSN(false)}
                                                        inputProps={{ maxLength: 9 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowSSN}
                                                                    onMouseDown={handleMouseDownSSN}
                                                                >
                                                                    {values.memberSSN ? !showSSN ? <Visibility /> : <VisibilityOff /> : null}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }}
                                                        error={memberSSNError}
                                                        helperText={memberSSNError ? memberSSNErrorText : null}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tabs-inner-container m-3">
                                        <div className="tab-header">
                                            <h2 className="tab-heading float-left frm-label">Address Details</h2>
                                            <div className="clearfix" />
                                        </div>
                                        <div className="tab-body-bordered pb-3 pt-3 mt-0">
                                            <div className="form-wrapper col-sm-12 px-2 my-1">
                                                <div className="mui-custom-form col-sm-6 col-md-6 m-0 pb-3">
                                                    <TextField
                                                        required={true}
                                                        id="standard-mailing-address1"
                                                        label="Address"
                                                        value={values.addressLine1}
                                                        onChange={handleChanges('addressLine1')}
                                                        placeholder="Line 1"
                                                        inputProps={{ maxLength: 50 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        error={addressError}
                                                        helperText={addressError ? addressErrorText : null}
                                                    />
                                                </div>
                                                <div className="mui-custom-form col-sm-6 col-md-6 m-0 mb-3">
                                                <label className="visuallyhidden" for="standard-mailing-address2">Address Extension</label>
                                                    <TextField
                                                        // required={true}
                                                        id="standard-mailing-address2"
                                                        placeholder="Line 2"
                                                        className="pt-4"
                                                        value={values.addressLine2}
                                                        onChange={handleChanges('addressLine2')}
                                                        inputProps={{ maxLength: 50 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                       //error={addressError}
                                                      // helperText={addressError ? addressErrorText : null}
                                                    />
                                                </div>


                                            </div>
                                            <div className="form-wrapper col-sm-12 px-2 my-1">
                                                <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                                    <TextField
                                                        required={true}
                                                        id="standard-city"
                                                        label={RegistrationConstants.CITY}
                                                        value={values.city}
                                                        onChange={handleChanges('city')}
                                                        inputProps={{ maxLength: 30 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        error={cityError}
                                                        helperText={cityError ? cityErrorText : null}
                                                    />
                                                </div>
                                                <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                                    <TextField
                                                        select
                                                        required={true}
                                                        id="standard-state"
                                                        label={RegistrationConstants.STATE}
                                                        value={values.state}
                                                        onChange={handleChanges('state')}
                                                        inputProps={{ id: 'state', maxLength: 2 }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            for: 'state'
                                                        }}
                                                        error={stateError}
                                                        helperText={stateError ? stateErrorText : null}
                                                    >
                                                        <MenuItem selected key={AppConstants.SELECT} value={AppConstants.SELECT}>
                                                            {AppConstants.SELECT}
                                                        </MenuItem>
                                                        {stateList ? stateList.map(option => (
                                                            <MenuItem selected key={option.key} value={option.key}>
                                                                {option.value}
                                                            </MenuItem>
                                                        )) : null}
                                                    </TextField>
                                                </div>
                                                <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                                    <TextField
                                                        required={true}
                                                        id="standard-zip"
                                                        label={RegistrationConstants.POSTAL_CODE}
                                                        value={values.postalCode}
                                                        onChange={handleChanges('postalCode')}
                                                        inputProps={{ maxLength: 5 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        error={postalCodeError}
                                                        helperText={postalCodeError ? postalCodeErrorText : null}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tabs-inner-container m-3">
                                        <div className="tab-header">
                                            <h2 className="tab-heading float-left frm-label">Security Questions</h2>
                                            <div className="clearfix" />
                                        </div>
                                        <div className="tab-body-bordered pb-3 pt-3 mt-0">
                                            <div className="form-wrapper col-sm-12 pl-0 pr-3 my-0">
                                                <div className="col-sm-6 col-md-3 m-0">
                                                    <div className="mui-custom-form mb-3">
                                                        <TextField
                                                            required
                                                            select
                                                            id="standard-sq1"
                                                            label={RegistrationConstants.SECURITY_QUESTION_1}
                                                            value={values.secQs1}
                                                            onChange={handleChanges('secQs1')}
                                                            inputProps={{ id: 'secQS1' }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                for: 'secQS1'
                                                            }}
                                                            error={securityQs1Error}
                                                            helperText={securityQs1Error ? securityQs1ErrorText : null}
                                                        >
                                                            <MenuItem selected key={AppConstants.SELECT_QUESTION} value={AppConstants.SELECT_QUESTION}>
                                                                {AppConstants.SELECT_QUESTION}
                                                            </MenuItem>
                                                            {securityQsList ? securityQsList.map(option => (
                                                                // <MenuItem selected key={option.key} value={option.value}>
                                                                <MenuItem selected key={option.key} value={option.key}>
                                                                    {option.value}
                                                                </MenuItem>
                                                            )) : null}
                                                        </TextField>
                                                    </div>
                                                    <div className={
                                                        !securityQs1Error && (dupSecurityQs2Error || dupSecurityQs3Error)
                                                            ? "mui-custom-form mb-3 dynamic-padding-dup" :
                                                            ((securityQs1Error && (dupSecurityQs2Error || dupSecurityQs3Error))
                                                                || (!securityQs1Error && (securityQs2Error || securityQs3Error)))
                                                                ? "mui-custom-form mb-3 dynamic-padding" : "mui-custom-form mb-3"}>
                                                        <TextField
                                                            required
                                                            id="standard-ans1"
                                                            label={RegistrationConstants.ANSWER_1}
                                                            value={values.answer1}
                                                            onChange={handleChanges('answer1')}
                                                            inputProps={{ maxLength: 50 }}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            error={answer1Error}
                                                            helperText={answer1Error ? answer1ErrorText : null}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-3 m-0">
                                                    <div className="mui-custom-form mb-3">
                                                        <TextField
                                                            required
                                                            select
                                                            id="standard-sq2"
                                                            label={RegistrationConstants.SECURITY_QUESTION_2}
                                                            value={values.secQs2}
                                                            onChange={handleChanges('secQs2')}
                                                            inputProps={{ id: 'secQS2' }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                for: 'secQS2'
                                                            }}
                                                            error={securityQs2Error || dupSecurityQs2Error}
                                                            helperText={securityQs2Error ? securityQs2ErrorText : (dupSecurityQs2Error ? dupSecurityQs2ErrorText : null)}
                                                        >
                                                            <MenuItem selected key={AppConstants.SELECT_QUESTION} value={AppConstants.SELECT_QUESTION}>
                                                                {AppConstants.SELECT_QUESTION}
                                                            </MenuItem>
                                                            {securityQsList ? securityQsList.map(option => (
                                                                // <MenuItem selected key={option.key} value={option.value}>
                                                                <MenuItem selected key={option.key} value={option.key}>
                                                                    {option.value}
                                                                </MenuItem>
                                                            )) : null}
                                                        </TextField>
                                                    </div>
                                                    <div className={
                                                        !securityQs2Error && (dupSecurityQs3Error && !dupSecurityQs2Error)
                                                            ? ("mui-custom-form mb-3 dynamic-padding-dup")
                                                            : ((securityQs2Error && dupSecurityQs3Error) || (!securityQs2Error && !dupSecurityQs2Error && (securityQs1Error || securityQs3Error))
                                                                ? "mui-custom-form mb-3 dynamic-padding"
                                                                : "mui-custom-form mb-3")}>
                                                        <TextField
                                                            required
                                                            id="standard-ans2"
                                                            label={RegistrationConstants.ANSWER_2}
                                                            value={values.answer2}
                                                            onChange={handleChanges('answer2')}
                                                            inputProps={{ maxLength: 50 }}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            error={answer2Error}
                                                            helperText={answer2Error ? answer2ErrorText : null}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-3 m-0">
                                                    <div className="mui-custom-form mb-3">
                                                        <TextField
                                                            required
                                                            select
                                                            id="standard-sq3"
                                                            label={RegistrationConstants.SECURITY_QUESTION_3}
                                                            value={values.secQs3}
                                                            onChange={handleChanges('secQs3')}
                                                            inputProps={{ id: 'secQS3' }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                for: 'secQS3'
                                                            }}
                                                            error={securityQs3Error || dupSecurityQs3Error}
                                                            helperText={securityQs3Error ? securityQs3ErrorText : (dupSecurityQs3Error ? dupSecurityQs3ErrorText : null)}
                                                        >
                                                            <MenuItem selected key={AppConstants.SELECT_QUESTION} value={AppConstants.SELECT_QUESTION}>
                                                                {AppConstants.SELECT_QUESTION}
                                                            </MenuItem>
                                                            {securityQsList ? securityQsList.map(option => (
                                                                // <MenuItem selected key={option.key} value={option.value}>
                                                                <MenuItem selected key={option.key} value={option.key}>
                                                                    {option.value}
                                                                </MenuItem>
                                                            )) : null}
                                                        </TextField>
                                                    </div>
                                                    <div className={
                                                        !securityQs3Error && (dupSecurityQs2Error && !dupSecurityQs3Error)
                                                            ? ("mui-custom-form mb-3 dynamic-padding-dup")
                                                            : ((securityQs3Error && dupSecurityQs2Error) || (!securityQs3Error && !dupSecurityQs3Error && (securityQs1Error || securityQs2Error))
                                                                ? "mui-custom-form mb-3 dynamic-padding"
                                                                : "mui-custom-form mb-3")}>
                                                        <TextField
                                                            required
                                                            id="standard-ans3"
                                                            label={RegistrationConstants.ANSWER_3}
                                                            value={values.answer3}
                                                            onChange={handleChanges('answer3')}
                                                            inputProps={{ maxLength: 50 }}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            error={answer3Error}
                                                            helperText={answer3Error ? answer3ErrorText : null}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-1">
                                        <div className='col pr-0'>
                                            {showAgeCheckBox ? <div className="form-wrapper ml-2">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            className={under13Error ? "checkbox-error" : null}
                                                            name="terms-condition-checkbox"
                                                            color="primary"
                                                            checked={ageCheckBox}
                                                            value={ageCheckBox}
                                                            onChange={handleChangeAgeCheckBox}
                                                        />
                                                    }
                                                    id="attestationCheck"
                                                    label="Attesting the authority to view data of child under 13"
                                                />
                                            </div> : null}
                                        </div>
                                        <div className='d-flex pt-1 pl-0 pr-3 text-right mr-3'>
                                            <span className="gray-font">Page 1 of 2</span> 
                                        </div>
                                    </div>
                                    {showAgeCheckBox && under13Error ? <p className="attest-error">{under13ErrorText}</p> : null}

                                    <div className="float-center th-btnGroup py-3">
                                        <Button variant="outlined" color="primary" className='btn btn-ic btn-next' id="submitButton"
                                            onClick={submitRegistrationForm}>
                                            Next </Button>
                                        <Button variant="outlined" color="primary" className='btn btn-cancel' onClick={() => AppConstants.backToHome(props)}>
                                            Cancel </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    {open ? <RegistrationDialog open={open} setOpen={setOpen} history={props.history}
                        registrationValue={values} id={id} /> : null}
                </div>
            </div>
        </div>
    )
}

